import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const SchedulingSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Standaard weergave',
            explanationText: 'Toon de geplande opdrachten in een lijstje per dag/medewerker, of in een kalender op de geplande tijd.',
            fields: [
                {
                    type: 'tabs',
                    name: 'scheduling_default_view',
                    buttonSize: 'large',
                    options: [
                        { value: 'employee_list', name: 'Lijstweergave' },
                        { value: 'employee_timeline', name: 'Tijdlijn weergave', requiredFeature: 'scheduling_timeline_views' },
                        { value: 'project_planner', name: 'Project planner', requiredFeature: 'project_planner' },
                    ]
                }
            ]
        },
        {
            title: 'Getoonde tijden (van - tot)',
            dependentOn: { fieldName: 'scheduling_default_view', value: ['employee_timeline'] },
            fields: [
                {
                    type: 'time',
                    name: 'scheduling_display_start_time',
                    group: 'field-row'
                },
                {
                    type: 'time',
                    name: 'scheduling_display_end_time',
                    group: 'field-row'
                }
            ]
        },
        // {
        //     title: 'Plan in tijdsblokken',
        //     requiredFeature: 'scheduling_time_blocks',
        //     dependentOn: { fieldName: 'scheduling_default_view', value: ['employee_list', 'employee_timeline'] },
        //     explanationText: 'De mogelijkheid om afspraken in te plannen in tijdsblokken in plaats van op uren, en geef de buitendienst medewerker meer flexibiliteit.',
        //     switchButton: {
        //         enabled: true,
        //         name: 'scheduling_time_blocks',
        //     }
        // },
        {
            title: 'Richting agenda',
            explanationText: 'Toon de medewerkers gestapeld op het scherm met de tijd van links naar rechts, of toon de medewerkers als kolommen naast elkaar met de tijd van boven naar beneden.',
            fields: [
                {
                    type: 'tabs',
                    name: 'scheduling_direction',
                    buttonSize: 'large',
                    options: [
                        { value: 'stacked', name: 'Gestapeld' },
                        { value: 'columns', name: 'Kolommen' },
                    ]
                }
            ]
        },
        {
            title: 'Getoonde dagen',
            fields: [
                {
                    type: 'tabs',
                    name: 'scheduling_display_mode',
                    buttonSize: 'large',
                    options: [
                        { value: 'mon-fri', name: 'Ma t/m vr' },
                        { value: 'mon-sat', name: 'Ma t/m za' },
                        { value: 'all', name: 'Alle dagen' },
                    ]
                }
            ]
        },
        {
            title: 'Afspraak kleuren',
            fields: [
                {
                    type: 'tabs',
                    name: 'scheduling_appointment_colors',
                    buttonSize: 'large',
                    options: [
                        { value: 'status', name: 'Status' },
                        { value: 'type', name: 'Opdracht type' },
                    ]
                }
            ]
        },
        // {
        //     title: 'Afspraak tekst',
        //     fields: [
        //         {
        //             type: 'multiselect',
        //             name: 'scheduling_appointment_text',
        //             hardcodedOptions: [
        //                 { value: 'start_time', name: 'Starttijd' },
        //                 { value: 'end_time', name: 'Eindtijd' },
        //                 { value: 'reference', name: 'Referentie' },
        //                 { value: 'type', name: 'Opdracht type' },
        //                 { value: 'description', name: 'Omschrijving' },
        //                 { value: 'client', name: 'Klantnaam' },
        //                 { value: 'location', name: 'Locatie' }
        //             ]
        //         }
        //     ]
        // },
        {
            title: 'Open planningsinbox automatisch',
            switchButton: {
                enabled: true,
                name: 'scheduling_automatic_open_job_inbox',
            }
        },
        {
            title: `Profielfoto's medewerkers tonen`,
            switchButton: {
                enabled: true,
                name: 'scheduling_show_profile_pictures',
            }
        },
        // {
        //     title: `Medewerkersrooster tonen`,
        //     explanationText: 'Toon de beschikbaarheid van je medewerkers. De achtergrond wanneer iemand niet beschikbaar is wordt grijs.',
        //     switchButton: {
        //         enabled: true,
        //         name: '',
        //     }
        // },
        // {
        //     title: `Beschikbare capaciteit berekenen`,
        //     requiredFeature: 'employee_scheduling',
        //     explanationText: 'Laat het systeem op basis van het reeds geplande werk en het rooster van je medewerkers de nog beschikbare capaciteit per week berekenen. Hierdoor kun je beter vooruit plannen.',
        //     switchButton: {
        //         enabled: true,
        //         name: '',
        //     }
        // },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default SchedulingSettings;