/*
 * FieldsetTypes.tsx
 * This file contains the type definition of widget fields.
 */ 

export type PhoneNumberType = {
    id: number | undefined;
    phone_number: string;
    phone_type: string;
    person: number | undefined;
    organization: number | undefined;
    deleted: boolean;
    environment: string | undefined;
}

export const createEmptyPhoneNumber = (object: string, data: any) => ({
    id: undefined,
    phone_number: '',
    phone_type: 'work',
    person: (object === 'person') ? data.id : undefined,
    organization: (object === 'organization') ? data.id : undefined,
    deleted: false,
    environment: undefined,
});

export type EmailAddressType = {
    id: number | undefined;
    email: string;
    email_type: string;
    person: number | undefined;
    organization: number | undefined;
    deleted: boolean;
    environment: string | undefined;
}

export const createEmptyEmailAddress = (object: string, data: any) => ({
    id: undefined,
    email: '',
    email_type: 'general',
    person: (object === 'person') ? data.id : undefined,
    organization: (object === 'organization') ? data.id : undefined,
    deleted: false,
    environment: undefined,
});

export type AddressType = {
    id: number | undefined;
    created: string | undefined;
    modified: string | undefined;
    address: string;
    street: string | undefined;
    house_number: string | undefined;
    addition: string | undefined;
    postal_code: string | undefined;
    city: string | undefined;
    country: string | undefined;
    name: string | undefined;
    notes: string | undefined;
    type: string;
    deleted: boolean;
    environment: string | undefined;
}

export const createEmptyAddress = (locationType: string) => ({
    id: undefined,
    created: undefined,
    modified: undefined,
    address: '',
    street: undefined,
    house_number: undefined,
    addition: undefined,
    postal_code: undefined,
    city: undefined,
    country: undefined,
    name: undefined,
    notes: undefined,
    type: locationType,
    deleted: false,
    environment: undefined,
});