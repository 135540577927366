import React, { useState, useRef, useEffect } from 'react';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import axios, { CancelTokenSource } from 'axios';
import { fetchData } from 'services/api/fetchData';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { sanitizeTextareaFromString } from 'services/utils/convertTextareaData';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import CustomQuillEditor from 'components/forms/fields/CustomQuillEditor';
import ReactQuill from 'react-quill';
import { convertTabsToSpaces } from 'services/utils/convertTextareaData';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import 'react-quill/dist/quill.snow.css';
import '../../style/scss/timeline.scss';

interface TimelineNoteFormProps {
    timeline_item?: string;
    noteId?: string;
    timeline_page_id: string | number;
    timeline_page?: string;
    isNoteFormOpen: boolean;
    setIsNoteFormOpen: (isFormOpen: boolean) => void;
    resetActiveButtonItem: () => void;
    errorMessage?: string | string[] | undefined;
}

type NoteData = {
    note: string;
    timeline_item?: string;
    timeline_page_id: string | number;
    timeline_page_type?: string;
    created_by_user_hash?: string;
    modified_by_user_hash?: string;
}

const TimelineNoteForm: React.FC<TimelineNoteFormProps> = ({ 
    timeline_item, timeline_page_id, timeline_page, noteId, setIsNoteFormOpen, resetActiveButtonItem 
}) => {
    const { t } = useTranslation();
    const { errorMessages, setErrorMessages, setFloatingAlert } = useGlobalContext();
    const { handleLogout, userHash } = useAuthContext();
    const [noteText, setNoteText] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [, setShowErrorAlert] = useState(false);
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [showLoader, setShowLoader] = useState(false);
    const quillRef = useRef<ReactQuill>(null);
    const apiObject = 'note'
    const fieldNames = ['note'];

    // If editing an existing note, fetch the existing data
    useEffect(() => {
        // Set load status to loading
        setLoadStatus("loading");

        // Show loader icon after 250ms
        let timer: NodeJS.Timeout | null = setTimeout(() => {
            setShowLoader(true);
        }, 250);

        const loadNoteData = async () => {
            if (noteId) {
                try {
                    const data = await fetchData({ apiObject: 'note', itemId: noteId, handleLogout });
                    if (data) {
                        // Convert tabs to non-breakable spaces to show the tabs in the frontend
                        const convertText = convertTabsToSpaces(data.note)
                        setNoteText(convertText);
                    }
                } catch (error) {
                    console.log('Failed to load note data', error);
                }
            } else {
                setNoteText('');
            }
        };

        // Start both async operations
        const loadPromise = [loadNoteData()];

        // Set load status to loaded on completion
        Promise.all(loadPromise).then(() => {
            setLoadStatus("loaded");
            if (timer) {clearTimeout(timer)};
            setShowLoader(false);
        });
    }, [noteId]);

    // Handles the submit of the note
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setButtonLoader(true);

            // Create the cancel token source
            const source = axios.CancelToken.source();
            setCancelSource(source);

            // Sanitize textarea before submit
            const convertedNote = sanitizeTextareaFromString(noteText);

            // Set the fields to save the note
            let noteData: NoteData = {
                note: convertedNote,
                timeline_page_id,
                timeline_page_type: timeline_page,
            }

            // If we create a new note, set the user which creates it
            if (!noteId) {
                noteData = {
                    ...noteData,
                    created_by_user_hash: userHash,
                }
            // If we update an existing note, set the user which modifies it
            } else {
                noteData = {
                    ...noteData,
                    timeline_item: timeline_item,
                    modified_by_user_hash: userHash,
                }
            }

            // Save the note
            await saveData({ apiObject, itemId: noteId, data: noteData, source });

            setErrorMessages({});
            setFloatingAlert({ type: 'success' })
            setIsNoteFormOpen(false);
            resetActiveButtonItem();
        } catch (error) {
            // Handle the errors using the handleSaveErrors function
            const errorData = handleSaveErrors(error, fieldNames); 
            setErrorMessages(errorData);

            // If the errorState has a general error, show the error alert
            if (errorData.general) 
                setShowErrorAlert(true);

        } finally {
            setButtonLoader(false);
        }
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages['note'];

    // Handles the closing of the note form
    const handleClose = () => {
        if (cancelSource) {
            cancelSource.cancel();
        }
        setIsNoteFormOpen(false);
        resetActiveButtonItem();
        setNoteText('');
        setShowErrorAlert(false);
        setErrorMessages({});
        setButtonLoader(false);
    }

    return (
        <>
            {loadStatus === 'loading' || loadStatus === 'idle' ? (
                <div className='note-form-loader'>
                    <div className={`loader ${showLoader ? '' : 'hide'}`}></div>
                </div>
            ) : loadStatus === 'loaded' ? (
                <form className='formset note-form' 
                    onSubmit={handleSubmit}>
                    <CustomQuillEditor
                        value={noteText}
                        ref={quillRef}
                        onChange={setNoteText}
                        initialFocus={true}
                        enableStyling='simple'
                    />
                    <div className='button-row'>
                        <div className='buttons-left'>
                            {errorMessage && 
                                <div className='error-message'>
                                    {t(errorMessage, { defaultValue: errorMessage})}
                                </div>
                            }
                        </div>
                        <div className='buttons-right'>
                            <SecondaryButton 
                                    onClick={(e: React.MouseEvent) => {e.stopPropagation(); handleClose()}} 
                                    label="general.cancel"
                                    size="extra-small"/>
                            <PrimaryButton
                                type="submit" 
                                label="general.save"
                                size="extra-small"
                                loading={buttonLoader}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}/>
                        </div>
                    </div>
                </form>
            ) : null}
        </>
    );
};

export default TimelineNoteForm;