/*
 * timelineTranslation.ts
 * Maps the field names en values of timeline updates to the translation strings.
 */ 

export const translateTimelineUpdates = (
    timelinePageType: string, 
    fieldName: string | null, 
    newValue: string | null,
    previousValue: string | null,
): { 
    translatedFieldName: string, 
    translatedNewValue: string | null,
    translatedPreviousValue: string | null,
} => {
    if (!fieldName) return { translatedFieldName: '', translatedNewValue: null, translatedPreviousValue: null };

    const translatedFieldName = `${timelinePageType}.backend_field.${fieldName}`;

    let translatedNewValue: string | null = null;
    let translatedPreviousValue: string | null = null;

    // If the field name contains 'contact_type' and belongs to a contact, find the associated translation strings
    if (fieldName === 'contact_type' && (timelinePageType === 'organization' || timelinePageType === 'person' || timelinePageType === 'contact')) {
        if (newValue) {
            translatedNewValue = `${timelinePageType}.contact_type.${newValue}`;
        }
        if (previousValue) {
            translatedPreviousValue = `${timelinePageType}.contact_type.${previousValue}`;
        }
    }
    
    // If the field name contains 'type' and belongs to a location, find the associated translation strings
    if (fieldName === 'type' && timelinePageType === 'location') {
        if (newValue) {
            translatedNewValue = `${timelinePageType}.type.${newValue}`;
        }
        if (previousValue) {
            translatedPreviousValue = `${timelinePageType}.type.${previousValue}`;
        }
    }
    
    return { translatedFieldName, translatedNewValue, translatedPreviousValue };
}