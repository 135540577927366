import { WorkflowType } from "./WorkflowWizard";

export const jobWorkflowConfig: WorkflowType = {

    // Set the workflow connected feature
    connected_feature: 'job',
    
    // Define the triggers for this workflow type
    conditions: [
        { name: 'Job created', value: 'created' },
        { name: 'At least 1 scheduled appointment', value: 'one-scheduled-appointment' },
        { name: 'At least 1 reserved appointment', value: 'one-reserved-appointment' },
        { name: 'Job verified', value: 'verified' }
    ],

    // Define the steps for this workflow type
    statuses: [
        {
            key: 1,
            name: 'Nieuw',
            description: 'De eerste stap van deze opdracht workflow. De opdracht is aangemaakt en nu moeten de vervolgstappen voor deze werksoort worden ingesteld, bijvoorbeeld het verzenden van een factuur, het bestellen van onderdelen of het inplannen van de opdracht.', 
            condition: 'created',
            settings: [
                {
                    type: 'dropdown',
                    alignment: 'horizontal',
                    name: 'job_billing_type',
                    label: 'Afrekenmethode',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'calculation', name: 'Nacalculatie / Regie' },
                        { value: 'fixed', name: 'Vaste prijs' },
                        { value: 'non_billing', name: 'Interne rekening' }
                    ]
                },
            ]
        }
    ],

    // Define the available actions for this workflow type
    availableActions: [
        'send_invoice', 
        'create_purchase_order', 
        'schedule_appointment', 
        'schedule_target_date'
    ]

    // conditions: [
    //     // To be implemented
    // ]
};