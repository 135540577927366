import React, { useMemo } from 'react';
import { TimelineItemType, TimelineTaskTypeType, TimelineUserType } from 'types/TimelineTypes';
import TimelineUpdate from './TimelineUpdate';
import TimelineNote from './TimelineNote';
import TimelineTask from './TimelineTask';

/*
 * GenerateTimelineComponents.tsx
 * Function that is responsible for generate the right timeline components. First it maps the fetched users
 * and tasktypes. This is needed to make filtering faster. Then it generates the timeline components based
 * of the object type of the timeline item: if it's an update, note, task or email. In some timelines it is
 * desirable to only show updates. Therefore, if the showOnlyTimelineItems option is set to true, all other
 * timeline items except updates will be filtered out. Since we want to display in the timeline items which 
 * person added the item, and this data is not available in the timeline item object itself, we pass this 
 * along from the usermap function. The same applies to tasks, with tasks we want to show the correct icon so 
 * that we pass on the task type. Finally, this function also ensures that any pinned note is rendered.
 */

type TimelineComponentsRenderProps = {
    renderPinnedNote: () => JSX.Element | null;
    timelineComponents: (JSX.Element | null)[];
}

type GenerateTimelineComponentsProps = {
    viewKey: string;
    timelineItems: TimelineItemType[];
    showOnlyTimelineUpdates: boolean | undefined;
    pinnedNoteIds: number[],
    pinnedNote: TimelineItemType | null;
    users: TimelineUserType[];
    taskTypes: TimelineTaskTypeType[];
    timeline_page_id: string;
    timeline_page?: string;
    children: (props: TimelineComponentsRenderProps) => JSX.Element;
}

export const GenerateTimelineComponents: React.FC<GenerateTimelineComponentsProps> = ({
    viewKey, timelineItems, showOnlyTimelineUpdates, pinnedNoteIds, pinnedNote, users, taskTypes, timeline_page_id, timeline_page, children
}) => {

    // Map the users to query on them while generating the timeline components
    const userMap = useMemo(() => {
        const map = new Map<string | number, TimelineUserType>();
        users.forEach(user => map.set(user.user_hash, user));
        return map;
    }, [users]);

    // Map the task types to query on them while generating the timeline components
    const taskTypeMap = useMemo(() => {
        return new Map(taskTypes.map(taskType => [taskType.id, taskType]));
    }, [taskTypes]);

    // Generates the timeline components (except pinned notes)
    const timelineComponents = useMemo(() => {
        const filteredItems = [...timelineItems]
            .reverse()
            .filter(item => !pinnedNoteIds.includes(item.timeline_object_id));

        return filteredItems.map((item: TimelineItemType) => {
            // Only shows timeline updates in case the showOnlyTimelineUpdates is set to true
            if (showOnlyTimelineUpdates && item.timeline_object_type !== 'update') {
                return null;
            }

            // Renders the timeline components
            switch(item.timeline_object_type) {
                case 'update':
                    // Get the user data which created the update
                    const updateCreatedBy = item.update?.user_hash ? userMap.get(String(item.update?.user_hash)) : null;

                    // Renders the component
                    return <TimelineUpdate 
                                key={item.id} 
                                item={item} 
                                createdBy={updateCreatedBy} />;

                case 'note':
                    // Get the user data which created the note
                    const noteCreatedBy = item.note?.created_by_user_hash ? userMap.get(String(item.note?.created_by_user_hash)) : null;

                    // To hide the pin icon in the note, determine if this note isn't pinned 
                    const isAnotherPinned = pinnedNote && item.timeline_object_id !== pinnedNote.timeline_object_id;

                    // Renders the component
                    return <TimelineNote 
                                key={item.id} 
                                item={item} 
                                createdBy={noteCreatedBy} 
                                isAnotherPinned={isAnotherPinned || undefined} />;

                case 'task':
                    // Get the user data which created and modified the task
                    const taskCreatedBy = item.task?.created_by_user_hash ? userMap.get(String(item.task?.created_by_user_hash)) : null;
                    const taskModifiedBy = item.task?.created_by_user_hash ? userMap.get(String(item.task?.modified_by_user_hash)) : null;

                    // Get the user data of the task assignee
                    const taskAssignee = item.task?.assignee_hash ? userMap.get(String(item.task?.assignee_hash)) : null;

                    // Get the task type data based on the task type id
                    const taskType = item.task?.type ? taskTypeMap.get(item.task.type) : null;

                    // Renders the component
                    return <TimelineTask 
                                viewKey={viewKey}
                                key={item.id} 
                                timeline_page_id={timeline_page_id} 
                                timeline_page={timeline_page} 
                                item={item} 
                                assignee={taskAssignee} 
                                tasktype={taskType} 
                                createdBy={taskCreatedBy} 
                                modifiedBy={taskModifiedBy} />

                case 'email':
                    return null;
                    // TODO  return <TimelineEmail />

                default:
                    return null;
            }
        });
    }, [timelineItems, showOnlyTimelineUpdates, pinnedNoteIds, pinnedNote, taskTypeMap, timeline_page, timeline_page_id, userMap]);

    // Renders the pinned note to put seperated on top of the timeline
    const renderPinnedNote = () => {
        if (pinnedNote) {
            // Maps the user hash of the note to the user object and pas it to the component
            const noteCreatedBy = pinnedNote.note?.created_by_user_hash ? userMap.get(String(pinnedNote.note?.created_by_user_hash)) : null;

            // Renders the timeline note component
            return <TimelineNote 
                        key={pinnedNote.id} 
                        item={pinnedNote} 
                        createdBy={noteCreatedBy} 
                        isPinned={true} />;
        }
        return null;
    };

    // Return the components
    return children({
        renderPinnedNote: renderPinnedNote,
        timelineComponents: timelineComponents
    });
}