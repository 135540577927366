import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const JobWorkflowForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const apiObject = 'workflow'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'workflow.general.name_label',
            type: 'text',
            placeholder: 'workflow.general.name_placeholder',
            helperText: 'job.workflow.name_helperText'
        },
        { 
            type: 'radio', 
            name: 'job_is_project',
            label: 'job.workflow.type_label',
            tooltipText: 'job.workflow.type_tooltip',
            defaultValue: 'false', 
            options: [
                { value: false, name: 'job.general.single_job_label'},
                { value: true, name: 'job.general.longer_term_project_label'},
            ],
            showOnEdit: false, // Not possible to change the job type when editing a workflow
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default JobWorkflowForm;