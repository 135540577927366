import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const GroupForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'group'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'group.general.name_label',
            type: 'text',
            placeholder: 'group.general.name_placeholder',
        },
        { 
            type: 'multiselect', 
            name: 'users', 
            label: 'group.general.users_label',
            apiObject: 'user',
            disabled_selected: 'group.general.users_disabled_selected',
            groupByField: {
                groupByField: 'role',
                apiObject: 'role',
                apiField: 'name',
            },
            allowNoneOption: false,
            optionFormat: (option) => option.full_name,
            selectionFormat: 'full_name',
            params: {
                is_active: true,
            },
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default GroupForm;