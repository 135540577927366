import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import PageAlert from './PageAlert';

const PageNotFound = () => {
    const { isLoggedIn } = useAuthContext();

    if (!isLoggedIn) {
        return <Redirect to="/login" />
    }

    return (
        <PageAlert 
            type='warning'
            title='alert.page_not_found_label'
            message="alert.page_not_found_message" />
    );
};

export default PageNotFound;