import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../components/modals/ModalContext';
import { useFetchData } from 'services/api/useFetchData';
import { useSettings } from 'services/settings/SettingsContext';
import { ReleaseType } from './ReleaseTypes';
import ReleaseTypeBadge from './functions/ReleaseTypeBadge';
import { formatDateString } from 'services/utils/dateTimeUtils';
import '../../../style/scss/modals.scss';

const ReleaseViewModal: React.FC<{ itemId?: number, viewKey?: string }> = ({ itemId, viewKey }) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const { revealModal } = useModal();
    const { response } = useFetchData({ apiUrl: `get_release/${itemId}` });    
    const [releaseData, setReleaseData] = useState<ReleaseType>();
    
    // Set the fetched response
    useEffect(() => {
        if (response) setReleaseData(response);
    }, [response]);

    // Reveal the modal after getting the data
    useEffect(() => {
        if (itemId !== null && releaseData) revealModal();
    }, [itemId, releaseData]);

    // Destructure the release data if it is set
    const { version, release_name, type, description, change_log, release_date } = releaseData || {};

    return (
        <div className='release-modal'>
            <div className='header'>
                <div>
                    <h2>{t('release.general.release_title')} {version}</h2>
                </div>
                <div className='right-header'>
                    <div className='details'>
                    {release_name && (
                        <>
                            <h5>{release_name}</h5>
                            <span style={{ margin: '0 5px' }}>•</span>
                        </>
                    )}
                    {release_date && (
                        <h5>{formatDateString(release_date, userLocale)}</h5>
                    )}
                    </div>
                    {type && (
                        <>
                            <span style={{ margin: '0 5px' }}></span>
                            <ReleaseTypeBadge releaseType={type} />
                        </>
                    )}
                </div>
            </div>
            <div className='content'>
                {description && (
                    <div className='description'>
                        <div>
                            <p className='textarea'
                               dangerouslySetInnerHTML={{ __html: description as string }} 
                            />
                        </div>
                    </div>
                )}
                {change_log && (
                    <div className='change-log'>
                        <h6>{t('release.general.change_log_label')}</h6>
                        <div>
                            <p className='textarea'
                               dangerouslySetInnerHTML={{ __html: change_log as string }} 
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReleaseViewModal;