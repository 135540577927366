import { faPaperPlane, faExclamationCircle, faPhone, faInbox, faPrint, faHandshake, faClock, faSquareCheck, faNoteSticky, faCalendar, faFlag, faUtensils, faComment, faFileInvoice, faBook, faFolderOpen, faMobile, faTablet, faCarSide, faToolbox, faWrench, faTag, faCamera, faTruckField, faTrophy, faAlignLeft, faAlignCenter, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/*
 * iconMapping.ts
 * Map fontAwesome icons to the icon name in our platform.
 */

export const iconMapping = (iconName: string): IconDefinition => {
    const icons: Record<string, IconDefinition> = {
        call: faPhone,
        email: faPaperPlane,
        meeting: faHandshake,
        checkbox: faSquareCheck,
        deadline: faFlag,
        lunch: faUtensils,
        inbox: faInbox,
        print: faPrint,
        time: faClock,
        calender: faCalendar,
        note: faNoteSticky,
        chat: faComment,
        document: faFileInvoice,
        book: faBook,
        folder: faFolderOpen,
        smartphone: faMobile,
        tablet: faTablet,
        car: faCarSide,
        toolbox: faToolbox,
        wrench: faWrench,
        tag: faTag,
        camera: faCamera,
        van: faTruckField,
        trophy: faTrophy,
        alignLeft: faAlignLeft,
        alignCenter: faAlignCenter,
        alignRight: faAlignRight,
    }

    return icons[iconName] || faExclamationCircle;
}