import React from 'react';
import { FormButtonType } from 'types/ButtonTypes';
import { FieldOption, FieldType } from 'types/FieldTypes';
import InlineForm from 'components/forms/InlineForm';
import { generateCurrentTimezoneString, languageOptions, localeOptions } from 'internationalization/i18n';
import { useSettings } from 'services/settings/SettingsContext';

const PersonalPreferencesForm: React.FC<{ viewKey?: string, userHash?: string }> = ({ viewKey, userHash }) => {
    const { userTimezone, userLocale, userPreferences } = useSettings();
    const apiObject = 'user_preference';

    // Function to convert hardcoded options into dropdown options
    function convertOptionsToDropdownOptions(options: Record<string, { label: string }>): FieldOption[] {
        return Object.entries(options).map(([value, { label }]) => ({
            value,
            name: label,
        }))
    };
    
    // Convert language and locale options into dropdown options
    const languageDropdownOptions: FieldOption[] = convertOptionsToDropdownOptions(languageOptions);
    const localeDropdownOptions: FieldOption[] = convertOptionsToDropdownOptions(localeOptions);

    // Get the current timezone string as disabled dropdown option
    const currentTimezoneString: FieldOption = { 
        value: userTimezone ?? 'UTC', 
        name: generateCurrentTimezoneString(userTimezone ?? 'UTC', userLocale)
    };

    const fields: FieldType[] = [
        {
            type: 'header',
            header: 'personal_preferences.general.header_general',
        },
        {
            type: 'text',
            name: 'first_name',
            label: 'personal_preferences.general.first_name_label',
            placeholder: 'personal_preferences.general.first_name_placeholder',
            width: '50%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'last_name',
            label: 'personal_preferences.general.last_name_label',
            placeholder: 'personal_preferences.general.last_name_placeholder',
            width: '50%',
            group: 'field-row',
        },
        {
            name: 'profile_picture',
            label: 'user.general.profile_picture_label',
            type: 'image',
            imageType: 'smallCircle',
            dropzoneText: 'user.general.profile_picture_placeholder',
            apiField: { 
                fileUpload: 'profile_picture_file', 
                fileName: 'profile_picture_filename'
            },
            cropConfig: {
                aspectRatio: 1,
                cropShape: 'round',
                resize: { width: 200, height: 200 }
            }
        },
        {
            type: 'dropdown',
            name: 'timezone',
            label: 'personal_preferences.general.timezone_label',
            disabled_selected: 'personal_preferences.general.timezone_disabled_selected',
            disabled: true,
            helperText: 'personal_preferences.general.timezone_helperText',
            allowNoneOption: false,
            defaultValue: { value: userTimezone },
            hardcodedOptions: [currentTimezoneString]
        },
        {
            type: 'dropdown',
            name: 'user_locale',
            label: 'personal_preferences.general.user_locale_label',
            disabled_selected: 'personal_preferences.general.user_locale_disabled_selected',
            allowNoneOption: false,
            hardcodedOptions: localeDropdownOptions,
            defaultValue: { value: userPreferences?.user_locale }
        },
        {
            type: 'dropdown',
            name: 'language',
            label: 'personal_preferences.general.language_label',
            disabled_selected: 'personal_preferences.general.language_disabled_selected',
            allowNoneOption: false,
            hardcodedOptions: languageDropdownOptions,
            defaultValue: { value: userPreferences?.language }
        },
        {
            type: 'dropdown',
            name: 'default_currency',
            label: 'personal_preferences.general.default_currency_label',
            disabled_selected: 'personal_preferences.general.default_currency_disabled_selected',
            allowNoneOption: false,
            apiObject: 'currency',
            backendField: 'key'
        },
        {
            type: 'header',
            header: 'personal_preferences.general.header_interface',
        },
        {
            type: 'checkbox',
            name: 'open_tabs',
            align: 'left',
            label: 'personal_preferences.general.open_tabs_label'
        },
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'general.save',
            position: 'left',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'small',
            showLoader: true,
        },
    ];

    return (
        <InlineForm
            viewKey={viewKey!}
            itemStr={userHash}
            apiObject={apiObject}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default PersonalPreferencesForm;