import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import TaskTypeForm from './TaskTypeForm';

// Rename the name of the function:
const TaskTypeList: React.FC = () => {

    // Specify the options of this list:
    const query = { 
        endpoint: 'get_tasktype_list',
        object: 'tasktype'
    }
    const objectName = 'tasktype.general.object_name'
    const showSearch = false
    const isDraggable = true
    const showDeleteOrDeactivate = 'fully_delete'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <TaskTypeForm />;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'tasktype.general.name_label' },
    ];

    return (
        <AbstractList
            objectName={objectName}
            query={query}
            columns={columns}
            showSearch={showSearch}
            isDraggable={isDraggable}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default TaskTypeList;