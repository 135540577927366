import React from 'react';
import { GlobalProvider } from 'GlobalContext';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationContextProvider } from 'services/authentication/AuthenticationContext';
import { FetchContextProvider } from 'services/api/FetchContext';
import { SettingsContextProvider } from 'services/settings/SettingsContext';
import { ModalProvider } from 'components/modals/ModalContext';
import Navbar from 'components/layout/Navbar';
import Routes from './routes';
import GenericModal from 'components/modals/GenericModal';
import FloatingAlert from 'components/alerts/FloatingAlert';
import FooterLogo from 'components/layout/FooterLogo';

// Point the frontend base url to the Heroku url or to the localhost
export const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost';

// Configure api base url
export const apiBaseUrl = `${baseUrl}/api`;

// Configure websocket base url
export const websocketBaseUrl = process.env.NODE_ENV === 'production'
    ? `wss://${window.location.host}/ws`
    : 'ws://localhost/ws';


function App() {
    return (
        <div className='app-container'>
            <GlobalProvider>
                <BrowserRouter>
                    <AuthenticationContextProvider>
                        <FetchContextProvider>
                            <SettingsContextProvider>
                                <ModalProvider>
                                    <Navbar />
                                    <main>
                                        <Routes />
                                        <GenericModal />
                                        <FloatingAlert />
                                    </main>
                                    <FooterLogo />
                                </ModalProvider>
                            </SettingsContextProvider>
                        </FetchContextProvider>
                    </AuthenticationContextProvider>
                </BrowserRouter>
            </GlobalProvider>
        </div>
    );
}

export default App;