import { useEffect, useState, useRef } from 'react';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { websocketBaseUrl } from 'App';

export const useWebSocket = ({ 
    url, websocketGroup 
}: { url: string, websocketGroup: string | undefined }): { message: any } => {
    const { isLoggedIn } = useAuthContext();
    const [message, setMessage] = useState<any>(null);
    const shouldReconnect = useRef(true);
    const webSocket = useRef<WebSocket | null>(null);

    useEffect(() => {
        if (websocketGroup !== undefined && websocketGroup !== '') {
            const setupWebSocket = () => {
                // Configure the websocket url
                webSocket.current = new WebSocket(`${websocketBaseUrl}/${url}/${websocketGroup}/`);

                // Parse the received websocket message
                webSocket.current.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    setMessage(data);
                };

                // Try to reconnect in 10 seconds on close
                webSocket.current.onclose = () => {
                    if (shouldReconnect.current) {
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            };

            // Listen to the websocket when the user is logged in
            if (isLoggedIn) setupWebSocket();

            // Clear websocket by unmount
            return () => {
                shouldReconnect.current = false;
                if (webSocket.current) {
                    webSocket.current.close();
                }
            };
        }
    }, [url, websocketGroup, isLoggedIn]);

    return { message };
};