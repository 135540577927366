import { createContext } from "react";

/*
 * FormFieldContext.tsx
 * This context is created to contain information about (form)fields 
 * so that it can be shared with other files related to those components.
 */

type FormFieldContextType = {
    // Keep track of the editing state
    editing?: boolean;
    setEditing?: (value: boolean) => void;

    // Keep track of updated data before fetching to the server
    updatedData: Record<string, any | any[]>;
    setUpdatedData: (value: Record<string, any>) => void;

    // Keep track if the form button is loading
    buttonLoader?: boolean;
    setButtonLoader?: (value: boolean) => void;

    // Keep track if an error message on top of a field has to be shown
    showErrorAlert?: boolean;
    setShowErrorAlert?: (value: boolean) => void;

    // Shares the handleSubmit function, with optionally accepting the submit data for save on blur functionality
    handleSubmit?: (newData?: Record<string, any>) => Promise<void>;
}

const FormFieldContext = createContext<FormFieldContextType>({
    updatedData: {},
    setUpdatedData: () => {},
    editing: undefined,
    setEditing: undefined,
    buttonLoader: undefined,
    setButtonLoader: undefined,
    showErrorAlert: undefined,
    setShowErrorAlert: undefined,
    handleSubmit: undefined,
});

export default FormFieldContext;