import React, { useEffect } from 'react';
import { useModal } from 'components/modals/ModalContext';
import { baseUrl } from 'App';
import SecondaryButton from 'components/buttons/SecondaryButton';
import '../../../style/scss/attachment-viewer.scss';

interface AttachmentViewType {
    type: 'photo' | 'attachment';
    file: string;
    filename: string;
    description?: string;
}

const AttachmentViewer: React.FC<AttachmentViewType> = ({ 
    type, file, filename, description
}) => {
    const { revealModal, closeModal } = useModal();

    // Reveal modal on component mount
    useEffect(() => revealModal(), []);

    return (
        <div className='attachment-viewer-modal'>
            <h4 className='header text-center'>{filename}</h4>
            {description && <div className="helper-text">{description}</div>}
            <div className={`content ${type === 'photo' ? 'photo-content' : 'attachment-content'}`}>
                {type === 'attachment' ? (
                    <div className='attachment-viewer'>
                        <iframe src={file} frameBorder="0"></iframe>
                    </div>
                ) : (
                    <div className="photo-viewer">
                        <img src={`${baseUrl}${file}`} alt={filename} id="viewer-img" className="viewer-image" />
                    </div>
                )}
            </div>
            <div className='footer'>
                <SecondaryButton
                    onClick={closeModal} 
                    label="general.close"
                    size="small"/>
            </div>
        </div>
    )
}

export default AttachmentViewer;