export interface TimeTypeType {
    id: number;
    uuid?: string;
    name: string | null;
    type: string | null;
    is_calculated_leave: boolean;
    is_active: boolean;
    division: number | null;
}

export interface TimeRowType {
    id: number | null;
    uuid?: string;
    job: number | null;
    appointment: number | null;
    registration_mode: string | null;
    time_type: number | null;
    date: string | null;
    start_datetime: string | null;
    end_datetime: string | null;
    start_date: string | null;
    end_date: string | null;
    start_time: string | null;
    end_time: string | null;
    duration: number | null;
    duration_string: string | null;
    assignee: number | null;
    hourly_rate: number | null;
    total_price: number | null;
    note: string | null;
    deleted: boolean;
}

export const createNewTimeRow = ({ 
    jobId, appointmentId, uuid, registration_mode
}: { jobId?: number, appointmentId?: number, uuid: string, registration_mode?: string }): TimeRowType => ({
    id: null,
    uuid: uuid,
    job: jobId ?? null,
    appointment: appointmentId ?? null,
    registration_mode: registration_mode ?? null,
    time_type: null,
    date: null,
    start_datetime: null,
    end_datetime: null,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    duration: null,
    duration_string: null,
    assignee: null,
    hourly_rate: null,
    total_price: 0,
    note: null,
    deleted: false,
})