import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseWidgetProps } from 'types/WidgetTypes';
import NumberWidget from 'components/widgets/variants/NumberWidget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/widgets.scss';

const BaseWidget: React.FC<BaseWidgetProps> = ({ 
    widgetKey, variant, period, data, loading, title, tooltipText, calculationMethod, prefix, measuringUnit, decimals, 
    moreIsNegative = false
}) => {
    const { t } = useTranslation();

    // Determine the right period translation labels based on the given period of the widget
    const periodLabel = period === 'week' ? 'date_time.general.week' : 'date_time.general.month';
    const periodLabelShort = period === 'week' ? 'date_time.general.week_short' : 'date_time.general.month_short';

    return (
        <div className='section widget'>
            <div className='title'>
                {t(title, { period_short: t(periodLabelShort).toLowerCase() })}
                {tooltipText && (
                    <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <span className="tooltip tooltip-right">{t(tooltipText, { period: t(periodLabel) })}</span>
                    </span>
                )}
            </div>
            {loading ? (
                // Show loader when the data is being fetched
                <div className='loader-container'>
                    <div className="loader" />
                </div>
            ) : (
                variant === 'numberWidget' && (
                    <NumberWidget
                        widgetKey={widgetKey}
                        defaultData={data.defaultData}
                        dimensionData={data.dimensionData}
                        calculationMethod={calculationMethod}
                        period={period}
                        prefix={prefix}
                        measuringUnit={measuringUnit}
                        decimals={decimals}
                        moreIsNegative={moreIsNegative}
                    />
                )
                // Add other widget variants here
            )}
        </div>
    );
};

export default BaseWidget;