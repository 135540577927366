import { createContext } from 'react';
import { ListRowType, ListColumnType, QueryParamType, ListMetaDataType } from 'types/ListTypes';

/*
 * ListContext
 * This context is created to contain information about lists which 
 * can be shared across the list components.
 */

interface ListContextProps<T extends ListRowType> {
    // Keep track if the list is loading
    loading: string;

    // Keep track of the query params of the list
    queryParams: QueryParamType;

    // Keep track of the data in the list
    data: T[];
    setData: (data: T[]) => void;

    // Keep track of the list meta data such as current page, total items and total pages
    listMetaData: ListMetaDataType;
    setListMetaData: (metaData: ListMetaDataType) => void;

    // Keep track of the ordering of the list
    ordering: string | null;

    // Keep track of the table width class
    tableWidthClass: string;

    // Keep track of a search term is given
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;

    // Keep track of the last search term
    lastSearchTerm: string | null;
    setLastSearchTerm: (lastSearchTerm: string | null) => void;

    // Keep track of the available columns in the list
    availableColumns: ListColumnType[];
    setAvailableColumns: (availableColumns: ListColumnType[]) => void; 

    // Keep track of the selected columns in the list
    selectedColumns: ListColumnType[];
    setSelectedColumns: (selectedColumns: ListColumnType[]) => void; 

    // Callback to update the query params (which refetches with the new params)
    updateQueryParams: (newParams: QueryParamType) => void;

    // Callback to handle the page change
    handlePageChange: (newPage: number) => void;

    // Callback to handle the search change
    handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    // Callback to handle the column ordering
    handleColumnOrdering: (columnField: string) => void;

    // Callback to handle the 
    handleDragOrdering: (newOrder: ListRowType[]) => void;

    // Callback to refetch the list
    refetchList: () => void;
}

const ListContext = createContext<ListContextProps<any>>(undefined!);

export default ListContext;