/*
 * timefieldFormatting.ts
 * Format a user-entered time value to a standard 24-hour or 12-hour format.
 *
 * This function handles a variety of input formats, such as:
 * 
 * When using 12-hour format:
 * - "230am", "230 am", "2.30am", "2.30 am", "2:30am" or "2:30 am" => becomes "2:30 AM"
 * - "2am" => becomes "2:00 AM"
 * - "3" or "15" => becomes "3:00 AM" or "3:00 PM"
 * - "15:39" => becomes "3:39 PM"
 * 
 * When using 24-hour format:
 * - '8' => '8:00'
 * - '800' or '815' => '8:00' or '8:15'
 * - '8.00' or '8.15' => '8:00' or '8:15'
 */

export function formatTime(time: string, is12HourFormat: boolean): string {
    // Get the current value of the input
    let newTime = time.trim();

    // Format time for 12-hours format (AM/PM)
    if (is12HourFormat === true) {

        // Scenario: "230am" or "530pm"
        if (/^\d{3,4}(am|pm)$/i.test(newTime)) {
            const matched = newTime.match(/am|pm/i);
            let period = matched![0].toUpperCase();
            let hours = parseInt(newTime.slice(0, -4));
            let minutes = newTime.slice(-4, -2);

            newTime = `${hours}:${minutes} ${period}`;
        }

        // Scenario: "230 am" or "530 pm"
        else if (/^\d{3,4}\s(am|pm)$/i.test(newTime)) {
            const matched = newTime.match(/am|pm/i);
            let period = matched![0].toUpperCase();
            let hours = parseInt(newTime.split(/\s+/)[0].slice(0, -2));
            let minutes = newTime.split(/\s+/)[0].slice(-2);

            newTime = `${hours}:${minutes} ${period}`;
        }

        // Scenario: "2.30am" or "2:30am"
        else if (/^\d{1,2}[.:]\d{2}(am|pm)$/i.test(newTime)) {
            const matched = newTime.match(/am|pm/i);
            if (!matched) {
                return "";
            }
            let period = matched[0].toUpperCase();
            let hours: number = 0;
            let minutes: string = '00';
        
            // Split time from the period ("am" or "pm")
            const timeWithoutPeriod = newTime.slice(0, newTime.length - 2).trim();
        
            if (timeWithoutPeriod.includes(':')) {
                const [hoursStr, minutesStr] = timeWithoutPeriod.split(':');
                hours = Number(hoursStr);
                minutes = minutesStr.trim();
            } else if (timeWithoutPeriod.includes('.')) {
                const [hoursStr, minutesStr] = timeWithoutPeriod.split('.');
                hours = Number(hoursStr);
                minutes = minutesStr.trim();
            }
        
            newTime = `${hours}:${minutes.padStart(2, '0')} ${period}`;
        }

        // Scenario: "2.30 am" en "2:30 am"
        else if (/^\d{1,2}[.:]\d{2}\s(am|pm)$/i.test(newTime)) {
            const matched = newTime.match(/am|pm/i);
            if (!matched) {
                return "";
            }
            let period = matched[0].toUpperCase();
            let timeWithoutPeriod = newTime.replace(/am|pm/i, '').trim();
            let hours: number = 0, minutes: string = "00";

            if (timeWithoutPeriod.includes(':')) {
                const [hoursStr, minutesStr] = timeWithoutPeriod.split(':');
                hours = Number(hoursStr.trim());
                minutes = minutesStr.trim();
            } else if (timeWithoutPeriod.includes('.')) {
                const [hoursStr, minutesStr] = timeWithoutPeriod.split('.');
                hours = Number(hoursStr.trim());
                minutes = minutesStr.trim();
            }

            newTime = `${hours}:${minutes.padStart(2, '0')} ${period}`;
        }

        // Scenario: "2am" or "2pm"
        else if (/^\d{1,2}(am|pm)$/i.test(newTime)) {
            const matched = newTime.match(/am|pm/i);
            if (!matched) {
                return "";
            }
            let period = matched[0].toUpperCase();
            let hours = parseInt(newTime.replace(/am|pm/i, '').trim());

            newTime = `${hours}:00 ${period}`;
        }

        // Scenario: "3" or "15"
        else if (/^\d{1,2}$/.test(newTime)) {
            let hours = parseInt(newTime);

            let period = 'AM';
            if (hours >= 12) {
                if (hours > 12) {
                    hours -= 12;
                }
                period = 'PM';
            }

            newTime = `${hours}:00 ${period}`;
        }

        // Scenario: User entered "15:39" while in 12h format
        else if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(newTime) && is12HourFormat) {
            let hours = parseInt(newTime.split(':')[0]);
            let minutes = newTime.split(':')[1];
            const period = hours >= 12 ? 'PM' : 'AM';
            if (hours > 12) {
                hours -= 12;
            }
            newTime = `${hours}:${minutes} ${period}`;
        }
    // Format time for 24-hours format (23:59)
    } else {
        // Check if the user entered a value like '800' or '815'
        if (/^\d{1,4}$/.test(newTime)) {
            let hours, minutes;
        
            if (newTime.length > 2) {
                hours = parseInt(newTime.slice(0, -2));
                minutes = newTime.slice(-2);
            } else {
                hours = parseInt(newTime);
                minutes = '00';
            }
        
            if (is12HourFormat) {
                const period = hours >= 12 ? 'PM' : 'AM';
                if (hours > 12) {
                    hours -= 12;
                }
                newTime = `${hours}:${minutes} ${period}`;
            } else {
                newTime = `${hours}:${minutes}`;
            }
        }

        // Check if the user entered a value like '8.00' or '8.15'
        else if (/^\d+\.\d{2}$/.test(newTime)) {
            let hours = Number(newTime.split('.')[0]);
            let minutes = newTime.split('.')[1];
            if (is12HourFormat) {
                const period = hours >= 12 ? 'PM' : 'AM';
                if (hours > 12) {
                    hours -= 12;
                }
                newTime = `${hours}:${minutes} ${period}`;
            } else {
                newTime = `${hours}:${minutes}`;
            }
        }
    }

    return newTime;
}