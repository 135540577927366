import React, { useState, useContext, useEffect } from 'react';
import { AppointmentsRowsFieldType, FieldData } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { formatDecimalToHoursAndMinutes } from 'services/utils/convertDecimalToHoursAndMinutes';
import { convertRowsToLocalDateTimes, sortRowsByDate } from 'services/utils/sortDates';
import { combineDateAndTimeInputsToDatetimeString, convertTimeStringToDate } from 'services/utils/dateTimeUtils';
import { convertLocalDateTimeToUTC, extractDateFromLocalDateTime, extractTimeFromLocalDateTime } from 'internationalization/timezoneConversions';
import { renderRowsFieldErrorMessages } from './functions/renderRowsFieldErrorMessages';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AppointmentType, createNewAppointment } from '../../../views/jobs/JobTypes';
import { UserType } from '../../../views/users/UserTypes';
import DateInput from '../basefields/DateInput';
import TimeInput from '../basefields/TimeInput';
import MultiSelect from '../basefields/MultiSelect';
import Dropdown from '../basefields/Dropdown';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const AppointmentsRowsField: React.FC<AppointmentsRowsFieldType & { data: FieldData }> = ({
    name, jobId, data, dropdownData, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const { errorMessages } = useGlobalContext();
    const { userLocale, userTimezone, getTimeFormat, environmentSettings } = useSettings();
    // const { initializeModal } = useModal();
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<AppointmentType[]>([]);
    const [assignees, setAssignees] = useState<UserType[]>([]);
    const [appointmentStatuses] = useState<{ value: string, name: string }[]>([ 
        { value: 'to_be_assigned', name: 'appointment.status.to_be_assigned' }, 
        { value: 'reserved', name: 'appointment.status.reserved' }, 
        { value: 'scheduled', name: 'appointment.status.scheduled' }, 
        { value: 'done', name: 'appointment.status.done' }]);
    const [showEndDateField, setShowEndDateField] = useState<boolean>(false);
    const [scheduleMultipleAppointments, setScheduleMultipleAppointments] = useState<boolean>(false);
    const is12HourFormat = getTimeFormat() === '12h';
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));  

    // Load the appointments from the fetched data
    useEffect(() => {
        if (data && data[name] && data[name].length === 0) {
            // If the response is empty, create an empty row
            if (jobId) setRows([createNewAppointment(jobId, uuidv4())]);
        } else {
            // Convert the backend utc datetimes to local datetimes
            const { rowsWithLocalDatetimes } = convertRowsToLocalDateTimes<AppointmentType>(data[name], 'start_datetime', 'end_datetime', userLocale, userTimezone)

            // Initiate variable to show end date field
            let shouldShowEndDateField = false;

            // Convert start- and end-datetimes into separated date and time fields
            const processedRows = rowsWithLocalDatetimes.map((row: AppointmentType) => {
                let startDate, startTime, endDate, endTime, utcStartDatetime, utcEndDatetime;

                if (row.start_datetime) {
                    startDate = extractDateFromLocalDateTime(row.start_datetime) || null;
                    startTime = extractTimeFromLocalDateTime(row.start_datetime, userLocale, is12HourFormat) || null;
                    utcStartDatetime = convertLocalDateTimeToUTC(row.start_datetime, userLocale, userTimezone) || null;
                }

                if (row.end_datetime) {
                    endDate = extractDateFromLocalDateTime(row.end_datetime) || null;
                    endTime = extractTimeFromLocalDateTime(row.end_datetime, userLocale, is12HourFormat) || null;
                    utcEndDatetime = convertLocalDateTimeToUTC(row.end_datetime, userLocale, userTimezone) || null;
                }

                // Check if the end_date differs from the start date of non-deleted rows
                if (startDate && endDate && startDate !== endDate && !row.deleted) {
                    shouldShowEndDateField = true;
                }

                // Map the ids of the assignees to an array of ids
                const assigneeIds = row.assignees?.map(assignee => assignee.id) || [];

                // Update the row with the dates and times
                return {
                    ...row,
                    start_datetime: utcStartDatetime || null,
                    end_datetime: utcEndDatetime || null,
                    start_date: startDate || null,
                    start_time: startTime || null,
                    end_date: endDate || null,
                    end_time: endTime || null,
                    assignee_ids: assigneeIds,
                };
            });

            // Set visibility of end date field
            setShowEndDateField(shouldShowEndDateField);

            // Sort the rows on date
            const { sortedRows } = sortRowsByDate(processedRows, 'start_date');

            // Format the duration and total price
            sortedRows.map((row: AppointmentType) => {

                // If a duration exists, convert it to a time field
                if (row.duration !== null) {

                    // Format the duration decimal value into a string
                    row.duration_string = formatDecimalToHoursAndMinutes(row.duration)

                } else {
                    // If no duration exists, set the duration string to an empty string
                    row.duration_string = '';
                }

                return { ...row };
            });
            setRows(sortedRows);
        }
    }, [data, jobId]);  
    
    // Set the assignees from the dropdown data
    useEffect(() => {
        setAssignees(dropdownData?.user?.results || []);
    }, [dropdownData]);

    // Set the schedule multiple appointments setting to show/hide the add appointments button
    useEffect(() => {
        if (environmentSettings.job_appointment_method === 'multiple') {
            setScheduleMultipleAppointments(true);
        } else {
            setScheduleMultipleAppointments(false);
        }
    }, [environmentSettings]);

    // Handle the date change
    const handleDateChange = (selectedDate: string | null, fieldName: 'start_date' | 'end_date', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the selected date and time values into datetime fields
            let combinedDatetime, utcDatetime;
            if (selectedDate !== null) {

                // Handle datetime conversion when start date field is changed
                if (fieldName === 'start_date') {

                    // If a start time exists, combine the start datetime
                    if (row.start_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.start_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        updatedRows[rowIndex].start_datetime = utcDatetime;
                    }
                    
                    // If the start date and end date are equal, and end time exists, combine the start datetime
                    if ((row.end_date === null || (row.start_date === row.end_date)) && row.end_time) {

                        // Set the end date to the selected date
                        updatedRows[rowIndex].end_date = selectedDate;

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }
                }

                // Handle datetime conversion when end date field is changed
                if (fieldName === 'end_date' && row.end_time) {
                    // Combine the date input and time input into a local datetime string
                    combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                    // Convert the local datetime into an utc datetime string for the backend
                    utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                    // Update the end datetime
                    updatedRows[rowIndex].end_datetime = utcDatetime;
                }
            } else {
                // Handle in case the start date is removed
                if (fieldName === 'start_date') {
                    // If the start date and end date are equal, remove both start- and end-datetimes
                    if (row.start_date && row.end_date && (row.start_date === row.end_date)) {
                        updatedRows[rowIndex].start_datetime = null;
                        updatedRows[rowIndex].end_datetime = null;

                    // Otherwise only remove the start-datetime
                    } else {
                        updatedRows[rowIndex].start_datetime = null;
                    }
                }

                // Handle in case the end date is removed
                if (fieldName === 'end_date') {
                    // If the start date still exists and end time, set the end date to the start date
                    if (row.start_date && row.end_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;

                    // Otherwise remove the end-datetime
                    } else {
                        updatedRows[rowIndex].end_datetime = null;
                    }
                }
            }

            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedDate;

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle time change
    const handleTimeChange = (selectedTime: string | null, fieldName: 'start_time' | 'end_time', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedTime;

            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the time value to a datetime string if the start_date value exists
            let combinedDatetime, utcDatetime;
            if (selectedTime !== null && selectedTime !== '') {

                // Check if the start date exists
                if (row.start_date !== null) {

                    // Handle if the end time changed and the end date differs from the start date
                    if (row.end_date !== null && (row.start_date !== row.end_date) && fieldName === 'end_time') {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.end_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }

                    // Handle if both the end- or start-time changed, but the start- and end-dates are equal
                    if ((row.end_date !== null && (row.start_date === row.end_date)) || row.end_date === null) {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        if (fieldName === 'start_time') {
                            updatedRows[rowIndex].start_datetime = utcDatetime;
                        }
            
                        // Update the end datetime
                        if (fieldName === 'end_time') {
                            updatedRows[rowIndex].end_datetime = utcDatetime;
                        }    
                    }
                }
            
            } else {
                // If the selected time is set to null, remove the start- or end-datetimes
                if (fieldName === 'start_time') {
                    updatedRows[rowIndex].start_datetime = null;
                }
    
                // Update the end datetime
                if (fieldName === 'end_time') {
                    updatedRows[rowIndex].end_datetime = null;
                }
            }

            // Update the duration after changing the time
            if (row.start_time && row.end_time) {

                // Calculate the duration if start- and end-datetime exists
                if (row.start_datetime && row.end_datetime) {

                    // Convert date time strings to date objects
                    const startDate = new Date(row.start_datetime);
                    const endDate = new Date(row.end_datetime);

                    // Check if both date objects are valid
                    if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endDate.getTime() - startDate.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                
                // Calculate the duration only on start- and end-time fields
                } else {
                    // Convert start- and end-time strings to date objects
                    const startTime = convertTimeStringToDate(row.start_time);
                    const endTime = convertTimeStringToDate(row.end_time);

                    if (startTime && endTime && !isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endTime.getTime() - startTime.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                }
            } else {
                // Fallback to 0 if one of the times is missing
                const fallback = formatDecimalToHoursAndMinutes(0);

                // Update the duration string with the fallback
                updatedRows[rowIndex].duration_string = fallback;
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle the dropdown change
    const handleDropdownChange = (selectedValue: string, fieldName: 'status', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedValue

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // Handle the multiselect change
    const handleMultiSelectChange = (selectedValue: string, fieldName: 'assignees', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            let updatedAssignees = rows[rowIndex][fieldName] || [];
            
            // Get the index of the assignee in the current list
            const assigneeIndex = updatedAssignees.findIndex(assignee => assignee.id.toString() === selectedValue);
    
            if (assigneeIndex > -1) {
                // Delete the assignee if it already exists
                updatedAssignees.splice(assigneeIndex, 1);
            } else {
                // Add the assignee if it doesn't exist
                const assigneeToAdd = assignees.find(assignee => assignee.id.toString() === selectedValue);
                if (assigneeToAdd) {
                    updatedAssignees.push(assigneeToAdd);
                }
            }

            // Update assignees for the specific row
            updatedRows[rowIndex][fieldName] = updatedAssignees;
            updatedRows[rowIndex]['assignee_ids'] = updatedAssignees.map(assignee => assignee.id);

            // Update the status based on whether there are assignees
            if (updatedAssignees.length === 0) {
                updatedRows[rowIndex].status = 'to_be_assigned';
            } else {
                updatedRows[rowIndex].status = 'scheduled';
            }
    
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // Handle the creation of a new row
    const handleAddRow = () => {
        if (jobId) {
            // Create a new row
            const newRow = createNewAppointment( jobId, uuidv4() );
        
            // Add the row to the list of rows
            const updatedRows = [...rows, newRow];
        
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // TODO in a later stage. Add new appointments by the schedule job modal. Handle the opening of the schedule job modal
    // const handleOpenModal = (event: React.MouseEvent) => {
    //     if (isEditable && !userHasOnlyViewRights) {
    //         // Only open modal when the object is editable
    //         event.preventDefault()
    //         initializeModal(
    //             <ScheduleJobModal jobId={parseInt(itemId)} setLoading={isLoading => setShowLoader(isLoading)} />, { modalSize: 'medium' }
    //         );
    //     }
    // };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        let updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row from the updated rows
            const row = updatedRows[rowIndex]

            // Check if the row is a new row added in the frontend (it has an uuid but no id)
            if (!row.id && row.uuid) {
                // Directly remove it from the updated rows
                updatedRows = updatedRows.filter((_, index) => index !== rowIndex)
        
            } else {
                // Flag the backend known row as deleted without actually delete it
                updatedRows[rowIndex].deleted = true;
            }
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((row, index) => ({
                ...row,
                position: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedRows.filter(row => row.deleted)];
    
            setRows(finalItems);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalItems }))
        } 
    }

    // Handle the showing of the end date field manually if needed
    const handleEndDateSuggestionClick = () => {
        setShowEndDateField(true);
    };

    return (
        <div className='rows-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {rows.length > 0 && (
                        <div className={`rows-header appointments-row ${showEndDateField ? 'show-end-date-field' : ''}`}>
                            <div className='header-item'>{t('appointment.general.date_label')}</div>
                            <div className='header-item'>{t('appointment.general.start_time_label')}</div>
                            <div className='header-item'>{t('appointment.general.end_time_label')}</div>
                            {showEndDateField && (
                                <div className='header-item'>{t('appointment.general.end_date_label')}</div>
                            )} 
                            <div className='header-item'>{t('appointment.general.hours_label')}</div>
                            <div className='header-item'>{t('appointment.general.assignees_label')}</div>
                            <div className='header-item'>{t('appointment.general.status_label')}</div>
                            <div className='delete-placeholder'></div>
                        </div>
                    )}
                    {rows && rows.filter(row => row.deleted === false).map((row) => {
                        const identifier = row.id?.toString() || row.uuid;
                        
                        return (
                            <div key={identifier} className='list-item'>
                                <div className={`appointments-row ${showEndDateField ? 'show-end-date-field' : ''}`}>            
                                    <DateInput
                                        id={`start_date_${identifier}`}
                                        name={`start_date_${identifier}`}
                                        value={row.start_date ? new Date(row.start_date) : undefined}
                                        onChange={(selectedValue) => handleDateChange(selectedValue, 'start_date', identifier || '')}
                                        isInvalid={identifier && errorMessages?.appointments?.[identifier]?.['start_date'] ? true : false}
                                        placeholder='appointment.general.date_placeholder' 
                                    />
                                    <TimeInput
                                        type='time'
                                        id={`start_time_${identifier}`}
                                        name={`start_time_${identifier}`}
                                        value={row.start_time ?? undefined}
                                        onChange={(selectedTime) => handleTimeChange(selectedTime, 'start_time', identifier || '')}
                                        isInvalid={identifier && errorMessages?.appointments?.[identifier]?.['start_time'] ? true : false}
                                    />
                                    <TimeInput
                                        type='time'
                                        id={`end_time_${identifier}`}
                                        name={`end_time_${identifier}`}
                                        value={row.end_time ?? undefined}
                                        onChange={(selectedTime) => handleTimeChange(selectedTime, 'end_time', identifier || '')}
                                        isInvalid={identifier && errorMessages?.appointments?.[identifier]?.['end_time'] ? true : false}
                                    />
                                    {showEndDateField && (
                                        // Show in case the end date is given but differs from the start date
                                        (row.end_date !== null && row.end_date !== row.start_date) || 
                                        // Show in case the end time is earlier than the start time, this probably means the use wants to set the timerow till tomorrow
                                        (row.end_time && row.start_time && row.end_time < row.start_time) ? (
                                            <DateInput
                                                id={`end_date_${identifier}`}
                                                name={`end_date_${identifier}`}
                                                value={row.end_date ? new Date(row.end_date) : undefined}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, 'end_date', identifier || '')}
                                                placeholder='appointment.general.date_placeholder' 
                                            />
                                        ) : (
                                            <div></div>
                                        )
                                    )}
                                    <div className='string-value'>
                                        {row.duration_string}
                                    </div>
                                    <MultiSelect<UserType> 
                                        options={assignees}
                                        id={`assignees_${identifier}`}
                                        name={`assignees_${identifier}`}
                                        disabled_selected={t('appointment.general.select_assignee_disabled_selected')}
                                        selectedOption={row.assignees || []}
                                        allowNoneOption={false}
                                        showSearch={true}
                                        selectionFormat={(option) => `${option.full_name}`}
                                        optionFormat={(option) => `${option.full_name}`}
                                        onChange={(selectedValue) => handleMultiSelectChange(selectedValue, 'assignees', identifier || '')}
                                        isInvalid={identifier && errorMessages?.appointments?.[identifier]?.['assignees'] ? true : false}
                                    />
                                    <Dropdown<{ value: string, name: string }>
                                        options={appointmentStatuses.filter(status => status.value !== 'to_be_assigned')}
                                        id={`status_${identifier}`}
                                        name={`status_${identifier}`}
                                        disabled_selected={t('appointment.general.select_status_disabled_selected')}
                                        selectedOption={appointmentStatuses.find(status => status.value === row.status)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'status', identifier || '')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        allowNoneOption={false} 
                                        showSearch={false}
                                        disabled={row.status === 'to_be_assigned'}
                                    />
                                    <div className='delete-icon tooltip-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => handleDeleteRow(identifier || '')} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {errorMessages && 
                        <div className='error-message'>
                            {renderRowsFieldErrorMessages(errorMessages, 'appointments', t, handleEndDateSuggestionClick)}
                        </div>
                    }
                    {(scheduleMultipleAppointments || rows.length === 0) && (
                        // Only allow to add new appointments based on the job appointment setting
                        <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                            className="add-new-button">
                                {t('appointment.general.add_appointment_label')}
                        </div>  
                    )}
                </div>
            ) : (
                // View mode
                rows.length > 0 && (
                    <div className={`full-width-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>
                        <div className="view-mode">
                            <span className='p'>
                                <div className='item-list'>
                                    {rows.filter(row => row.id).map((row, index) => {
                                        // Map and combine the assignees
                                        const assignees = row.assignees?.map(assignee => assignee.full_name).join(', ');

                                        // Format the start date field
                                        const startDate = row.start_date ? new Date(row.start_date).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

                                        // Format the end date if it exists and differs from the start date
                                        const endDate = (row.end_date && row.end_date !== row.start_date)
                                            ? new Date(row.end_date).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }) 
                                            : null;
                                        
                                        return (
                                            <div key={index} className='item'>
                                                <span>{startDate} - {row.start_time}-{row.end_time} ({row.duration_string})</span>
                                                {endDate && <span> - {endDate}</span>}
                                                {assignees && ` - ${assignees}`} 
                                                {row.status && <span> - {t(`appointment.status.${row.status}`)}</span>} 
                                            </div>
                                        )
                                    })}
                                    {isEditable && !userHasOnlyViewRights && (
                                        // Only show add item label when the object is editable
                                        (scheduleMultipleAppointments || rows.length === 0) && (
                                            // Only allow to add new appointments based on the job appointment setting
                                            <div className='add-item'>
                                                {t('appointment.general.add_appointment_label')}
                                            </div>
                                            // TODO: Add in a later stage: add appointment by scheduling job modal
                                            // <div className='add-item' 
                                            //      onClick={(event) => handleOpenModal(event)}>
                                            //     {t('appointment.general.add_appointment_label')}
                                            // </div>
                                        )
                                    )}
                                </div>
                            </span>
                            <span className='edit-icon'>
                                <FontAwesomeIcon icon={faPen} />
                            </span> 
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default AppointmentsRowsField;