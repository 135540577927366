import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';

// Rename the name of the function:
const ProductCategoryList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_productcategory_list',
        defaultParams: { 'is_active': true },
        object: 'productcategory'
    }
    const objectName = 'productcategory.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deactivated'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <></>;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'productcategory.general.name_label' },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'productcategory.general.active_categories_label',
            params: { 'is_active': true }
        },
        {
            id: 'inactive',
            label: 'productcategory.general.inactive_categories_label',
            params: { 'is_active': false }
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default ProductCategoryList;