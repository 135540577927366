import axios from 'axios';
import { apiBaseUrl } from 'App';

// Set a csrf token from the server in the browser
export const setCsrfToken = async (): Promise<void> => {
    try {
        await axios.get(`${apiBaseUrl}/set_csrf/`, { withCredentials: true });
    } catch (error: any) {
        console.error('Error while setting CSRF token:', error);
    }
}

// Retreive the csrf token from the cookie
export function getCsrfToken() {
    let name = 'csrftoken';
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}