import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from './ModalContext';
import { saveData } from 'services/api/saveData';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/modals.scss';

interface DeleteModalProps {
    deletionType?: string;
    patchToServer?: {
        apiObject: string;
        itemId: string;
    };
    onConfirmDelete?: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ 
    deletionType = 'general.delete', patchToServer, onConfirmDelete
}) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { revealModal, closeModal } = useModal();
    const [buttonLoader, setButtonLoader] = useState(false);

    // Reveal the modal on first render
    useEffect(() => {
        revealModal();
    }, []);

    // Handle the delete
    const handleDelete = async () => {
        // Optionally patch the delete flag to the server
        if (patchToServer) {

            // Deconstruct the patch props
            const { apiObject, itemId } = patchToServer;

            try {
                // Start button loader
                setButtonLoader(true);

                // Determine the field and value to update based on the deletion type
                let fieldValue = {};
                if (deletionType === 'general.delete') fieldValue = { deleted: true };
                if (deletionType === 'general.deactivate') fieldValue = { is_active: false };
            
                // Patch the delete state in the server
                await saveData({ 
                    apiUrl: `patch_${apiObject}/${itemId}`, 
                    method: 'patch', 
                    data: fieldValue
                });
            } catch (error) {
                // Show error if given
                console.error('Error during deleting.', error);
            } finally {
                // Stop button loader
                setButtonLoader(false);
            }
        };

        // Execute confirm delete callback
        if (onConfirmDelete) onConfirmDelete();

        // Close the modal
        closeModal();

        // Show floating alert
        setFloatingAlert({ type: 'success', message: 'alert.floating_alert_deleted' });
    };

    // Determine the delete button label based on the deletion type
    const determineDeleteButtonLabel = () => {
        if (deletionType === 'general.delete') return 'forms.delete_button_label';
        if (deletionType === 'general.deactivate') return 'forms.deactivate_button_label';
    };

    return (
        <div className='delete-modal'>
            <div className='warning-icon'>
                <FontAwesomeIcon 
                    icon={faTriangleExclamation} 
                />
            </div>
            <h3 className='header text-center'>
                {t(deletionType)}
            </h3>
            <p className='text-center'>
                {t('general.delete_confirm_message', { deletionLabel: t(deletionType).toLowerCase() })}
            </p>
            <div className='button-row'>
                <SecondaryButton
                    onClick={() => closeModal()}
                    label='general.cancel'
                    size="small"
                />
                <PrimaryButton
                    onClick={() => handleDelete()}
                    label={determineDeleteButtonLabel()}
                    customClass='confirm-delete'
                    size="small"
                    loading={buttonLoader}
                />
            </div>
        </div>
    )
}

export default DeleteModal;