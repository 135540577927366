import React, { useContext, useState } from 'react';
import Dropdown from 'components/forms/basefields/Dropdown';
import { useTranslation } from 'react-i18next';
import { FieldData, FieldType } from 'types/FieldTypes';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import FormFieldContext from 'components/forms/FormFieldContext';
import DropdownButton from 'components/buttons/DropdownButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { DropdownButtonItem } from 'types/ButtonTypes';
import '../../../style/scss/wizard.scss';

export interface WorkflowStatus {
    id?: number;
    key?: number;
    name: string;
    condition: string;
    description?: string;
    settings?: FieldType[];
    primaryActions?: string[];
    optionalActions?: string[];
    subSteps?: WorkflowSubStep[];
}

interface WorkflowSubStep {
    id?: number;
    key?: number;
    name: string;
    parentStep: number;
    primaryAction: string;
    subStepType: 'status' | 'task';
}

interface WorkflowStatusCondition {
    name: string;
    value: string;
}

export interface WorkflowType {
    connected_feature: 'deal' | 'proposal' | 'job' | 'invoice';
    name?: string;
    statuses: WorkflowStatus[];
    conditions: WorkflowStatusCondition[];
    availableActions?: string[];
    // triggers: FieldType[];
    // conditions: To be implemented
}

interface WorkflowWizardProps {
    workflowConfig: WorkflowType;
    viewKey?: string;
}

/* 
 * WorkflowWizard.tsx
 * Renders the fields to configure a workflow.
 */

const WorkflowWizard: React.FC<WorkflowWizardProps & { data: FieldData, viewKey: string }> = ({ workflowConfig, data, viewKey }) => {
    const { t } = useTranslation();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const currentStep = workflowConfig.statuses[currentStepIndex];
    const [stepActions, setStepActions] = useState([]);
    const { updatedData } = useContext(FormFieldContext);

    // Render the fields
    const renderFields = (fields: FieldType[]) => {
        if (viewKey) {
            // Set the edit mode for all fields
            const editFields = fields.map(field => ({ ...field, viewInEditMode: true }));

            // Generate the fields
            return generateFieldComponents(viewKey, editFields, undefined, undefined, data, updatedData)
        }
    }

    console.log("updatedData", updatedData);

    return (
        <div className='workflow-wizard'>
            {currentStep.description && (
                <div className='workflow-section'>
                    <h6>{currentStep.name}</h6>
                    <p>{currentStep.description}</p>
                </div>
            )}
            <div className='workflow-section'>
                <h3>Trigger</h3>
                <p>Dit is de voorwaarde die bepaalt hoe je bij deze stap uit komt.</p>
                {renderFields([{
                    type: 'dropdown',
                    name: `condition_${currentStep.key}`,
                    allowNoneOption: false,
                    showSearch: false,
                    defaultValue: { value: currentStep.condition },
                    disabled: true,
                    hardcodedOptions: workflowConfig.conditions 
                }])}
            </div>
            {currentStep.settings && (
                <div className='workflow-section'>
                    <h3>Instellingen</h3>
                    {renderFields(currentStep.settings)}
                </div>
            )}
            <div className='workflow-section'>
                <h3>Vervolgactie</h3>
                <p>Bepaal hier de primaire actie(s) die moeten worden uitgevoerd vanuit de huidige stap.</p>
                {/* TODO: Als een actie is gekozen, dan een kruisje tonen om hem te deselecteren en een checkbox om hem optioneel te maken */}
                {/* {config.primaryActions && (
                    <DropdownButton
                        items={config.primaryActions}
                        label='+ Kies Actie'
                        size="medium" /> 
                )} */}
            </div>
            <div className='workflow-section'>
                <h3>Sub stappen</h3>
                <p>Voeg additionele stappen toe die binnen de huidige workflow stap vallen.</p>
                <SecondaryButton
                    label='+ Add sub step'
                    size='small'
                    />
            </div>
        </div>
    )
}

export default WorkflowWizard;