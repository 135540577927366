import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const HourlyRateForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'hourlyrate'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'price-currency', 
            name: 'rate', 
            label: 'hourly_rate.general.rate_label', 
            placeholder: 'hourly_rate.general.rate_placeholder', 
            apiObject: 'currency',
        }, 
        { 
            type: 'text', 
            name: 'description', 
            label: 'hourly_rate.general.description_label', 
            placeholder: 'hourly_rate.general.description_placeholder', 
        }, 
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default HourlyRateForm;