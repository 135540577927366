import { useEffect, useState } from 'react';

/*
 * useJobInboxHeight.ts
 * A custom hook to calculate the job inbox height based on the
 * height of the scheduler.
 */

const useJobInboxHeight = (schedulingBoardRef: React.RefObject<HTMLDivElement>) => {
    const [jobInboxHeight, setJobInboxHeight] = useState<number>(0);

    useEffect(() => {
        const calculateJobInboxHeight = () => {
            // Get the height of the window of the user
            const windowHeight = window.innerHeight;
        
            // Get the navbar height
            const navBar = document.querySelector('.navbar') as HTMLElement;
            const subNavBar = document.querySelector('.subnavbar') as HTMLElement;
            const navBarHeight = navBar ? navBar.offsetHeight : 0;
            const subNavBarHeight = subNavBar ? subNavBar.offsetHeight : 0;
        
            // Get the height of the page header and scheduling header
            const mainElement = document.querySelector('main') as HTMLElement;
            const mainElementMarginTop = document.querySelector('main') ? parseInt(window.getComputedStyle(mainElement).marginTop) : 0;
            const pageHeader = document.querySelector('.scheduling-header') as HTMLElement;
            const pageHeaderMarginBottom = pageHeader ? parseInt(window.getComputedStyle(pageHeader).marginBottom) : 0;
            const boardHeader = document.querySelector('.scheduling-board .header') as HTMLElement;
            
            const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight + pageHeaderMarginBottom : 0;
            const boardHeaderHeight = boardHeader ? boardHeader.offsetHeight : 0;
                    
            // Calculate the height available for the job inbox
            const totalJobInboxHeight = windowHeight - (navBarHeight + subNavBarHeight + pageHeaderHeight + boardHeaderHeight + mainElementMarginTop);
            setJobInboxHeight(totalJobInboxHeight);
        };
      
        // Create the mutation observer instance
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    calculateJobInboxHeight();
                }
            });
        });

        // Start the observer
        if (schedulingBoardRef.current) {
            observer.observe(schedulingBoardRef.current, { childList: true, subtree: true, attributes: true });
        }
      
        // Execute the height calculation
        calculateJobInboxHeight();
      
        // Clear on unmount
        return () => observer.disconnect();
    }, [schedulingBoardRef]);

    return jobInboxHeight;
};

export default useJobInboxHeight;