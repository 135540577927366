import React from 'react';

// Generic function which renders the translated error messages string for rowsfields
export const renderRowsFieldErrorMessages = (
    errorMessages: { [key: string]: { [key: string]: Record<string, string> } },
    rowsFieldKey: string,
    t: (key: string) => string,
    onClickHandler?: () => void,
) => {
    // Don't render anything if there are no error messages for this rowsfield
    const groupedErrors = errorMessages[rowsFieldKey];
    if (!groupedErrors) return null;

    // Create a set of unique error messages
    const uniqueErrorMessages: JSX.Element[] = [];

    // Loop through the error messages and add the unique messages to the set
    Object.keys(groupedErrors).forEach((identifier) => {
        const rowErrors = groupedErrors[identifier];
        Object.values(rowErrors).forEach((error) => {

            if (typeof error === 'string') {
                // Check if the error is already in the array
                const errorExists = uniqueErrorMessages.some(
                    (message) => message.key === error
                );

                if (!errorExists) {
                    // For timerows errors, add button to add end date in specific error
                    if (error === "validation.timerow.end_time_before_start_time" && onClickHandler) {
                        uniqueErrorMessages.push(
                            <div key={error}>
                                {t(error)}{" "}
                                <span
                                    onClick={onClickHandler}
                                    style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("validation.timerow.fill_end_date_message")}
                                </span>
                            </div>
                        );
                    // For appointments errors, add button to add end date in specific error
                    } else if (error === "validation.appointment.end_time_before_start_time" && onClickHandler) {
                        uniqueErrorMessages.push(
                            <div key={error}>
                                {t(error)}{" "}
                                <span
                                    onClick={onClickHandler}
                                    style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("validation.appointment.fill_end_date_message")}
                                </span>
                            </div>
                        );
                    } else {
                        uniqueErrorMessages.push(<div key={error}>{t(error)}</div>);
                    }
                }
            }
        });
    });

    // Render the error messages
    return uniqueErrorMessages.map((message, index) => (
        <div key={index}>{message}</div>
    ));
};