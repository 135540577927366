import React, { useContext, useState, useEffect } from 'react';
import { TextareaFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { convertTabsToSpaces } from 'services/utils/convertTextareaData';
import FieldViewMode from './elements/FieldViewMode';
import TextareaInput from '../basefields/TextareaInput';
import FieldWrapper from '../FieldWrapper';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

interface TextareaFieldProps extends TextareaFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const TextareaField: React.FC<TextareaFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, placeholder, onChange, viewInEditMode, shouldAutoFocus, 
    rows = 2, isNote, enableStyling, alignment, disabled, emptyLabel, showToolbarInitially, isEditable
}) => {
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState('');

    // Sets the current value from the data
    useEffect(() => {
        if (data && data[name] !== undefined && data[name] !== value) {
            setValue(convertTabsToSpaces(data[name]))
        }
    }, [data, name]);

    // Handle text area change
    const handleTextAreaChange = (value: string | React.ChangeEvent<HTMLTextAreaElement>) => {

        // Determine the type of text area
        let textareaType = enableStyling ? 'styled' : 'normal';

        // Handle changes from styled text area
        if (textareaType === 'styled' && typeof value === 'string') {
            setValue(value);
            setUnsavedChanges(viewKey, value !== data[name]);
            setUpdatedData({...updatedData, [name]: value})
        }

        // Handle changes from normal text area
        if (textareaType === 'normal' && typeof value != 'string') {
            setValue(value.target.value);

            // Call onChange for normal textarea fields
            if (onChange) {
                onChange(value);
            }

            setUnsavedChanges(viewKey, value.target.value !== data[name]);
            setUpdatedData({...updatedData, [name]: value.target.value});
        }
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <TextareaInput
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    rows={rows}
                    isNote={isNote}
                    disabled={disabled}
                    enableStyling={enableStyling}
                    showToolbarInitially={showToolbarInitially}
                    viewInEditMode={viewInEditMode}
                    autoFocus={shouldAutoFocus}
                    onChange={(value) => handleTextAreaChange(value)}
                />
            ) : (
                // View mode
                <FieldViewMode
                    value={value} 
                    type='textarea'
                    alignment={alignment}
                    emptyLabel={emptyLabel}
                />
            )}
        </FieldWrapper>
    );
};

export default TextareaField;