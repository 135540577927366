import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FormFieldContext from '../FormFieldContext';
import { FieldData, SwitchFieldType } from 'types/FieldTypes';
import FieldWrapper from '../FieldWrapper';
import SwitchButton from 'components/buttons/SwitchButton';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const SwitchField: React.FC<SwitchFieldType & { data: FieldData, viewKey: string }> = ({ 
    name, type, label, data, defaultValue, helperText, tooltipText, viewInEditMode, 
    saveOnBlur, showIfFilled, alignment, isEditable, disabled = false, emptyLabel, viewKey
}) => {
    const { t } = useTranslation();
    const { setUnsavedChanges } = useGlobalContext();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const [isOn, setIsOn] = useState<boolean>(false);

    // Set the value with the received data or default value
    useEffect(() => {
        if (data && data[name] !== undefined) {
            setIsOn(data[name]);
        }
        else if (defaultValue !== undefined) {
            setIsOn(!!defaultValue);
        }
    }, [data, name, defaultValue, setUpdatedData])

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!isOn && isOn === false)) {
        return null;
    }

    // Updates the value after changing the toggle
    const handleToggle = (newIsOn: boolean) => {
        setIsOn(newIsOn);

        const hasChanges = newIsOn !== !!data[name];
        setUnsavedChanges(viewKey, hasChanges);
        setUpdatedData({...updatedData, [name]: newIsOn});

        if (saveOnBlur) {
            if (handleSubmit) handleSubmit();
        };        
    };

    return (
        <FieldWrapper
            name={name}
            type={type}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <SwitchButton
                    name={name}
                    data={data}
                    isDisabled={disabled}
                    onToggle={handleToggle}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={isOn ? t('forms.enabled') : t('forms.disabled')} 
                    alignment={alignment}
                    emptyLabel={emptyLabel}
                />
            )}
        </FieldWrapper>
    )
};

export default SwitchField;