import { UserType } from "../../views/users/UserTypes";

export interface JobItemType {
    id: number | null;
    uuid?: string;
    job: number;
    appointment: number | null;
    product: number | null;
    name: string;
    number: string | null;
    unit: string | null;
    sale_price: string | null;
    sale_price_currency: string | null;
    total_price: number | null;
    vat_rate: number | null;
    ledger_account: number | null;
    is_inventory: boolean;
    quantity_reserved: number | null;
    quantity_registrated_string: string | null;
    quantity_registrated: number | null;
    date_registrated: string | null;
    position: number | null;
    deleted: boolean;
};

export const createNewJobItem = (jobId: number, uuid: string, position: number): JobItemType => ({
    id: null,
    uuid: uuid,
    job: jobId,
    appointment: null,
    product: null,
    name: '',
    number: null,
    unit: null,
    sale_price: null,
    sale_price_currency: 'EUR',
    total_price: null,
    vat_rate: null,
    ledger_account: null,
    is_inventory: false,
    quantity_reserved: null,
    quantity_registrated_string: null,
    quantity_registrated: null,
    date_registrated: null,
    position: position,
    deleted: false,
});

export interface TimeBlockType {
    id: number;
    name: string | null;
    start_time: string | null;
    end_time: string | null;
    division: number | null;
};

export interface AppointmentType {
    id: number | null;
    uuid?: string;
    start_datetime: string | null;
    end_datetime: string | null;
    start_date: string | null;
    end_date: string | null;
    start_time: string | null;
    end_time: string | null;
    time_block: number | null;
    whole_day: boolean;
    duration: number | null;
    duration_string: string | null;
    status: string;
    job: number | null;
    job_internal_reference: string | null;
    job_work_location_city: string | null;
    job_client_name: string | null;
    job_main_description: string | null;
    job_workflow: string | null;
    job_status: string | null;
    deleted: boolean;
    division: number | null;
    assignees: UserType[] | null;
    assignee_ids: number[];
};

export const createNewAppointment = (jobId: number, uuid: string): AppointmentType => ({
    id: null,
    uuid: uuid,
    start_datetime: null,
    end_datetime: null,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    whole_day: false,
    time_block: null,
    duration: null,
    duration_string: null,
    status: 'scheduled',
    job: jobId,
    job_internal_reference: null,
    job_work_location_city: null,
    job_client_name: null,
    job_main_description: null,
    job_workflow: null,
    job_status: null,
    deleted: false,
    division: null,
    assignees: [],
    assignee_ids: [],
});

export interface JobType {
    id: number | null;
    uuid?: string;
    created: string | null;
    modified: string | null;
    number: string | null;
    subject: string | null
    internal_reference: string | null;
    external_reference: string | null;
    client_name: string | null;
    labels: number[];
    services: number[];
    main_description: string | null;
    internal_description: string | null;
    project: number | null;
    organization: number | null;
    person: number | null;
    location: number | null;
    target_date: string | null;
    estimated_duration: string | null;
    billing_type: string;
    status: number | null;
    status_name: string | null;
    status_condition: string | null;
    ordering: number | null;
    deleted: boolean;
    division: number | null;
};

export const createNewJob = (uuid: string, parentJobId?: number, ordering?: number, billingType?: string): JobType => ({
    id: null,
    uuid: uuid,
    created: null,
    modified: null,
    number: null,
    subject: null,
    internal_reference: null,
    external_reference: null,
    client_name: null,
    labels: [],
    services: [],
    main_description: null,
    internal_description: null,
    project: null,
    organization: null,
    person: null,
    location: null,
    target_date: null,
    estimated_duration: null,
    billing_type: billingType ?? 'calculation',
    status: null,
    status_name: null,
    status_condition: 'job_created',
    ordering: ordering ?? null,
    deleted: false,
    division: null
});