import React, { useState, useRef, useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { TimelineItemType, TimelineTaskTypeType, TimelineUserType } from 'types/TimelineTypes';
import TimelineTaskForm from './TimelineTaskForm';
import axios from 'axios';
import { deleteData } from 'services/api/deleteData';
import { saveData } from 'services/api/saveData';
import { formatTimelineDate, determineTaskDateClass } from 'services/utils/dateTimeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faPen, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { iconMapping } from 'services/utils/iconMapping';
import TaskCheckmark from 'assets/TaskCheckmark.svg';
import { useTranslation } from 'react-i18next';
import '../../style/scss/tooltip.scss';

interface TimelineTaskProps {
    viewKey: string;
    item: TimelineItemType;
    timeline_page_id: string;
    timeline_page?: string;
    assignee?: TimelineUserType | null;
    tasktype?: TimelineTaskTypeType | null;
    createdBy?: TimelineUserType | null;
    modifiedBy?: TimelineUserType | null;
}

const TimelineTask: React.FC<TimelineTaskProps> = ({ viewKey, item, timeline_page_id, timeline_page, assignee, tasktype, createdBy, modifiedBy }) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const [isEditing, setIsEditing] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    const helperTextRef = useRef<HTMLDivElement>(null);
    
    // Format the date and determine the overdue/today date class
    const taskDate = formatTimelineDate(item.ordering_date, item.task?.whole_day || false);
    const dateClass = determineTaskDateClass(item.ordering_date, isChecked);

    // Format the created and modified date to show as additional information on the task edit form
    let createdDate
    if (item.task && item.task.created) {
        createdDate = formatTimelineDate(item.task.created, false);
    }
    let modifiedDate
    if (item.task && item.task.modified) {
        modifiedDate = formatTimelineDate(item.task.modified, false)
    }

    // Expand the note of the task
    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    // Shows the text expand icon if the helpertext element is higher than the visible text element
    useEffect(() => {
        if (textRef.current && helperTextRef.current) {
            const visibleTextHeight = textRef.current.offsetHeight;
            const helperTextHeight = helperTextRef.current.offsetHeight;
            setIsExpandable(helperTextHeight > visibleTextHeight);
        }
    }, [item]);

    // Determine the task icon
    const taskIcon = tasktype?.icon ? iconMapping(tasktype.icon) : null;

    // Set the check status on initialisation
    useEffect(() => {
        if (item.task) {
            setIsChecked(item.task.checked);
        }
    }, [item.task])

    // Update the check state in the database
    const handleCheck = async () => {
        if (item.task?.id) {
            type checkedTaskDataType = {
                checked: boolean;
                [key: string]: boolean | string;
            };

            let checkedTaskData: checkedTaskDataType = { checked: !isChecked };

            if (timeline_page) {
                checkedTaskData[timeline_page] = timeline_page_id;
            }

            try {
                await saveData({ 
                    apiObject: 'task', 
                    method: 'patch',
                    itemId: item.task?.id, 
                    data: checkedTaskData, 
                    source: axios.CancelToken.source() 
                })
                setIsChecked(prevIsChecked => !prevIsChecked);
                setFloatingAlert({ type: 'success' })
            } catch (error) {
                console.error('Failed to update check state', error)
            }
        } else {
            console.error('No timeline object id found for this task');
        }
    }

    // Delete the task in the database
    const deleteTask = async () => {
        if (item.task?.id) {
            try {
                await deleteData({ apiObject: 'task', itemId: item.task?.id, source: axios.CancelToken.source() })
                setFloatingAlert({ type: 'success' })
            } catch (error) {
                setFloatingAlert({ type: 'danger', message: 'timeline.task.alert.delete_task_failed_message' })
            }
        } else {
            console.error('No timeline object id found for this task');
        }
    }

    return (
        <div className='timeline-item task'>
            <div className='marker-container'>
                <div className="big-marker">
                    <FontAwesomeIcon icon={taskIcon || faCheck} className='note-icon fa-fw' />
                </div>
            </div>
            <div className={`task-content ${isEditing ? 'editing' : ''}`}>
                {isEditing ? (
                    <TimelineTaskForm
                        viewKey={viewKey}
                        taskId={item.timeline_object_id}
                        timeline_page={timeline_page}
                        timeline_page_id={item.timeline_page_id.toString()}
                        createdBy={createdBy}
                        createdDate={createdDate}
                        modifiedBy={modifiedBy}
                        modifiedDate={modifiedDate}
                        isTaskFormOpen={isEditing} 
                        setIsTaskFormOpen={setIsEditing}
                        resetActiveButtonItem={() => {}}
                    />
                ) : (item.task && 
                    <>
                    <div className='task-header'>
                        <div ref={textRef} 
                           className='task-title-wrapper'>
                            <div className='check-button-wrapper'>
                                <button className={`check-button ${isChecked ? 'checked' : ''} tooltip-icon`}
                                        onClick={() => {handleCheck()}}>
                                    {isChecked ? <img src={TaskCheckmark} alt="Checked" /> : null}
                                    <span className="tooltip">{!isChecked ? t('timeline.task.general.mark_done_label') : t('timeline.task.general.mark_to_do_label')}</span>
                                </button>
                            </div>
                            <div className='task-title'
                                 onClick={() => setIsEditing(true)}>
                                {item.task.subject}
                            </div>
                        </div>
                        <div className='task-info'>
                            <div className='left-items'>
                                <span className={`${dateClass}`}>
                                    {taskDate}
                                </span>
                                {assignee && assignee.full_name && (
                                    <>
                                        <span style={{ margin: '0 5px' }}>•</span>
                                        <span>{assignee.full_name}</span>
                                    </>
                                )}
                            </div>
                            <div className='right-items'>
                                <div className='tooltip-icon edit-icon'
                                    onClick={() => setIsEditing(true)}>
                                        <FontAwesomeIcon icon={faPen} />
                                        <span className="tooltip">{t('general.edit_label')}</span>
                                </div>
                                <div className='tooltip-icon delete-icon'
                                    onClick={deleteTask}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                </div>  
                            </div>
                        </div>
                    </div>
                        {item.task.note && (
                            <div className='task-note'>
                                <div className='left-items'>
                                    <p className='note-text'
                                       ref={textRef} 
                                       dangerouslySetInnerHTML={{ __html: item.task.note }}
                                       style={!isExpanded ? { overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' } : {}}>
                                    </p>
                                    {/* Help element to determine if the text expand icon needs to show */}
                                    <p className='note-text' 
                                       ref={helperTextRef} 
                                       style={{ position: 'absolute', visibility: 'hidden', height: 'auto', width: '100%', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                                       dangerouslySetInnerHTML={{ __html: item.task.note }}>
                                    </p>
                                </div>
                                <div className='right-items'>
                                    {isExpandable && !isEditing &&
                                        <div className='tooltip-icon expand-icon' onClick={toggleIsExpanded}>
                                            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                                            <span className="tooltip">{isExpanded ? t('general.shrink_label') : t('general.expand_label')}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default TimelineTask;