import React from 'react';
import { useSettings } from 'services/settings/SettingsContext';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import HourlyRateForm from './HourlyRateForm';
import { currencySymbols } from 'internationalization/currencySymbols';

// Rename the name of the function:
const HourlyRateList: React.FC = () => {
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const objectName = 'hourly_rate.general.object_name'
    const query = {
        endpoint: 'get_hourlyrate_list',
        defaultParams: { 'is_active': true },
        object: 'hourlyrate'
    }
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deactivated'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <HourlyRateForm />;
    const onRowClick = 'editModal' // Either plug in 'editModal' or 'detailPage'

    // Custom jsx to render the rate currency, rate and optional description in the same column
    const renderRateColumn = (rate: number, rateCurrency: string, description: string) => {
        // Get the currency symbol for the currency of the rate
        const currencySymbol = currencySymbols[rateCurrency.toLowerCase()] || rateCurrency;

        // Format the rate based on the user locale
        const formattedRate = rate.toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        // Configure the rate column string as div
        return (
            <div>
                {currencySymbol} {formattedRate}{description ? ` - ${description}` : ''}
            </div>
        )
    }

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { 
            width: '1fr', 
            field: 'rate', 
            label: 'hourly_rate.general.rate_label', 
            render: (_, row) => renderRateColumn(row.rate, row.rate_currency, row.description)
        },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'hourly_rate.general.active_hourly_rates_label',
            params: { 'is_active': true },
            default: true,
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'inactive',
                    label: 'hourly_rate.general.inactive_hourly_rates_label',
                    shortName: 'general.deactivated',
                    params: { 'is_active': false },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default HourlyRateList;