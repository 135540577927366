import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';

/*
 * useFetchSearchHook.tsx
 * A custom hook to fetch the items for search fields. It only fetches when 2 characters
 * are given in the search value and the value is different from the last fetched value.
 * It starts a timer to show the search loading icon. The resetLastFetchValue function
 * can be used to make possible to search again on the same string when unlinking an 
 * item in the search field. For example when searched for 'schroef' and selected 
 * 'schroef 4x40', to make it possible after unlinking to search again on 'schroef'.
 */ 

interface FieldOptionFetchResponse {
    results: any[];
}

export function useFetchSearchHook(
    apiUrl: string, params: object, search: string
) {
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [fetchFinished, setFetchFinished] = useState(true);
    const [lastFetchValue, setLastFetchValue] = useState('');
    const source = useRef<axios.CancelTokenSource | null>(null);

    // Fetch the data after entering the search value
    useEffect(() => {
        setFetchFinished(false);
        
        // Start this function after entering two characters and only when the value is different from the last fetch
        if (search.length >= 2 && search !== lastFetchValue) {
            let loadingTimer: NodeJS.Timeout;
            let fetchTimer: NodeJS.Timeout;

            // Show loader icon after 500ms
            loadingTimer = setTimeout(() => setLoading(true), 500);

            // To be sure the entering is completed, execute the fetch after 1250ms
            fetchTimer = setTimeout(() => {

                // If another request is still running, cancel it
                if (source.current) {
                    source.current.cancel('New search initiated');
                }

                // Create a new cancel token for the current request
                source.current = axios.CancelToken.source();

                // Get the csrf token from the cookie
                const csrfToken = getCsrfToken();

                // Fetch the data
                axios.get(`${apiBaseUrl}/${apiUrl}/`, { 
                    params: { ...params, search }, 
                    cancelToken: source.current.token,
                    withCredentials: true,
                    headers: { 'X-CSRFToken': csrfToken }
                    })
                    .then((response: { data: FieldOptionFetchResponse }) => {
                        setOptions(response.data.results);
                        setLoading(false);
                        setFetchFinished(true);
                        setLastFetchValue(search);
                    })
                    .catch((error) => {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                        }
                        setLoading(false);
                        setFetchFinished(true);
                    });
            }, 1250);

            // Cleanup timers
            return () => {
                clearTimeout(loadingTimer);
                clearTimeout(fetchTimer);
                source.current?.cancel("Component unmounted or search changed");
            };
        }
    }, [search, apiUrl, params]);

    // Reset the last fetch value, to use for example when unlinking an existing item
    const resetLastFetchValue = () => {
        setLastFetchValue('');
    }
    
    return { options, loading, fetchFinished, resetLastFetchValue };
}