/*
 * queryParamsUtils.tsx
 * This contains the query params utility functions of lists. 
 */

import { QueryParamType } from "types/ListTypes";
import { Location, History } from 'history';

// Function to initialize query params on first render with the default params of the list
// In the configuration file of lists default params can be given to initialize the first
// fetch on rendering of the component with.
export function useInitializeParams(defaultParams: QueryParamType, isPaginated: boolean, location: Location) {
    return () => {
        const urlParams = new URLSearchParams(location.search);

        // Add the default params of the list config and 'page' if the list is paginated
        const initialParams: QueryParamType = { ...defaultParams, page: isPaginated ? '1' : undefined };

        // Loops through the url params object
        urlParams.forEach((value, key) => {
            initialParams[key] = value;
        });

        return initialParams;
    }
}

// Function to set the browser url with the current and default params
export function setBrowserUrl(params: QueryParamType) {
    const searchParams = new URLSearchParams(window.location.search);

    // Loop through the params object
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (params[key] === undefined) {
            // If the value is undefined, delete the parameter
            searchParams.delete(key);
        } else {
            // If a value is given, add or update the parameter
            searchParams.set(key, String(value));
        }
    });

    // Push the params to the window history
    window.history.pushState({}, '', '?' + searchParams.toString());
}

// Function to update the browser url and execute refetch when query params change
export function executeQueryParamsUpdate(newParams: QueryParamType, queryParams: QueryParamType, setQueryParams: (params: QueryParamType) => void, history: History, location: Location) {

    // Check if the new params are different from the current params 
    if (JSON.stringify(newParams) !== JSON.stringify(queryParams)) {
        const searchParams = new URLSearchParams();

        // Loop through the new params object
        Object.entries(newParams).forEach(([key, value]) => {
            if (value === '' || value == null) {
                // If the value is empty or null, delete the parameter
                searchParams.delete(key);
            } else {
                // If a value is given, add or update the parameter
                searchParams.set(key, String(value));
            }
        });

        // Update the browser url
        history.push({ pathname: location.pathname, search: searchParams.toString() });

        // Update the query state, this executes a refetch
        setQueryParams(newParams);
    }
}