import React, { useContext, useState, useEffect } from 'react';
import { FieldData, TotalPriceFieldType } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import FieldViewMode from './elements/FieldViewMode';
import FieldWrapper from '../FieldWrapper';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const TotalPriceField: React.FC<TotalPriceFieldType & { data: FieldData, viewKey: string }> = ({ 
    name, label, tooltipText, data, value, currency, alignment, helperText, disabled, viewInEditMode,
    isEditable
}) => {
    const { editing } = useContext(FormFieldContext);
    const [totalPrice, setTotalPrice] = useState<number>(0.00);
    const [currencyValue, setCurrencyValue] = useState('');

    // Set the total price with the value or received data
    useEffect(() => {
        if (value !== undefined) {
            setTotalPrice(value);
        } else if (data && data[name] !== undefined) {
            setTotalPrice(data[name]);
        }
    }, [data, name, value]);

    useEffect(() => {
        if (currency !== undefined) {
            setCurrencyValue(currency);
        }
    }, [currency]);

    // Format the price value
    const formattedTotalPrice = totalPrice.toLocaleString('nl-NL', { style: 'currency', currencyDisplay: 'symbol', currency: currencyValue || 'EUR' });

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                // Not implemented yet
                <></>
            ) : (
                // View mode
                <FieldViewMode 
                    value={formattedTotalPrice} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default TotalPriceField;