import React from 'react';
import { useTranslation } from 'react-i18next';

// Render the release type as badge
const ReleaseTypeBadge: React.FC<{ releaseType: string }> = ({ releaseType }) => {
    const { t } = useTranslation();
        
    // Determine the badge color based on the release type
    let color;
    if (releaseType === 'feature_release') color = 'background-green';
    if (releaseType === 'fix_release') color = 'background-yellow';

    return (
        <div className='badge-container'>
            <div className={`label-badge ${color}`}>
                {t(`release.release_type.${releaseType}`)}
            </div>
        </div>
    );
};

export default ReleaseTypeBadge;