import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PeopleCardProps, PeopleCardPersonType } from 'types/DetailPageTypes'; 
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { fetchData } from 'services/api/fetchData';
import IconButton from 'components/buttons/IconButton';
import SearchSelect from 'components/forms/basefields/SearchSelect';
import '../../style/scss/card.scss';

const PeopleCard: React.FC<PeopleCardProps> = ({ 
    viewKey, apiObject, itemId, loadStatus, personIds, isEditable
}) => {
    const { t } = useTranslation();
    const { handleLogout } = useAuthContext();
    const [people, setPeople] = useState<PeopleCardPersonType[]>([]);
    const [peopleLoadStatus, setPeopleLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [isEditing, setIsEditing] = useState(false);

    // Fetch the people
    useEffect(() => {
        if (personIds && personIds.length) {
            // Show loader icon after 250ms
            let timer: NodeJS.Timeout | null = setTimeout(() => {
                setPeopleLoadStatus("loading");
            }, 250);

            const fetchPeople = async () => {
                try {
                    const data = await fetchData({ apiUrl: `get_person_search/${itemId}`, handleLogout });
                    setPeople(data.results);
                } catch (error) {
                    console.error("Failed to load people data:", error);
                } finally {
                    if (timer) {clearTimeout(timer)};
                    setPeopleLoadStatus("loaded")
                }
            };
            fetchPeople();
        }
    }, [personIds]);

    return (
        <div className='card peoplecard'>
            <div className='header'>
                <h6>
                    {t('person.general.people_label')}
                    {/* Toon het aantal items alleen als er personen zijn */}
                    {people && people.length > 0 && <span> ({people.length})</span>}    
                </h6>
                {isEditable && (
                    <IconButton
                        onClick={() => setIsEditing(true)}
                        size='extra-small' 
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        tooltipText='person.general.add_person_label'
                    /> 
                )}
            </div>
            {isEditing && (
                <SearchSelect 
                    type='searchselect' 
                    name='person'
                    label='person.link_person_label'
                    data={personIds}
                    placeholder='person.general.search_person_label'
                    viewInEditMode={true}
                    objectName='person.general.object_name.singular'
                    query={{endpoint: 'get_person_search'}}
                    postEndpoint=''
                    selectionFormat='name'
                    optionFormat={{
                        title: { field: 'name', format: (value) => value },
                        subtitle: { field: 'primary_locations[0].address', format: (value) => value ? `${value}` : '', optional: true },
                    }}
                    onSelect={selectedPerson => {
                        // Voeg hier logica toe om de geselecteerde persoon te linken aan de organisatie
                    }}
                />
            )}
            {loadStatus === "loading" || peopleLoadStatus === "loading" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && peopleLoadStatus === "loaded" && (
                people.map((person) => (
                    <Link className='card-link' 
                          to={`/contacts/person/${person.id}`}
                          key={person.id}>
                        {person.name}
                        {person.role && <span> ({person.role})</span>}
                    </Link>
                ))
            )}
        </div>
    );
}

export default PeopleCard;