import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { generateWeekNumber } from "services/utils/dateTimeUtils";

interface DatePickerProps {
    selectedDate: Date | Date[] | null;
    onDayClick: (date: Date) => void;
    closeDatePicker: () => void;
    weekStartsOnSunday?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ 
    selectedDate, onDayClick, closeDatePicker, weekStartsOnSunday = false
}) => {
    const { t } = useTranslation();
    const [currentMonth, setCurrentMonth] = useState(() => new Date());
    const datePickerRef = useRef<HTMLDivElement>(null)

    // Set the current month based on selected date
    useEffect(() => {
        if (selectedDate) {
            const dateToUse = Array.isArray(selectedDate) ? selectedDate[0] : selectedDate;
            setCurrentMonth(new Date(dateToUse.getFullYear(), dateToUse.getMonth(), 1));
        } else {
            setCurrentMonth(new Date());
        }
    }, [selectedDate]);

    // Click outside the datepicker to close it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                closeDatePicker();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeDatePicker, datePickerRef]);

    // Render the day names to show as a header in the date picker
    const renderDayNames = () => {
        const dayNames = weekStartsOnSunday
            ? ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] 
            : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        
        return (
            <div className='header-row'>
                <div className="day-name week-number-header">{t('date_time.general.week_short').toLowerCase()}</div>
                {dayNames.map((dayName, index) => (
                    <div key={index} className='day-name'>{t(`date_time.days.short.${dayName}`).toLowerCase()}</div>
                ))}
            </div>
        );
    };

    // Render the days to show in the date picker
    const renderDays = () => {
        const daysElements = [];
        const today = new Date();
    
        // Determine the day on which the week starts: 0 for sunday, 1 for monday
        const startDay = weekStartsOnSunday ? 0 : 1;
    
        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const lastDayOfLastMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0).getDate();
        let dayOfWeek = firstDayOfMonth.getDay();
        dayOfWeek = dayOfWeek < startDay ? 7 - startDay : dayOfWeek - startDay;
    
        const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    
        // Calculate days of the last month to show
        const daysFromPrevMonth = dayOfWeek;
    
        // Add days of the last month to fill the first week
        for (let i = daysFromPrevMonth; i > 0; i--) {
            const day = lastDayOfLastMonth - i + 1;
            daysElements.push(<div key={`prev-${day}`} className="day other-month">{day}</div>);
        }
    
        // Add the days of the current month
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
            const isSelected = isSelectedDate(date)
            const isToday = today.getDate() === day && today.getMonth() === currentMonth.getMonth() && today.getFullYear() === currentMonth.getFullYear();

            daysElements.push(
                <div key={day} 
                     className={`day ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''}`} 
                     onClick={() => onDayClick(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day))}>
                    {day}
                </div>
            );
        }
    
        // Add days of the next month to fill the last week
        let extraDaysNeeded = 7 - ((daysFromPrevMonth + daysInMonth) % 7);
        if (extraDaysNeeded && extraDaysNeeded < 7) {
            for (let i = 1; i <= extraDaysNeeded; i++) {
                daysElements.push(<div key={`next-${i}`} className="day other-month">{i}</div>);
            }
        }
    
        // Add weeknumbers
        const weeks = [];
        for (let i = 0; i < daysElements.length; i += 7) {
            const weekStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1 - daysFromPrevMonth + i);
            const weekNumber = generateWeekNumber(weekStartDate, weekStartsOnSunday);
    
            weeks.push(
                <div key={`week-${i}`} className="week-row">
                    <div className="week-number">{weekNumber}</div>
                    {daysElements.slice(i, i + 7)}
                </div>
            );
        }
    
        return weeks;
    };

    // Set the selected date(s) to show active in the date picker
    const isSelectedDate = (dateToCheck: Date) => {
        if (Array.isArray(selectedDate)) {
            return selectedDate.some(date => 
                date instanceof Date && 
                date.getDate() === dateToCheck.getDate() &&
                date.getMonth() === dateToCheck.getMonth() &&
                date.getFullYear() === dateToCheck.getFullYear()
            );
        } else if (selectedDate) {
            return selectedDate.getDate() === dateToCheck.getDate() &&
                selectedDate.getMonth() === dateToCheck.getMonth() &&
                selectedDate.getFullYear() === dateToCheck.getFullYear();
        }
        return false;
    };

    // Shows the previous month in the date picker
    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };

    // Shows the next month in the date picker
    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };

    return (
        <div className="datepicker"
             ref={datePickerRef}>
            <div className="month-selector">
                <FontAwesomeIcon 
                    icon={faAngleLeft} 
                    onClick={handlePrevMonth}
                    className='month-angle' />
                <span>{t(`date_time.months.${currentMonth.getMonth()}`)} {currentMonth.getFullYear()}</span>
                <FontAwesomeIcon 
                    icon={faAngleRight} 
                    onClick={handleNextMonth}
                    className='month-angle' />
            </div>
            {renderDayNames()}
            <div className="days-container">
                {renderDays()}
            </div>
        </div>
    );
};

export default DatePicker;