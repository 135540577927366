import { fetchData } from "services/api/fetchData";

/*
 * handleDownloadPdf.ts
 * Handles the downloading of a pdf when clicking
 * a button.
 */

interface HandleDownloadPdfProps {
    itemId: number;
    buttonAction: string;
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>
    layoutId?: number;
}

export const handleDownloadPdf = async ({ 
    itemId, buttonAction, setButtonLoader, handleLogout, layoutId
}: HandleDownloadPdfProps) => {
    // If no layout id is given, return
    if (!layoutId) return;

    // Download the pdf
    try {
        // Start the button loader for this button
        setButtonLoader(prev => ({ ...prev, [buttonAction]: true }));

        // Call the api to initiate the generation of the pdf of the given item
        const blob = await fetchData({
            apiUrl: `generate-pdf/${layoutId}/${itemId}`,
            responseAsBlob: true,
            handleLogout
        });

        // Create a link to download the pdf
        const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `job_${itemId}_pdf.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);

    // Stop button loader after successfully generating the pdf
    } finally {
        setButtonLoader(prev => ({ ...prev, [buttonAction]: false }));
    }
}