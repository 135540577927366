import React, { useMemo } from 'react';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { getNavigatorLanguageOrFallback } from 'internationalization/i18n';
import InlineForm from 'components/forms/InlineForm';
import { FieldType } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { v4 as uuidv4 } from 'uuid';

const LoginForm: React.FC = () => {
    const { handleLogin } = useAuthContext();
    const viewKey = useMemo(() => uuidv4(), []);

    // Handles the submit of the login in a custom api function and transforms the data
    const transformDataForHandleLogin = async (submitData: Record<string, any>) => {
        const email = submitData.email;
        const password = submitData.password;
        const navigator_language = getNavigatorLanguageOrFallback(navigator.language, 'language');
        const navigator_locale = getNavigatorLanguageOrFallback(navigator.language, 'locale');
        const navigator_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
        const remember_login = submitData.remember_login;

        await handleLogin(email, password, navigator_language, navigator_locale, navigator_timezone, remember_login);
    }

    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'email',
            placeholder: 'account.email_placeholder',
            allow1Password: true,
        },
        {
            type: 'password',
            name: 'password',
            placeholder: 'account.password_placeholder',
        },
        // {
        //     type: 'checkbox',
        //     name: 'remember_login',
        //     label: 'account.remember_login_label',
        //     align: 'left'
        // },
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'account.login_button_label',
            position: 'fullwidth',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'large',
            showLoader: true,
        },
    ];

    return (
        <InlineForm
            viewKey={viewKey}  
            customApiFunction={transformDataForHandleLogin}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default LoginForm;