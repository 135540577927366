/*
 * roleSpecificFeatures.ts
 * This file contains a list of all features which are set as
 * role specific features. This is needed to know which role
 * features are turned off, because only the allowed features
 * in the role are saved.
 */

export const roleSpecificFeatures = [
    'contacts', 'products', 'subcontractors', 'inventory', 'proposals', 'scheduling', 
    'jobs', 'manage_timesheets', 'contract_management', 'invoices', 'inbox', 'tickets',
    'workflows', 'hrm', 'reporting',
];