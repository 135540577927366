/*
 * dragDropUtils.tsx
 * General utility of drag and drop related functions.
 */

import React, { useEffect, useState } from "react";
import { Droppable, DroppableProps } from "react-beautiful-dnd";

// Removes the React strictmode from droppable elements, otherwise drag and drop wont work
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  	const [enabled, setEnabled] = useState(false);
	
  	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));
		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
  	}, []);
	
	if (!enabled) {
		return null;
	}
	return <Droppable {...props}>{children}</Droppable>;
};