import React from 'react';
import { FieldHeaderType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import '../../../../style/scss/live-edit.scss';
import '../../../../style/scss/forms.scss';

const FieldHeader: React.FC<FieldHeaderType> = ({
    header, description, disabled,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`field-header ${disabled ? 'disabled' : ''}`}>
            {header && 
                <h6 className='header'>
                    {t(header)}
                </h6>
            }
            {description && 
                <p className='description'>
                    {t(description)}
                </p>
            }
        </div>
    );
};

export default FieldHeader;