import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import '../../style/scss/alert.scss';

const FloatingAlert: React.FC = () => {
    const { t } = useTranslation();
    const { floatingAlert, setFloatingAlert } = useGlobalContext();
    const [visible, setVisible] = useState(false);

    // Show the alert, set a timer and set the visiblity to false afterwards
    useEffect(() => {
        if (floatingAlert) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
                setFloatingAlert(null);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [floatingAlert, setFloatingAlert]);

    // Hide the alert if it is not visible
    if (!visible) {
        return null;
    }

    // Renders the component
    return (
        floatingAlert && (
            <div className={`alert floating-alert alert-${floatingAlert.type}`}>
                {floatingAlert.type === 'success' ? t(floatingAlert.message) || t('alert.floating_alert_success') : t(floatingAlert.message)}
                <FontAwesomeIcon
                    icon={faTimes}
                    className='close-alert'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFloatingAlert(null)}
                />
            </div>
        )
    );
};

export default FloatingAlert;