import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const TimeBlockForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const apiObject = 'timeblock'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'timeblock.general.name_label',
            type: 'text',
            placeholder: 'timeblock.general.name_placeholder',
        },
        {
            name: 'start_time',
            label: 'timeblock.general.start_time_label',
            type: 'time',
            width: '50%',
            group: 'field-row',
        },
        {
            name: 'end_time',
            label: 'timeblock.general.end_time_label',
            type: 'time',
            width: '50%',
            group: 'field-row',
        }
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default TimeBlockForm;