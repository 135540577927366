import React from 'react';
import { ListColumnType, ListRowType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import LabelForm from './LabelForm';

// Rename the name of the function:
const LabelList: React.FC = () => {

    // Specify the options of this list:
    const query = { 
        endpoint: 'get_label_list',
        object: 'label',
        defaultParams: { 'deleted': false } 
    }
    const objectName = 'label.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deleted'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <LabelForm />;
    const onRowClick = 'editModal'

    // Custom jsx to render the labels as badges
    const renderLabelBadge = (name: string, row: ListRowType) => {
        return (
            <div className='badge-container'>
                <div className={`label-badge ${row.color}`}>
                    {name}
                </div>                        
            </div>
        );
    }

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { field: 'name', label: 'label.general.name_label', width: '1fr', render: renderLabelBadge },
        { field: 'connected_feature', label: 'label.general.connected_feature_label', width: '1fr' }
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default LabelList;