import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FormFieldContext from '../FormFieldContext';
import { baseUrl } from 'App';
import { FieldData, ImageFieldType } from 'types/FieldTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'components/modals/ModalContext';
import ImageEditor from './ImageEditor';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const ImageField: React.FC<ImageFieldType & { data: FieldData, viewKey: string }> = ({ 
    name, label, type, data, apiField, helperText, tooltipText, dropzoneText, generatedFileName,
    viewInEditMode, alignment, isEditable, disabled = false, viewKey, cropConfig, imageType
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { setUnsavedChanges, setFloatingAlert } = useGlobalContext();
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const [image, setImage] = useState<string | null>(null);
    const [imageName, setImageName] = useState<string | null>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Notice the dragging of files across the entire screen
    useEffect(() => {
        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDragOver(true);
        };

        const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDragOver(false);
        };

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDragOver(false);
            const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
            processFile(file);
        };

        // Add and clean event listeners
        document.addEventListener('dragover', handleDragOver as any);
        document.addEventListener('dragleave', handleDragLeave as any);
        document.addEventListener('drop', handleDrop as any);
        return () => {
            document.removeEventListener('dragover', handleDragOver as any);
            document.removeEventListener('dragleave', handleDragLeave as any);
            document.removeEventListener('drop', handleDrop as any);
        };
    }, []);

    // Set the image url from the fetched data
    useEffect(() => {
        if (data && data[name]) {

            // Configure the path of the image based on the server environment
            let imagePath;
            if (process.env.NODE_ENV === 'production') {
                // For live environments, just use the fetched data url
                imagePath = `${data[name]}`
            } else {
                // For local environments, use the base url with the fetched data url
                imagePath = `${baseUrl}${data[name]}`
            }

            // Set the image and image name
            setImage(imagePath);
            setImageName(data[name].substring(data[name].lastIndexOf('/') + 1))
        } else {
            setImage(null);
        }
    }, [data, name]);
    
    // Show the file selector window when clicking on the dropzone
    const handleFileSelectClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle image upload when selecting a file through the dropzone selector window
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        processFile(file);
    };

    // Process the selected or dragged file
    const processFile = (file: File | null) => {
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = async (e: ProgressEvent<FileReader>) => {
                const result = e.target?.result as string;

                // If the generated file name prop is given, generate a short file name
                let newFileName: string;
                if (generatedFileName) {
                    // Generate a random code
                    const randomCode = Math.random().toString(36).substring(2, 7);

                    // Get the extension from the original file name
                    const extension = file.name.substring(file.name.lastIndexOf('.'))

                    // Construct the generated new filename
                    newFileName = `${generatedFileName.preFix}_${randomCode}${extension}`;

                // If not, take the original file name
                } else {
                    newFileName = file.name;
                }

                // Set the image name
                setImageName(newFileName);

                if (cropConfig) {
                    // If cropConfig is given, open the new image in a new modal to edit
                    initializeModal(<ImageEditor image={result} fileName={newFileName} cropConfig={cropConfig} onCroppedImage={handleSave} />, { modalSize: 'smaller' } )
                } else {
                    // Directly save the image without cropping
                    handleSave(result, newFileName);
                }
            };
            reader.readAsDataURL(file);

            // Set unsaved changes after succesfully select a file
            setUnsavedChanges(viewKey, true);
        } else {
            setFloatingAlert({ type: 'warning', message: 'validation.image.unsupported_file_type'})
        }
    };

    // Handle the deletion of the image
    const handleRemoveImage = () => {
        setImage(null);
        setUpdatedData({ ...updatedData, 
            [apiField.fileUpload]: null,
            [name]: null
        });
    };

    // Handle the save of the new image
    const handleSave = (croppedImageUrl: string, fileName: string) => {
        setImage(croppedImageUrl)

        setUpdatedData({
            ...updatedData,
            [apiField.fileUpload]: croppedImageUrl,
            // Only add the file name if the file name api field prop is given
            ...(apiField.fileName ? { [apiField.fileName]: fileName } : {})
        });
    }

    return (
        <FieldWrapper
            name={name}
            type={type}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? ( 
                // Edit mode
                image ? (
                    // Show current image
                    <div className={`current-image ${alignment}`}>
                        <div className='details'>
                            {imageName &&
                                <div className='image-name'>
                                    {imageName}
                                </div>
                            }
                            <div className='remove-button'
                                 onClick={handleRemoveImage}>
                                {t('forms.delete_replace_label')}
                            </div>
                        </div>
                        <div className={`image ${alignment} ${imageType}`} 
                             style={{ backgroundImage: `url(${image})` }}>
                            <span className='remove-button'
                                  onClick={handleRemoveImage}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </div>
                    </div>
                ) : (
                    // Show dropzone if there is no image
                    <div className={`dropzone ${isDragOver ? 'hover' : ''}`}
                         onClick={handleFileSelectClick}>
                        <input type='file'
                               onChange={handleImageUpload} 
                               accept='image/png, image/jpeg, image/jpg'
                               ref={fileInputRef} />
                        <span onClick={handleFileSelectClick}>
                            {t(dropzoneText)}
                        </span>
                    </div>
                )
            ) : (
                // View mode
                <FieldViewMode 
                    value={image} 
                    type='image'
                    alignment={alignment}
                    imageType={imageType}
                />
            )}
        </FieldWrapper>
    )
};

export default ImageField;