import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/scss/dashboard.scss';

const TrialHomePage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='container-filled'>
            <div className='dashboard'>
                <div>
                    <h2>
                        Welkom in jouw proefversie.
                    </h2>
                </div>
                <div>
                    <h5>
                        We gaan je helpen tijd te besparen en de resultaten binnen je bedrijf te verhogen.
                    </h5>
                </div>
                <div>
                    <h5>Hoe je kunt starten:</h5>
                    <h5>1. Maak een offerte</h5>
                    <h5>2. Maak een opdracht aan</h5>
                    <h5>3. Vul de werkbon in via de app</h5>
                    <h5>4. Factureer direct</h5>
                    <h5>5. Importeer je eigen data</h5>
                </div>
                <div>
                    <h5>
                        Help Center - Support - Bekijk alle features
                    </h5>
                    <h5>
                        Beginnen met een lege omgeving? (verwijder demo data)
                    </h5>
                    misschien verwijder demo data ook overal een knop tonen in een banner of footer of zo.
                </div>
                <div>
                    <h5>
                        Je proef loopt nog 30 dagen - Bestel
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default TrialHomePage;