import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const InvoiceForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'invoice'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'searchselect', 
            name: 'recipient',
            label: 'invoice.general.recipient_label',
            placeholder: 'invoice.general.recipient_placeholder',
            objectName: 'recipient.general.object_name.singular',
            query: {
                // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                endpoint: 'get_contact_search',
                params: {
                    contact_type: ['contact', 'prospect', 'client'] 
                }
            },
            postEndpoint: 'post_contact',
            selectionFormat: 'name',
            optionFormat: {
                title: { field: 'name', format: (value) => value },
                subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
            },
        },
        // TODO: Show field for recipient contact, only if the recipient is an organization ?
        { 
            type: 'searchselect', 
            name: 'invoice_address',
            label: 'invoice.general.invoice_address_label',
            placeholder: 'invoice.general.invoice_address_placeholder',
            objectName: 'location.general.object_name.singular',
            query: {
                // Get all active locations
                endpoint: 'get_location_search',
            },
            postEndpoint: 'post_location',
            selectionFormat: 'address',
            optionFormat: {
                title: { field: 'address', format: (value) => value },
            },
        },
        {
            type: 'header',
        },
        { 
            type: 'dropdown', 
            name: 'workflow', 
            label: 'invoice.general.workflow_label',
            disabled_selected: 'invoice.general.workflow_disabled_selected',
            allowNoneOption: false,
            showSearch: true,
        },
        {
            type: 'text',
            name: 'internal_reference',
            label: 'invoice.general.internal_reference_label',
            placeholder: 'invoice.general.internal_reference_placeholder',
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}       
        />
    );
};

export default InvoiceForm;