import axios, { AxiosError } from 'axios';
import { mapDateTimeFieldToFrontend } from 'services/utils/dateTimeUtils';

/*
 * handleSaveErrors.tsx
 * General utility function that handles save errors after saving data to the server.
 * It gives console errors when the request is cancelled or saving is not possible due
 * to a general api error. It also ensures that errors associated with form fields can be
 * displayed at the specified field, and form errors which are not associated to field can
 * be displayed in a general error message on top of the form.
 */

export function handleSaveErrors(error: any, fieldNames: string[]): Record<string, any> {
    if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return {};
        
    } else {
        const apiError = error as AxiosError;
        console.error('Saving not possible', error);

        if (apiError.response && typeof apiError.response.data === 'object') {
            const errorDataBackend = apiError.response.data as Record<string, any>;

            // Convert the backend error data to match frontend fields
            const errorData: Record<string, any> = {};
            for (const [backendField, errorMessage] of Object.entries(errorDataBackend)) {
                const isIndex = /^\d+$/.test(backendField);

                // If the field has an index
                if (isIndex) {
                    const index = parseInt(backendField, 10);

                    // Ensure we have an object to store the errors for the given index
                    if (!errorData[index]) {
                        errorData[index] = {};
                    }

                    // Process the fields inside this index
                    for (const [fieldKey, fieldErrors] of Object.entries(errorMessage)) {
                        // Only grab the first error message if there's a list
                        errorData[index][fieldKey] = Array.isArray(fieldErrors) ? fieldErrors[0] : fieldErrors;
                    }
                } 
                // If field does not have an index
                else {
                    // If the field is a special datetime field
                    for (const frontendField of mapDateTimeFieldToFrontend(backendField)) {
                        if (fieldNames.includes(frontendField)) {
                            errorData[frontendField] = errorMessage;
                        }
                    }
                    
                    // If it's a regular field
                    if (!errorData[backendField]) {
                        errorData[backendField] = errorMessage;
                    }
                }
            }
            
            // If we didn't match any frontend fields, generate a general error message
            if (Object.keys(errorData).length === 0) {
                let errorMessage = 'alert.general_save_error_message';
                for (let field in errorDataBackend) {
                    const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
                    errorMessage += ` ${capitalizedField}: ${errorDataBackend[field]}`;
                }
                return { general: errorMessage };
            }

            return errorData;

        } else {
            return { general: error.message || 'alert.general_save_error_message' };
        }
    }
}