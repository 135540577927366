import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { IdType, IdValuePairsType, NumberWidgetProps, WidgetDataType } from 'types/WidgetTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/widgets.scss';

const NumberWidget: React.FC<NumberWidgetProps> = ({ 
    widgetKey, defaultData, dimensionData, calculationMethod, period, prefix, measuringUnit, decimals = 0, moreIsNegative = false
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const [currentNumber, setCurrentNumber] = useState<string>('0');
    const [difference, setDifference] = useState<string>('0');

    // Convert the widget data into the current number and difference
    useEffect(() => {
        // Calculate the current number and difference
        const results = calculateData(
            defaultData.current,
            defaultData.previous,
            calculationMethod,
            dimensionData
        );

        // Set the calculated numbers to show in the widget
        setCurrentNumber(results.current.toString());
        setDifference(results.difference.toString());
    }, [defaultData, dimensionData, calculationMethod]);

    // Calculate the data to show in the widget
    const calculateData = (
        currentData: IdType | IdValuePairsType,
        previousData: IdType | IdValuePairsType,
        calculation: 'count' | 'average' | 'divide',
        dimensionData?: WidgetDataType
    ): { current: number, previous: number, difference: number } => {

        // Function to count the values of the data
        const sumValues = (data: IdType | IdValuePairsType): number => {
            // Return if no data is available
            if (!data.length) return 0;

            if (typeof data[0] === 'number') {
                // If the data is an array of ids, count the number of ids
                return data.length;
            } else {
                // If the data is an array of objects (IdValuePairs), sum the values inside the value fields after they are converted to float 
                return (data as IdValuePairsType).reduce((acc, val) => {
                    // Handle null values als 0
                    const numericValue = parseFloat(val.value) || 0;
                    return acc + numericValue;
                }, 0);
            }
        };

        // Count the values in the current and previous data states
        const count = sumValues(currentData);
        const previousCount = sumValues(previousData);

        // Initialize current and previous variables
        let current = 0;
        let previous = 0;

        // Handle the calculation per scenario
        switch (calculation) {
            case 'count':
                current = count;
                previous = previousCount;
                break;
            case 'average':
                if (Array.isArray(currentData)) {
                    if (currentData.length > 0) {
                        current = count / currentData.length;
                    } else {
                        current = 0;
                    }
                    if (previousData.length > 0) {
                        previous = previousCount / previousData.length;
                    } else {
                        previous = 0;
                    }
                }
                break;
            case 'divide':
                if (dimensionData) {
                    const dimensionCount = sumValues(dimensionData.current);
                    const previousDimensionCount = sumValues(dimensionData.previous);
                    current = dimensionCount !== 0 ? count / dimensionCount : 0;
                    previous = previousDimensionCount !== 0 ? previousCount / previousDimensionCount : 0;
                }
                break;
        }

        // Calculate the difference
        const difference = current - previous;

        // Return the calculated values
        return { current, previous, difference };
    };

    // Format the number with decimals and comma based on user locale
    const formatNumber = (number: string) => {
        const num = parseFloat(number);
        return new Intl.NumberFormat(userLocale, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }).format(isNaN(num) ? 0 : num);
    };

    // Determine the right period translation label based on the given period of the widget
    const periodLabel = period === 'week' ? 'date_time.general.week' : 'date_time.general.month';

    // Remove the minus sign if present, the caret icon takes care of this
    const formattedDifference = isNaN(parseFloat(difference)) ? '-' : Math.abs(parseInt(difference)).toString();

    return (
        <div key={widgetKey} className='number-widget'>
            <div className='current-number'>
                {prefix && (
                    <div className='prefix'>{prefix}</div>
                )}
                <h1>{formatNumber(currentNumber)}</h1>
                {measuringUnit && (
                    <div className='measuring-unit'>{t(measuringUnit)}</div>
                )}
            </div>
            {parseInt(difference) === 0 ? (
                // If no difference, just show a dash
                <div className='difference'>
                    <span>-</span>
                    &nbsp;{t('widget.general.difference_label', { period: t(periodLabel) })}
                </div>
            ) : (
                // If difference, show positive / negative caret icon with the difference
                <div className='difference'>
                    <div className={parseInt(difference) > 0 ? (moreIsNegative ? 'negative' : 'positive') : (moreIsNegative ? 'positive' : 'negative')}>
                        <FontAwesomeIcon icon={parseInt(difference) > 0 ? faCaretUp : faCaretDown} />
                        <span> {formatNumber(formattedDifference)}</span>
                    </div>
                    &nbsp;{t('widget.general.difference_label', { period: t(periodLabel) })}
                </div>
            )}
        </div>
    );
};

export default NumberWidget;