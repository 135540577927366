import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import InvoiceForm from './InvoiceForm';
import InvoiceModal from './InvoiceModal';
import { useTranslation } from 'react-i18next';
import { currencySymbols } from 'internationalization/currencySymbols';
import { useSettings } from 'services/settings/SettingsContext';

// Rename the name of the function:
const InvoiceList: React.FC = () => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_invoice_list',
        defaultParams: { 'deleted': false },
        object: 'invoice'
    }
    const objectName = 'invoice.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const formModal = <InvoiceForm />;
    const detailModal = <InvoiceModal />;
    const onRowClick = 'detailModal'

    // Custom jsx to render the amount with its currency in the same column
    // const renderAmountColumn = (amount: number, salePriceCurrency: string | null | undefined) => {
    //     // If no amount exists on this invoice, show a dash
    //     if (amount == null) {
    //         return <div>-</div>
    //     }

    //     // Check wheter a sale price currency exists
    //     const currency = salePriceCurrency ? salePriceCurrency.toLowerCase() : '';

    //     // Get the currency symbol for the currency of the rate
    //     const currencySymbol = currencySymbols[currency] || salePriceCurrency;

    //     // Format the rate based on the user locale
    //     const formattedRate = salePrice.toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    //     // Configure the rate column string as div
    //     return (
    //         <div>
    //             {currencySymbol} {formattedRate}
    //         </div>
    //     )
    // }

    // Custom jsx to render the status as badge
    const renderStatusBadge = (name: string, condition: string) => {
        return (
            <div className='badge-container'>
                <div className={`status-badge ${condition}`}>
                    {t(`workflow.default_status_names.${name}`)}
                </div>                        
            </div>
        );
    }

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '1.5fr', field: 'id', label: 'invoice.general.invoice_number_label' },
        { width: '4fr', field: 'recipient_name', label: 'invoice.general.recipient_label' },
        { width: '3fr', field: 'internal_reference', label: 'invoice.general.internal_reference_label' },
        { width: '2fr', field: 'invoice_date', label: 'invoice.general.invoice_date_label' },
        // { width: '2fr', field: 'amount', label: 'invoice.general.amount_label', render: (_, row) => renderAmountColumn(row.sale_price, row.sale_price_currency) },
        { width: '144px', field: 'status', label: 'invoice.general.status_label', render: (_, row) => renderStatusBadge(row.status_name, row.status_condition) }
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'invoice.general.all_invoices_filter_label',
            params: { 'deleted': false },
            default: true,
        },
        {
            group: 'invoice.general.status_condition_filter_group_label',
            filters: [
                {
                    id: 'invoice_created',
                    label: 'invoice.status_condition.invoice_created',
                    params: { 'status_condition': 'invoice_created' },
                },
                {
                    id: 'invoice_sent',
                    label: 'invoice.status_condition.invoice_sent',
                    params: { 'status_condition': 'invoice_sent' },
                },
                {
                    id: 'invoice_expired',
                    label: 'invoice.status_condition.invoice_expired',
                    params: { 'status_condition': 'invoice_expired' },
                },
                {
                    id: 'invoice_reminded',
                    label: 'invoice.status_condition.invoice_reminded',
                    params: { 'status_condition': 'invoice_reminded' },
                },
                {
                    id: 'invoice_paid',
                    label: 'invoice.status_condition.invoice_paid',
                    params: { 'status_condition': 'invoice_paid' },
                },
            ]
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deleted',
                    label: 'invoice.general.deleted_invoices_label',
                    shortName: 'general.deleted',
                    params: { 'deleted': true },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            filters={filters}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            formModal={formModal}
            detailModal={detailModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default InvoiceList;