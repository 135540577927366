import React, { useState, useEffect } from 'react';
import InlineForm from 'components/forms/InlineForm';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { fetchData } from 'services/api/fetchData';
import { FieldType, FieldOption } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { TimelineTaskTypeType, TimelineUserType } from 'types/TimelineTypes';

/*
 * TaskTimelineForm.tsx
 * Field configuration file of the fields on the task timeline form. In addition to configuring the fields
 * of the form, it loads the task types of the api, sorts them based on their ordering and sets the first
 * task type as the default task subject and task type on the form. This only happens when the form has no 
 * taskId and is therefore not used to edit an existing task. As a result, the default value is only set 
 * for forms where a new task is created. Also, when no task subject is entered by the user, but the task
 * type is changed, the default subject value is changed to the name of the selected task type. This allows 
 * the user to add tasks more quickly, as they can enter the subjects of the most frequently used tasks as 
 * a task type, and then only have to select the task type.
 */

interface TaskTimelineFormProps {
    viewKey: string;
    taskId?: number;
    timeline_page_id: string;
    timeline_page?: string;
    createdBy?: TimelineUserType | null;
    createdDate?: string;
    modifiedBy?: TimelineUserType | null;
    modifiedDate?: string;
    onClose?: () => void;
};

const TaskTimelineForm: React.FC<TaskTimelineFormProps> = ({ viewKey, taskId, timeline_page_id, timeline_page, createdBy, createdDate, modifiedBy, modifiedDate, onClose }) => {
    const apiObject = 'task'
    const { t } = useTranslation();
    const { userHash, handleLogout } = useAuthContext();
    const [taskTypes, setTaskTypes] = useState<TimelineTaskTypeType[]>([])
    const [defaultSubjectValue, setDefaultSubjectValue] = useState<string | undefined>(taskTypes.length > 0 ? taskTypes[0]?.name : undefined);
    const [defaultTypeValue, setDefaultTypeValue] = useState<number | string | undefined>(taskTypes.length > 0 ? taskTypes[0]?.id : undefined);

    // Load the task types from the api
    useEffect(() => { 
        const fetchTaskTypes = async () => {
            try {
                const response = await fetchData({ apiObject: 'tasktype', handleLogout });
                let fetchedTaskTypes = response.results;

                // Sort the task types based on the ordering field
                fetchedTaskTypes = fetchedTaskTypes.sort((a: TimelineTaskTypeType, b: TimelineTaskTypeType) => a.ordering - b.ordering);
                setTaskTypes(fetchedTaskTypes);
            } catch (error) {
                console.log("Failed to load task types data", error);
            }
        };
        fetchTaskTypes();
    }, []);

    // When task types are loaded, set the default subject and type
    useEffect(() => {
        if (!taskId) {
            setDefaultSubjectValue(taskTypes.length > 0 ? taskTypes[0]?.name : undefined);
            setDefaultTypeValue(taskTypes.length > 0 ? taskTypes[0]?.id : undefined);
        };
    }, [taskTypes, taskId]);

    // If no task subject is entered by the user, but the task type is changed, change the subject default value to the task type name
    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const newType = Number(event.target.value);
        const newSubjectValue = taskTypes.find(taskType => taskType.id === newType)?.name;
        setDefaultSubjectValue(newSubjectValue);
    }

    // Add some base information of the task as meta data to the task form to submit with the inserted values of the form
    const metaData: Record<string, number | string | undefined> = {
        // Add the timeline page with its page id to connect the task to
        [timeline_page as string]: timeline_page_id,

        // If adding a new task (so no taskId), set the created user, otherwise set the modified user
        ...(!taskId ? { 'created_by_user_hash': userHash } : { 'modified_by_user_hash': userHash })
    };

    // Add created and/or modified info to the form information of the form
    const formInformation: React.ReactNode = (
        <>
            {createdBy && createdDate && (
                <p>{t('timeline.task.field.created_information', { date: createdDate, creator: createdBy.full_name })}</p>
            )}
            {modifiedBy && modifiedDate && (
                <p>{t('timeline.task.field.modified_information', { date: modifiedDate, modifier: modifiedBy.full_name })}</p>
            )}
        </>
    );

    // Sets the fields of the task timeline form
    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'subject',
            placeholder: 'timeline.task.field.subject_placeholder',
            defaultValue: taskId ? undefined : defaultSubjectValue,
        },
        {
            type: 'tabs',
            name: 'type',
            apiObject: 'tasktype',
            showIconOnly: true,
            defaultValue: taskId ? undefined : defaultTypeValue,
        },
        {
            type: 'date',
            name: 'start_date',
            width: '28.5%',
            group: 'datetime-row',
        },
        {
            type: 'time',
            name: 'start_time',
            width: '20%',
            group: 'datetime-row',
        },
        {
            type: 'separator',
            width: '3%',
            group: 'datetime-row',
        },
        {
            type: 'time',
            name: 'end_time',
            width: '20%',
            group: 'datetime-row',
        },
        {
            type: 'date',
            name: 'end_date',
            placeholder: 'MM-DD-YYYY',
            width: '28.5%',
            group: 'datetime-row',
        },
        {
            type: 'textarea',
            name: 'note',
            isNote: true,
            enableStyling: 'simple',
            rows: 1,
            helperText: 'timeline.task.field.note_helperText',
            placeholder: 'timeline.task.field.note_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'assignee',
            disabled_selected: 'timeline.task.field.assignee_placeholder',  
            selectionFormat: 'full_name',
            defaultValue: {
                value: userHash,
                apiField: 'user_hash'
            },
            optionFormat: (option: FieldOption) => option.full_name,
            apiObject: 'user',
            params: { 
                is_active: true, 
                office_access: true, // Only show users with office access rights -- Tasks can only be added to office users.
            },
            allowNoneOption: false,
        },
    ];

    // Sets the buttons of the task timeline form
    const buttons: FormButtonType[] = [
        {
            label: 'general.cancel',
            position: 'right',
            hierarchy: 'secondary',
            onClick: 'handleClose',
            size: 'extra-small'
        },
        {
            label: 'general.save',
            position: 'right',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'extra-small',
            showLoader: true,
        },
    ];

    return (
        <InlineForm  
            viewKey={viewKey}
            apiObject={apiObject}
            itemId={taskId}
            fields={fields}
            buttons={buttons}
            formInformation={formInformation}
            metaData={metaData}
            onClose={onClose}
            onTypeChange={handleTypeChange}
            refresh={defaultSubjectValue}
        />
    );
};

export default TaskTimelineForm;