import React from 'react';
import { FieldType } from 'types/FieldTypes';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { usePackageFeature } from 'services/permissions/permissionChecks';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const RoleForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const { activeFeatures } = useAuthContext();
    const isEnabled = usePackageFeature('custom_roles');
    const apiObject = 'role'

    // Plug in the allowed office features. This function filters them so that only the in the environment active features are shown here
    const showActiveOfficeFeatures = [
        {
            value: 'contacts',
            label: 'role.general.office_features.contacts_label',
            tooltipText: 'role.general.office_features.contacts_tooltip'
        },
        {
            value: 'products',
            label: 'role.general.office_features.products_label',
            tooltipText: 'role.general.office_features.products_tooltip'
        },
        {
            value: 'subcontractors',
            label: 'role.general.office_features.subcontractors_label',
            tooltipText: 'role.general.office_features.subcontractors_tooltip'
        },
        {
            value: 'inventory',
            label: 'role.general.office_features.inventory_label',
            tooltipText: 'role.general.office_features.inventory_tooltip'
        },
        {
            value: 'proposals',
            label: 'role.general.office_features.proposals_label',
        },
        {
            value: 'scheduling',
            label: 'role.general.office_features.scheduling_label',
        },
        {
            value: 'jobs',
            label: 'role.general.office_features.jobs_label',
        },
        {
            value: 'invoices',
            label: 'role.general.office_features.invoices_label',
        },
        {
            value: 'timesheets',
            label: 'role.general.office_features.timesheets_label',
        },
        {
            value: 'contract_management',
            label: 'role.general.office_features.contract_management_label',
        },
        {
            value: 'inbox',
            label: 'role.general.office_features.inbox_label',
            tooltipText: 'role.general.office_features.inbox_tooltip'
        },
        {
            value: 'workflows',
            label: 'role.general.office_features.workflows_label',
        },
        {
            value: 'tickets',
            label: 'role.general.office_features.tickets_label',
        },
        {
            value: 'hrm',
            label: 'role.general.office_features.hrm_label',
            tooltipText: 'role.general.office_features.hrm_tooltip'
        },
        {
            value: 'reporting',
            label: 'role.general.office_features.reporting_label',
        },
    ].filter(option => activeFeatures.includes(option.value));

    // Plug in the allowed field features. This function filters them so that only the in the environment active features are shown here
    const showActiveFieldFeatures = [
        {
            value: 'mobile_jobs',
            label: 'role.general.field_features.mobile_jobs_label',
        },
        {
            value: 'mobile_timesheet',
            label: 'role.general.field_features.mobile_timesheet_label',
        },
        {
            value: 'mobile_create_jobs',
            label: 'role.general.field_features.mobile_create_jobs_label',
            tooltipText: 'role.general.field_features.mobile_create_jobs_tooltip',
        },
        // Commented out because these features are not available yet -- please don't delete this
        // {
        //     value: 'mobile_calendar',
        //     label: 'role.general.field_features.mobile_calendar_label',
        //     tooltipText: 'role.general.field_features.mobile_calendar_tooltip'
        // },
        // {
        //     value: 'mobile_leave_requests',
        //     label: 'role.general.field_features.mobile_leave_requests_label',
        // },
        // {
        //     value: 'mobile_expenses',
        //     label: 'role.general.field_features.mobile_expenses_label',
        // },
        // {
        //     value: 'mobile_tasks',
        //     label: 'role.general.field_features.mobile_tasks_label',
        //     tooltipText: 'role.general.field_features.mobile_tasks_tooltip',
        // },
        // {
        //     value: 'mobile_payments',
        //     label: 'role.general.field_features.mobile_payments_label',
        //     tooltipText: 'role.general.field_features.mobile_payments_tooltip'
        // },
    ].filter(option => activeFeatures.includes(option.value));

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'role.general.name_label',
            type: 'text',
            placeholder: 'role.general.name_placeholder',
        },
        {
            type: 'header',
            header: 'role.general.access_to_header',
            description: 'role.general.access_to_description'
        },
        {
            name: 'access_to',
            type: 'checkbox',
            viewInEditMode: true,
            align: 'left',
            options: [
                {
                    value: 'office',
                    label: 'role.general.office_environment_label',
                },
                {
                    value: 'field',
                    label: 'role.general.mobile_field_worker_label',
                },
            ],
        },
        {
            type: 'header',
            header: 'role.general.allowed_features_header',
            description: 'role.general.allowed_features_description',
            dependentOn: { fieldName: 'access_to', value: ['office', 'field'] },
        },
        {
            name: 'allowed_features',
            type: 'checkbox',
            viewInEditMode: true,
            label: 'role.general.office_features_label',
            dependentOn: { fieldName: 'access_to', value: 'office' },
            layout: 'two-column',
            options: showActiveOfficeFeatures,
        },
        {
            name: 'allowed_features',
            type: 'checkbox',
            viewInEditMode: true,
            label: 'role.general.field_features_label',
            dependentOn: { fieldName: 'access_to', value: 'field' },
            layout: 'two-column',
            options: showActiveFieldFeatures,
        },
        {
            type: 'header',
            header: 'role.general.allowed_rights_header',
            description: 'role.general.allowed_rights_description',
        },
        {
            name: 'allowed_rights',
            type: 'checkbox',
            viewInEditMode: true,
            align: 'left',
            options: [
                {
                    value: 'only_view',
                    label: 'role.general.rights.only_view_label',
                    tooltipText: 'role.general.rights.only_view_tooltip'
                },
                {
                    value: 'can_delete',
                    label: 'role.general.rights.can_delete_label',
                },
                {
                    value: 'can_download',
                    label: 'role.general.rights.can_download_label',
                },
                {
                    value: 'can_export',
                    label: 'role.general.rights.can_export_label',
                },
                {
                    value: 'change_settings',
                    label: 'role.general.rights.change_settings_label',
                },
            ],
        }
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields.map(field => ({ 
                ...field, 
                // Adds the disabled property to every field if the custom roles feature is not available within the current environment package
                disabled: !isEnabled
            }))}
        />
    );
};

export default RoleForm;