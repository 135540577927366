import { generateWeekNumber } from "services/utils/dateTimeUtils";
import { generateJobInboxWeeks } from "./generateJobInboxWeeks";

// Initialize the end date of the initial inbox jobs fetch to the last day of the week 20 weeks in the future
export const initializeInboxJobsFetchParams = (): { from?: string, to?: string } => {

    // Generate 20 weeks in the future. This generates a year-week string, e.g. '2024-43'
    const generatedWeeks = generateJobInboxWeeks(20);

    // Get the last date of the generated weeks
    const lastDate = getLastDateOfWeek(generatedWeeks);

    return { to: lastDate };
};

// Get the first date of the generated weeks
export const getFirstDateOfWeek = (generatedWeeks: string[]): string => {

    // Pick the first week from the generated weeks, and split into the year and week part
    const firstWeek = generatedWeeks[0];
    const year = Number(firstWeek.split('-')[0]);
    const week = Number(firstWeek.split('-')[1]);

    // Determine the day number of jan 1
    const jan1 = new Date(year, 0, 1);
    const jan1DayOfWeek = jan1.getDay();

    // If jan 1 is on sunday (day 0), replace it by 6 to put it at the end of the week
    const offset = (jan1DayOfWeek === 0 ? 6 : jan1DayOfWeek - 1);

    // Calculate the amount of days from the first day of the year
    const dayAmount = 1 + (week - 1) * 7 - offset;

    // Generate the start date of the first week
    const firstDateOfFirstWeek = new Date(year, 0, dayAmount);

    // Pick the date part from the iso string
    const firstDate = firstDateOfFirstWeek.toISOString().split('T')[0];

    return firstDate;
}

// Get the last date of the generated weeks
export const getLastDateOfWeek = (generatedWeeks: string[]): string => {

    // Pick the last week from the generated weeks, and split into the year and week part
    const lastWeek = generatedWeeks[generatedWeeks.length - 1];
    const yearPart = lastWeek.split('-')[0];
    const weekPart = lastWeek.split('-')[1];

    // Generate the start date of the last week
    const firstDateOfLastWeek = new Date(Number(yearPart), 0, (Number(weekPart) - 1) * 7 + 1);
 
    // Generate the end date of the last week
    const lastDateofLastWeek = new Date(firstDateOfLastWeek);
    lastDateofLastWeek.setDate(firstDateOfLastWeek.getDate() + 7);

    // Pick the date part from the iso string as to value 
    const lastDate = lastDateofLastWeek.toISOString().split('T')[0];

    return lastDate;
}

// Determine if the target date is in the current or future week
export const newTargetDateInCurrentOrFutureWeek = (target_date: string, weekStartsOnSunday: boolean): boolean => {

    // Generate the weeknumber and week key for the new target date
    const newTargetDate = new Date(target_date);
    const newTargetWeek = generateWeekNumber(newTargetDate, weekStartsOnSunday);
    const newTargetYear = newTargetDate.getFullYear();
    const newTargetWeekKey = `${newTargetYear}-${newTargetWeek}`;

    // Determine the current week
    const currentDate = new Date();
    const currentWeek = generateWeekNumber(currentDate, weekStartsOnSunday);
    const currentYear = currentDate.getFullYear();
    const currentWeekKey = `${currentYear}-${currentWeek}`;

    // Generate first date of new target week and current week to compare
    const newTargetWeekFirstDate = getFirstDateOfWeek([newTargetWeekKey]);
    const currentWeekFirstDate = getFirstDateOfWeek([currentWeekKey]);

    // Convert to unix epoch milliseconds to compare
    const targetWeekFirstDateUnix = new Date(newTargetWeekFirstDate).getTime();
    const currentWeekFirstDateUnix = new Date(currentWeekFirstDate).getTime();

    // Compare if the target date is equal or greater than the current week
    const isTargetDateInCurrentOrFutureWeek = targetWeekFirstDateUnix >= currentWeekFirstDateUnix;

    return isTargetDateInCurrentOrFutureWeek;
}

// Generate week key (for example '2024-43') for the given target date
export const generateWeekKey = (target_date: string, weekStartsOnSunday: boolean): string => {
    // Convert target date string into date object
    const targetDate = new Date(target_date);

    // Generate week number for the target date
    const targetWeek = generateWeekNumber(targetDate, weekStartsOnSunday);

    // Get the year of the target date
    const targetYear = targetDate.getFullYear();

    // Generate the week key
    const targetWeekKey = `${targetYear}-${targetWeek}`;

    return targetWeekKey;
}