import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const TaskTypeForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'tasktype'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'tasktype.general.name_label',
            type: 'text',
            placeholder: 'tasktype.general.name_placeholder',
        },
        {
            name: 'icon',
            label: 'tasktype.general.icon_label',
            type: 'tabs',
            showIconOnly: true,
            options: [
                { name: 'tasktype.general.icon_option.call', value: 'call', icon: 'call' },
                { name: 'tasktype.general.icon_option.email', value: 'email', icon: 'email' },
                { name: 'tasktype.general.icon_option.meeting', value: 'meeting', icon: 'meeting' },
                { name: 'tasktype.general.icon_option.checkbox', value: 'checkbox', icon: 'checkbox' },
                { name: 'tasktype.general.icon_option.deadline', value: 'deadline', icon: 'deadline' },
                { name: 'tasktype.general.icon_option.lunch', value: 'lunch', icon: 'lunch' },
                { name: 'tasktype.general.icon_option.inbox', value: 'inbox', icon: 'inbox' },
                { name: 'tasktype.general.icon_option.print', value: 'print', icon: 'print' },
                { name: 'tasktype.general.icon_option.time', value: 'time', icon: 'time' },
                { name: 'tasktype.general.icon_option.calender', value: 'calender', icon: 'calender' },
                { name: 'tasktype.general.icon_option.note', value: 'note', icon: 'note' },
                { name: 'tasktype.general.icon_option.chat', value: 'chat', icon: 'chat' },
                { name: 'tasktype.general.icon_option.document', value: 'document', icon: 'document' },
                { name: 'tasktype.general.icon_option.book', value: 'book', icon: 'book' },
                { name: 'tasktype.general.icon_option.folder', value: 'folder', icon: 'folder' },
                { name: 'tasktype.general.icon_option.smartphone', value: 'smartphone', icon: 'smartphone' },
                { name: 'tasktype.general.icon_option.tablet', value: 'tablet', icon: 'tablet' },
                { name: 'tasktype.general.icon_option.car', value: 'car', icon: 'car' },
                { name: 'tasktype.general.icon_option.toolbox', value: 'toolbox', icon: 'toolbox' },
                { name: 'tasktype.general.icon_option.wrench', value: 'wrench', icon: 'wrench' },
                { name: 'tasktype.general.icon_option.tag', value: 'tag', icon: 'tag' },
                { name: 'tasktype.general.icon_option.camera', value: 'camera', icon: 'camera' },
                { name: 'tasktype.general.icon_option.van', value: 'van', icon: 'van' },
                { name: 'tasktype.general.icon_option.trophy', value: 'trophy', icon: 'trophy' },
            ]
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default TaskTypeForm;