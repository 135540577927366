import React, { useEffect, useState } from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';
import { useSettings } from 'services/settings/SettingsContext';

// Rename the name of the function:
const ContactForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const { environmentSettings } = useSettings();
    const [defaultEntityType, setDefaultEntityType] = useState<string>(environmentSettings.contact_default_entity_type || 'organization');
    const apiObject = 'contact'

    // Get the default entity type from the environment settings
    useEffect(() => {
        setDefaultEntityType(environmentSettings.contact_default_entity_type || 'organization');
    }, [environmentSettings]);

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'contact.general.name_label',
            type: 'text',
            placeholder: 'contact.general.name_placeholder'
        },
        { 
            type: 'tabs', 
            name: 'entity_type', 
            label: 'contact.general.entity_type_label', 
            defaultValue: defaultEntityType.toString(), 
            fullWidth: true,
            options: [
                { value: 'organization', name: 'contact.entity_type.organization' },
                { value: 'person', name: 'contact.entity_type.person' },
            ],
        },
        { 
            type: 'dropdown', 
            name: 'contact_type', 
            label: 'contact.general.contact_type_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'contact' },
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'contact', name: 'contact.contact_type.contact'},
                { value: 'prospect', name: 'contact.contact_type.prospect'},
                { value: 'client', name: 'contact.contact_type.client'},
                { value: 'supplier', name: 'contact.contact_type.supplier'},
            ],
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default ContactForm;