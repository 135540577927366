import React from 'react';
import { TimelineItemType, TimelineUserType } from 'types/TimelineTypes';
import { useTranslation } from 'react-i18next';
import { translateTimelineUpdates } from 'internationalization/timelineTranslation';
import { formatTimelineDate } from 'services/utils/dateTimeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../../style/scss/timeline.scss';

interface TimelineUpdateProps {
    item: TimelineItemType;
    createdBy?: TimelineUserType | null;
}

const TimelineUpdate: React.FC<TimelineUpdateProps> = ({ item, createdBy }) => {
    const { t } = useTranslation();
    const isCreationEvent = item.update && item.update.updated_field === 'created' && !item.update.previous_value;

    // Format the date
    const formattedDate = formatTimelineDate(item.ordering_date, false);

    // Translate the timeline updates strings
    if (item.update) {
        const timelinePageType = item.update.timeline_page_type;
        const updatedField = item.update.updated_field || '';
        const newValue = item.update.new_value || '';
        const previousValue = item.update.previous_value || '';
    
        const { translatedFieldName, translatedNewValue, translatedPreviousValue } = translateTimelineUpdates(
            timelinePageType,
            updatedField,
            newValue,
            previousValue,
        );

        return (
            <div className='timeline-item'>
                <div className="marker-container">
                    <div className="small-marker"></div>
                </div>
                <div className="update-content">
                    {item.update && (
                        <>
                            {isCreationEvent ? (
                                // Render a creation label, e.g. 'Product created'
                                <p className='update-text'>
                                    {t(`${timelinePageType}.page_type`)} {t('timeline.update.general.created_label')}
                                </p>
                            ) : (
                                <p className='update-text'>
                                    {t(translatedFieldName)}:
                                    {item.update.previous_value &&
                                        <span> {t(translatedPreviousValue) || item.update.previous_value} <FontAwesomeIcon icon={faArrowRight} className='update-arrow' /> </span>
                                    }
                                    <span> {t(translatedNewValue) || item.update.new_value || t('timeline.update.general.empty_label')}</span>
                                </p>
                            )}
                        </>
                    )}
                    <div>
                        <p className='update-info'>
                            {formattedDate}
                            {createdBy && createdBy.full_name && (
                                <>
                                    <span style={{ margin: '0 5px' }}>•</span>
                                    <span>{createdBy.full_name}</span>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default TimelineUpdate;