import React from 'react';
import { FieldsCardProps } from 'types/DetailPageTypes'; 
import { useTranslation } from 'react-i18next';
import '../../style/scss/card.scss';

const MessagesCard: React.FC<FieldsCardProps> = ({ 
    viewKey, apiObject, itemId, title, data, dropdownData, groupOptions, alignment, loadStatus, refetchData 
}) => {
    const { t } = useTranslation();

    return (
        <div className='card materialscard'>
            <h6>{t('job.messages.messages_label')}</h6>
            {loadStatus === "loading" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && (
                <p>Messages widget here</p>
            )}
        </div>
    );
}

export default MessagesCard;