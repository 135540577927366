import React from 'react';
import { FormButtonType } from 'types/ButtonTypes';
import { FieldType } from 'types/FieldTypes';
import InlineForm from 'components/forms/InlineForm';

const CompanyDetailsForm: React.FC<{ viewKey?: string, environmentHash?: string }> = ({ viewKey, environmentHash }) => {
    const apiObject = 'company_details';

    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'company_name',
            // label: 'company_details.general.company_name_label',
            placeholder: 'company_details.general.company_name_placeholder',
        },
        // {
        //     type: 'text',
        //     name: 'trade_name',
        //     // label: 'company_details.general.trade_name_label',
        //     placeholder: 'company_details.general.trade_name_placeholder',
        // },
        {
            name: 'company_logo',
            // label: 'company_details.general.company_logo_label',
            type: 'image',
            alignment: 'stacked',
            imageType: 'landscape',
            dropzoneText: 'company_details.general.company_logo_placeholder',
            apiField: { 
                fileUpload: 'company_logo_file', 
                fileName: 'company_logo_filename'
            },
            helperText: 'Tip! Zorg voor een .PNG bestand met transparante achtergrond',
        },
        {
            type: 'header',
            header: 'company_details.general.address_header'
        },
        {
            type: 'text',
            name: 'street',
            // label: 'company_details.general.street_label',
            placeholder: 'company_details.general.street_placeholder',
            width: '70%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'house_number',
            // label: 'company_details.general.house_number_label',
            placeholder: 'company_details.general.house_number_placeholder',
            width: '30%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'postal_code',
            // label: 'company_details.general.postal_code_label',
            placeholder: 'company_details.general.postal_code_placeholder',
            width: '40%',
            group: 'field-row-next',
        },
        {
            type: 'text',
            name: 'city',
            // label: 'company_details.general.city_label',
            placeholder: 'company_details.general.city_placeholder',
            width: '60%',
            group: 'field-row-next',
        },
        {
            type: 'text',
            name: 'country',
            // label: 'company_details.general.country_label',
            placeholder: 'company_details.general.country_placeholder',
        },
        {
            type: 'header',
            header: 'company_details.general.contact_information_header'
        },
        {
            type: 'email',
            name: 'email',
            // label: 'company_details.general.email_label',
            placeholder: 'company_details.general.email_placeholder',
        },
        {
            type: 'text',
            name: 'phone_number',
            // label: 'company_details.general.phone_number_label',
            placeholder: 'company_details.general.phone_number_placeholder',
        },
        {
            type: 'text',
            name: 'website',
            // label: 'company_details.general.website_label',
            placeholder: 'company_details.general.website_placeholder',
        },
        {
            type: 'header',
            header: 'company_details.general.financial_details_header'
        },
        {
            type: 'text',
            name: 'coc_number',
            // label: 'company_details.general.kvk_number_label',
            placeholder: 'company_details.general.kvk_number_placeholder',
        },
        {
            type: 'text',
            name: 'vat_number',
            // label: 'company_details.general.vat_number_label',
            placeholder: 'company_details.general.vat_number_placeholder',
        },
        {
            type: 'text',
            name: 'iban_number',
            // label: 'company_details.general.iban_number_label',
            placeholder: 'company_details.general.iban_number_placeholder',
        }
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'general.save',
            position: 'left',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'small',
            showLoader: true,
        },
    ];

    return (
        <InlineForm
            viewKey={viewKey!}
            itemStr={environmentHash}
            apiObject={apiObject}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default CompanyDetailsForm;