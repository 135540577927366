import React, { useState, useContext, useEffect } from 'react';
import { PhoneNumbersFieldsetType, FieldData, FieldOption } from 'types/FieldTypes';
import { PhoneNumberType, createEmptyPhoneNumber } from 'types/FieldsetTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { formValidation } from 'services/utils/formValidation';
import CustomDropdown from '../fields/CustomDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

interface PhoneNumberFieldsetProps extends PhoneNumbersFieldsetType {
    object?: string;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
    handleFieldSubmit?: boolean;
    fieldSubmitted?: () => void;
    refetchData?: () => void;
}

const PhoneNumbersFieldset: React.FC<PhoneNumberFieldsetProps & { data: FieldData, viewKey: string }> = ({
    viewKey, name, object, data, helperText, viewInEditMode, alignment, handleFieldSubmit, fieldSubmitted, 
    refetchData, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setEditing, updatedData, setButtonLoader, setUpdatedData, showErrorAlert, setShowErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges, setFloatingAlert } = useGlobalContext();
    const hasRightCheck = useAllowedRight;
    const phoneNumberTypes = [ { value: 'work', name: t('phone.type.work')}, { value: 'home', name: t('phone.type.home')}, { value: 'mobile', name: t('phone.type.mobile')}, { value: 'other', name: t('phone.type.other')} ];
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<(FieldOption | null)[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // If there are already phone numbers, set the current values in the fields
    useEffect(() => {
        if (data && data.phone_numbers && data.phone_numbers.length > 0) {
            // Set the phone numbers
            const extractedPhoneNumbers = data.phone_numbers.map((phoneNumber: any) => ({
                id: phoneNumber.id,
                phone_number: phoneNumber.phone_number,
                phone_type: phoneNumber.phone_type,
                organization: phoneNumber.organization,
                person: phoneNumber.person,
                deleted: phoneNumber.deleted,
                environment: phoneNumber.environment
            }));
            setPhoneNumbers(extractedPhoneNumbers)

            // Retrieve the phone types from any phone number
            const PhoneTypes = data.phone_numbers.map((phone: PhoneNumberType) => {
                const PhoneType = phoneNumberTypes.find(option => option.value === phone.phone_type);
                return PhoneType || null;
            });
            setSelectedOptions(PhoneTypes);
        // If there are no phone numbers, create an empty row
        } else if (editing || viewInEditMode) {
            if (object === 'organization' || object === 'person') {
                const emptyPhoneNumber = createEmptyPhoneNumber(object, data);
                setPhoneNumbers([emptyPhoneNumber]);
                setSelectedOptions([phoneNumberTypes.find(option => option.value === "work") || null]);
            }            
        }
    }, [data]);

    // Helper function to update the phone numbers inside the updated data
    const updatePhoneInUpdatedData = (phone: any, index: number) => {
        const updatedPhonesArray = Array.isArray(updatedData['phone_numbers']) 
            ? [...updatedData['phone_numbers']] 
            : [];
        updatedPhonesArray[index] = phone;
        setUpdatedData({ ...updatedData, 'phone_numbers': updatedPhonesArray });
    };

    // Handle changes in the phone number field
    const handlePhoneNumberChange = (e: any, index: any) => {
        setUnsavedChanges(viewKey, true);
        const updatedNumbers = [...phoneNumbers];
        updatedNumbers[index].phone_number = e.target.value;
        setPhoneNumbers(updatedNumbers);
        updatePhoneInUpdatedData(updatedNumbers[index], index);
    };

    // Handle changes in the phone type field
    const handlePhoneTypeChange = (selectedOption: string, index: number) => {
        setUnsavedChanges(viewKey, true);
        const PhoneType = phoneNumberTypes.find(option => option.value === selectedOption);
        if (PhoneType) {
            // Update the updated data to send to the server
            const updatedNumbers = [...phoneNumbers];
            updatedNumbers[index].phone_type = PhoneType.value as 'work' | 'home' | 'mobile' | 'other';
            setPhoneNumbers(updatedNumbers);
            updatePhoneInUpdatedData(updatedNumbers[index], index);

            // Update the selected option to show in the field
            const updatedOptions = [...selectedOptions];
            updatedOptions[index] = PhoneType;
            setSelectedOptions(updatedOptions);
        }
    };

    // Handle deletion of a phone number
    const handleDeletePhoneNumber = (phoneNumberId?: number, index?: number) => {
        let phoneNumberToDelete: any;
    
        // If the phone number is already known in the database, use the provided id
        if (phoneNumberId !== undefined) {
            phoneNumberToDelete = phoneNumbers.find(item => item.id === phoneNumberId);
        // If it's a newly added phone number, use the index
        } else if (index !== undefined) {
            phoneNumberToDelete = phoneNumbers[index];
        }
    
        if (phoneNumberToDelete) {
            // Set the deleted property to true
            phoneNumberToDelete.deleted = true;
            
            // Set the deleted phone number in the updated data so that we can update the deleted boolean in the database
            const updatedPhones = Array.isArray(updatedData['phone_numbers']) ? [...updatedData['phone_numbers']] : [];
            if (phoneNumberToDelete.id) {
                updatePhoneInUpdatedData(phoneNumberToDelete, updatedPhones.length);
            // If the phone number is a newly added one, remove it from the updated data
            } else {
                const indexToRemove = updatedPhones.findIndex(item => item === phoneNumberToDelete);
                if (indexToRemove > -1) {
                    updatedPhones.splice(indexToRemove, 1);
                }
                setUpdatedData({ ...updatedData, phone_numbers: updatedPhones });
            }
            
            // Remove the row from the form
            const newPhoneNumbersList = phoneNumbers.filter(item => item !== phoneNumberToDelete);
            setPhoneNumbers(newPhoneNumbersList);
        }
    };
    
    // Handle the creation of a new phone number row
    const handleAddPhoneNumber = () => {
        if (object === 'organization' || object === 'person') {
            // Create a new phone number row
            const newPhoneNumber = createEmptyPhoneNumber(object, data);
            const updatedPhoneNumbers = [...phoneNumbers, newPhoneNumber];
            setPhoneNumbers(updatedPhoneNumbers);

            // Set the default phone type for the newly created row and set it in the updated data
            const newSelectedOption = phoneNumberTypes.find(option => option.value === "work") || null;
            const updatedSelectedOptions = [...selectedOptions, newSelectedOption];
            setSelectedOptions(updatedSelectedOptions);
            updatePhoneInUpdatedData(newPhoneNumber, updatedPhoneNumbers.length - 1);
        }
    }

    const handleFieldSubmitFunction = async() => {
        try {
            if (setButtonLoader) setButtonLoader(true);

            // Validate the phone numbers before submitting
            const validationErrors = phoneNumbers.map((phoneNumber) => formValidation('phonenumber-widget', {phone_number: phoneNumber.phone_number}));
            if (validationErrors.some(error => error !== null)) { setErrorMessages(validationErrors); return };

            // Patch the phone numbers and refetch the data after saving
            await saveData({ apiUrl: 'patch_phonenumber_batch', method: 'patch', data: updatedData });
            if (refetchData) await refetchData();

            // Clear the updatedData to prevent resubmitting and duplicate items
            setUpdatedData({});

            // Clear the errors, show a success alert, set unsaved changes to false and close the edit mode
            setErrorMessages({});
            setFloatingAlert({ type: 'success' })
            setUnsavedChanges(viewKey, false);
            if (setEditing) setEditing(false);
        } catch (error) {
            const fieldNames = ['phone_number', 'phone_type'];
            const errorData = handleSaveErrors(error, fieldNames); 
            if (setShowErrorAlert) setShowErrorAlert(true)
            setErrorMessages(errorData);
            setUnsavedChanges(viewKey, true);
        } finally {
            if (setButtonLoader) setButtonLoader(false);
            // If the submitting is finished, reset the submitting status in the parent component
            if (fieldSubmitted) fieldSubmitted();
        }
    }

    useEffect(() => {
        if (handleFieldSubmit === true) handleFieldSubmitFunction();
    }, [handleFieldSubmit]);

    // Submits the widget field after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!errorMessages['phone_number']) {
                handleFieldSubmitFunction();
            }
        }
    }

    // Formats the phone type name
    const getPhoneTypeName = (phoneTypeValue: string) => {
        const phoneTypeObj = phoneNumberTypes.find(type => type.value === phoneTypeValue);
        return phoneTypeObj ? phoneTypeObj.name : '';
    };

    const content = (
        <>
            <label htmlFor={name}>
                {t('phone.general.phone_label')}
                {alignment === 'horizontal' && !editing && !viewInEditMode && 
                    <span className='edit-icon'>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                }
            </label>
            <div>
                {showErrorAlert &&
                    <div className="alert form-alert alert-danger" role="alert">
                        {t(errorMessages.general, { defaultValue: errorMessages.general })}
                    </div>
                }
                {(editing || viewInEditMode) ? (
                    // Edit mode
                    <div className='edit-mode'>
                        {phoneNumbers.map((phoneNumber, index) => (
                            <div key={index} className='list-item'>
                                <div className='two-fields'>
                                    <input 
                                        type="text"
                                        id={`phone_number_${index}`}
                                        name={`phone_number_${index}`}
                                        value={phoneNumber.phone_number}
                                        onChange={e => handlePhoneNumberChange(e, index)}
                                        placeholder={t('phone.general.phone_placeholder')}
                                        onKeyDown={handleKeyDown}
                                        autoFocus={true}
                                        className={errorMessages[index]?.phone_number ? 'is-invalid' : ''}
                                        style={{ marginRight: '0.3rem' }} />
                                    <CustomDropdown
                                        options={phoneNumberTypes}
                                        id={`phone_type_${index}`}
                                        name={`phone_type_${index}`}
                                        selectedOption={selectedOptions[index] || null}
                                        value={phoneNumber.phone_type}
                                        onChange={(selectedType) => handlePhoneTypeChange(selectedType, index)}
                                        optionFormat={(option) => `${option.name}`}
                                        allowNoneOption={false}                                
                                    />
                                    <div className='delete-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => handleDeletePhoneNumber(phoneNumber.id, index)} />
                                    </div>
                                </div>
                                {errorMessages[index]?.phone_number && 
                                    <div className='error-message'>
                                        {t(errorMessages[index]?.phone_number, { defaultValue: errorMessages[index]?.phone_number })}
                                    </div>
                                }
                            </div>
                        ))}
                        <div onClick={(e) => {e.preventDefault(); handleAddPhoneNumber(); }} 
                            className="add-new-button">
                            {t('forms.add_new')}
                        </div>
                        {helperText && 
                            <div className="helper-text">
                                {t(helperText)}
                            </div>
                        }
                    </div>
                ) : (
                    // View mode
                    <div className="view-mode">
                        <span className='p'>
                            {phoneNumbers && phoneNumbers.length > 0 ? (
                                <>
                                    <div className='item-list'>
                                        {phoneNumbers.map((phoneNumber, index) => (
                                            <p key={index}>
                                                <a href={`tel:${phoneNumber.phone_number}`}
                                                className='item-link'
                                                onClick={(e) => e.stopPropagation()}>
                                                    {phoneNumber.phone_number}
                                                </a>
                                                {phoneNumber.phone_type ? <span className='type'> ({getPhoneTypeName(phoneNumber.phone_type)})</span> : ''}
                                            </p>
                                        ))}
                                    </div>
                                    {alignment === 'stacked' &&
                                        <span className='edit-icon'>
                                            <FontAwesomeIcon icon={faPen} />
                                        </span>
                                    }
                                </>
                            ) : (
                                <span className="no-value">
                                    <span className='minus-sign'>-</span>
                                    <span className="hover-text">{t('forms.add_value')}</span>
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </>
    )

    return (
        <div className='widget-field'>
            { alignment === 'horizontal' 
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
}

export default PhoneNumbersFieldset;