import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import PersonalPreferencesForm from './PersonalPreferencesForm';
import PageHeader from 'components/layout/PageHeader';
import '../../style/scss/navigation.scss';

const PersonalPreferencesPage: React.FC = () => {
    const { userHash } = useAuthContext();
    const viewKey = useMemo(() => uuidv4(), []);

    return (
        <div className='container-center align-center'>
            <div className='width-100'>
                <PageHeader title='nav.personal_preferences_label' />
                <div className='container-filled'>
                    <div className='section'>
                        <div className='large-width align-center'>
                            <PersonalPreferencesForm
                                userHash={userHash} 
                                viewKey={viewKey} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalPreferencesPage;