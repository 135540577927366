import { startOfWeek, endOfWeek, subWeeks, startOfMonth, endOfMonth, subMonths, format } from 'date-fns';

// Helper function to determine period dates
export const getPeriodDates = (periodType: 'week' | 'month') => {
    const currentDate = new Date();
    let startCurrent, endCurrent, startPrevious, endPrevious;

    // Determine the dates for widgets with week period view
    if (periodType === 'week') {
        startCurrent = startOfWeek(currentDate, { weekStartsOn: 1 });
        endCurrent = endOfWeek(currentDate, { weekStartsOn: 1 });
        startPrevious = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });
        endPrevious = endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });

    // Determine the dates for widgets with month period view
    } else if (periodType === 'month') {
        startCurrent = startOfMonth(currentDate);
        endCurrent = endOfMonth(currentDate);
        startPrevious = startOfMonth(subMonths(currentDate, 1));
        endPrevious = endOfMonth(subMonths(currentDate, 1));
    }

    // Check if any date is undefined and handle it
    if (!startCurrent || !endCurrent || !startPrevious || !endPrevious) {
        throw new Error('Failed to calculate period dates');
    }

    // Convert date objects to strings in the correct backend format 'yy-mm-dd'
    const formattedStartCurrent = convertDateToDateStringWithoutTimezoneConversion(startCurrent);
    const formattedEndCurrent = convertDateToDateStringWithoutTimezoneConversion(endCurrent);
    const formattedStartPrevious = convertDateToDateStringWithoutTimezoneConversion(startPrevious);
    const formattedEndPrevious = convertDateToDateStringWithoutTimezoneConversion(endPrevious);

    return {
        currentPeriod: { from: formattedStartCurrent, to: formattedEndCurrent },
        previousPeriod: { from: formattedStartPrevious, to: formattedEndPrevious }
    };
};

// Convert JavaScript date objects to a date string without timezone conversion
const convertDateToDateStringWithoutTimezoneConversion = (date: Date): string => {
    // Add 0 prefix for months/days below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the year, month and day from the date object
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-indexed in JavaScript
    const day = pad(date.getDate());

    // Return as formatted iso date string
    return `${year}-${month}-${day}`
}