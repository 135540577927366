import React, { useMemo } from 'react';
import InlineForm from 'components/forms/InlineForm';
import { FieldType } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { v4 as uuidv4 } from 'uuid';

const TwoFactorForm: React.FC = () => {
    const viewKey = useMemo(() => uuidv4(), []);

    const formDescription = 'account.two_factor_header_description'

    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'twofactor-code',
            placeholder: 'account.two_factor_placeholder',
        },
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'account.login_button_label',
            position: 'fullwidth',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'large',
            showLoader: true,
        },
    ];

    return (
        <InlineForm
            viewKey={viewKey}
            apiObject='twofactorverification'
            formDescription={formDescription}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default TwoFactorForm;