import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';

/*
 * saveBatchData.tsx
 * General utility function that handles saving of multiple data items to the server.
 * The function generates a put request to update all items at once. It also generates 
 * a cancel token that cancels requests from canceled submissions, which prevents 
 * unwanted server requests.
 */

interface SaveBatchDataProps {
    apiObject: string;
    apiUrl?: string;
    method?: 'put' | 'patch';
    data: Record<string, any>[] | Record<string, any>[];
    source?: CancelTokenSource;
}

export async function saveBatchData({ apiObject, apiUrl, method = 'put', data, source }: SaveBatchDataProps) {

    // Retrieve the csrfToken
    const csrfToken = getCsrfToken();

    // Determine the URL based on whether apiUrl is provided or not
    const url = apiUrl 
        ? `${apiBaseUrl}/${apiUrl}/`
        : `${apiBaseUrl}/put_${apiObject}_batch/`;

    // Configure the API request
    const apiRequestConfig = {
        url,
        method,
        data: data.map(item => ({
            ...item
        })),
        withCredentials: true,
        headers: {
            'X-CSRFToken': csrfToken,
        },
        cancelToken: source?.token,
    };

    // Save the data to the server
    try {
        const response = await axios(apiRequestConfig);
        return response;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error('Saving not possible', error);
            throw error;
        }
    }
}