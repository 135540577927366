import React from 'react';
import '../../style/scss/tooltip.scss';

interface TooltipProps {
    children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ children }) => {
    return (
        <div className="tooltip">
            {children}
        </div>
    );
};

export default Tooltip;