import React from 'react';
import { t } from 'i18next';

// Render labels as badges
export const renderLabelBadges = (labels: { id: number, name: string, color: string }[]) => {
    return (
        <div className='badge-container'>
            {labels.map(label => (
                <div key={label.id} className={`label-badge ${label.color}`}>
                    {label.name}
                </div>
            ))}
        </div>
    );
}

// Render status as badges
export const renderStatusBadge = (name: string, condition: string) => {
    return (
        <div className='badge-container'>
            <div className={`status-badge ${condition}`}>
                {t(`workflow.default_status_names.${name}`)}
            </div>                        
        </div>
    );
}