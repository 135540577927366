import React, { useState, useEffect, useContext } from 'react';
import { FieldData, ReferenceMultiFieldType } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from '../fields/elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const ReferenceMultiField: React.FC<ReferenceMultiFieldType & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, viewInEditMode, alignment, isEditable, disabled
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [internalReference, setInternalReference] = useState<string>('');
    const [externalReference, setExternalReference] = useState<string>('');
    
    // Load the current values inside the fields
    useEffect(() => {
        // Set the internal reference from the data
        if (data && data[`internal_${name}`]) {
            setInternalReference(data[`internal_${name}`]);
        }

        // Set the external reference from the data
        if (data && data[`external_${name}`]) {
            setExternalReference(data[`external_${name}`]);
        }
    }, [data, name]);

    // Handle the input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: 'internal_reference' | 'external_reference') => {

        // Set the value
        if (fieldName == 'internal_reference') setInternalReference(e.target.value);
        if (fieldName == 'external_reference') setExternalReference(e.target.value);

        // Set updated data
        setUpdatedData((prevData: Record<string, any>) => ({
            ...updatedData,
            ...prevData,
            [fieldName]: e.target.value
        }));

        // Set unsaved changes
        const hasChanges = e.target.value !== data[fieldName];
        setUnsavedChanges(viewKey, hasChanges);
    }

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name && !errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Combine both references to show in the view mode
    const combinedReference = () => {
        let combinedReference = '';
        
        if (internalReference && externalReference) {
            combinedReference = `${internalReference} • ${externalReference}`;
        } else if (internalReference) {
            combinedReference = internalReference;
        } else if (externalReference) {
            combinedReference = externalReference;
        }
    
        return combinedReference;
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='list-item'>
                    <div>
                        <label htmlFor={name}>
                            {t('job.general.internal_reference_label')}
                        </label>
                        <input
                            type="text"
                            id='internal_reference'
                            name='internal_reference'
                            placeholder={t('job.general.internal_reference_placeholder')}
                            value={internalReference}
                            disabled={disabled}
                            onChange={(e) => handleInputChange(e, 'internal_reference')}
                            className={`text ${errorMessages[name] ? 'is-invalid' : ''}`}
                            autoFocus={true}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div>
                        <label htmlFor={name}>
                            {t('job.general.external_reference_label')}
                        </label>
                        <input
                            type="text"
                            id='external_reference'
                            name='external_reference'
                            placeholder={t('job.general.external_reference_placeholder')}
                            value={externalReference}
                            disabled={disabled}
                            onChange={(e) => handleInputChange(e, 'external_reference')}
                            className={`text ${errorMessages[name] ? 'is-invalid' : ''}`}
                            autoFocus={false}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    type="data"
                    value={combinedReference()} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default ReferenceMultiField;