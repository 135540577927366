import React from 'react';
import { useTranslation } from 'react-i18next';
import { statusFeatureConfig } from './dashboardConfig';
import '../../style/scss/dashboard.scss';

export type FeatureStatusType = {
    id: number;
    status_condition: string;
    status_name?: string;
    budget?: string; // To be replaced by 'invoiced amount' or so (?) --> so the real amount of the item
};

export type FeatureStatusesType = {
    [featureKey: string]: FeatureStatusType[];
};

const WorkflowBar: React.FC<{ featureStatuses: FeatureStatusesType }> = ({ featureStatuses }) => {
    const { t } = useTranslation();

    // Function to group the features statuses by status condition
    const groupByStatusCondition = (data: FeatureStatusType[]) => {
        return data.reduce((acc, item) => {
            if (!acc[item.status_condition]) {
                acc[item.status_condition] = [];
            }
            acc[item.status_condition].push(item);
            return acc;
        }, {} as Record<string, FeatureStatusType[]>);
    };

    // Calculate the widths of the workflow bar segments
    const totalItems = Object.keys(statusFeatureConfig).reduce((sum, key) => sum + (featureStatuses[key]?.length || 0), 0);
    const widths = Object.keys(statusFeatureConfig).reduce((acc, key) => {
        acc[key] = ((featureStatuses[key]?.length || 0) / totalItems) * 100;
        return acc;
    }, {} as Record<string, number>);

    // Function to set the data categories to show their labels underneath the workflow bar
    const dataCategories = Object.keys(statusFeatureConfig).map(key => ({
        data: featureStatuses[key] || [],
        width: widths[key],
        label: `dashboard.general.todos_${key}_title`
    }));

    // Determine the amount of active categories. Only show the feature labels if more or equal then two active categories
    const activeCategories = dataCategories.filter(category => category.data.length > 0);

    return (
        <div className='workflow-section'>
            <h5>{t('dashboard.general.workflow_title')}</h5>
            <div className='workflow-bar'>
                {totalItems > 0 ? (
                    Object.keys(statusFeatureConfig).map(key => {
                        const data = featureStatuses[key] || [];
                        const groupedData = groupByStatusCondition(data);
                        return (
                            <div key={key} className='workflow-bar-segment segment' style={{ width: `${widths[key]}%` }}>
                                {Object.entries(groupedData).map(([status_condition, items]) => {
                                    const segmentWidth = (items.length / data.length) * 100;
                                    return (
                                        <div key={status_condition} className={`${status_condition} tooltip-icon`} style={{ width: `${segmentWidth}%` }}>
                                            <span className="tooltip">
                                                {t(`workflow.default_status_names.${items[0]?.status_name}`)} ({items.length})
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                    // Placeholder bar if there are no jobs
                    <div className='workflow-bar-segment segment' style={{ width: '100%' }}>
                        <div className='planned tooltip-icon'>
                            <span className="tooltip">
                                {t(`workflow.general.no_workflow_items_tooltipText`)}
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div className='feature-labels'>
                {activeCategories.length >= 2 && activeCategories.map(category => (
                    // Show feature labels if there are more than 2 active categories
                    <div key={category.label} style={{ width: `${category.width}%` }}>
                        <span className='label'>{t(category.label)}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WorkflowBar;