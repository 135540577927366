import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { filterReleasedFeatures } from 'services/permissions/releasedFeatures';
import { componentMapping, settingsConfig } from '../../views/settings/SettingsConfig';
import PageHeader from 'components/layout/PageHeader';

const SettingsPage: React.FC = () => {
    const location = useLocation();
    const { packageFeatures } = useAuthContext();
    const currentPath = location.pathname;

    // Filter the package features to include only released features
    const releasedFeature = filterReleasedFeatures(packageFeatures);

    // Find the right configuration based on the current route
    let currentPageConfig;
    for (const group of Object.values(settingsConfig)) {
        const foundItem = group.items.find(item => item.route === currentPath);
        if (foundItem) {
            currentPageConfig = foundItem;
            break;
        }
    }

    if (!currentPageConfig) {
        // No settings page configured for the current route
        return null;
    }

    // Render all pageHeaders for the current page
    return (
        <>
            {currentPageConfig.pageHeader?.map((headerConfig, index) => {
                // Check if the feature is released and included in the package
                const isReleased = headerConfig.requiredFeature 
                    ? filterReleasedFeatures([headerConfig.requiredFeature]).length > 0 
                    : true;

                // Don't render the pageheader if the feature is not released
                if (!isReleased) {
                    return null;
                }

                // Check if the pageheader is disabled when the required feature is not included in the environment package
                const isDisabled = headerConfig.requiredFeature 
                    ? !packageFeatures.includes(headerConfig.requiredFeature) 
                    : false;

                // Configure the tabs of a pageheader
                const tabs = headerConfig.tabs?.map(tab => {
                    if (typeof tab.componentName === 'string' && tab.componentName in componentMapping) {
                        const Component = componentMapping[tab.componentName as keyof typeof componentMapping];
                        return {
                            ...tab,
                            component: Component ? <Component key={tab.componentName} /> : null
                        };
                    }
                    return {
                        ...tab,
                        component: null
                    };
                }) || [];

                return (
                    <PageHeader
                        key={index}
                        tabs={tabs}
                        title={headerConfig.title}
                        explanationText={headerConfig.explanationText}
                        explanationImage={headerConfig.explanationImage}
                        explanationVideoUrl={headerConfig.explanationVideoUrl}
                        switchButton={headerConfig.switchButton}
                        switchButtonApiUrl='patch_environment_feature'
                        requiredFeature={headerConfig.requiredFeature}
                        disabled={isDisabled}
                    />
                );
            })}
        </>
    );
};

export default SettingsPage;