import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const JobForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'job'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'searchselect', 
            name: 'client',
            label: 'job.general.client_label',
            placeholder: 'job.general.client_placeholder',
            objectName: 'client.general.object_name.singular',
            query: {
                // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                endpoint: 'get_contact_search',
                params: {
                    contact_type: ['contact', 'prospect', 'client'] 
                }
            },
            postEndpoint: 'post_contact',
            selectionFormat: 'name',
            optionFormat: {
                title: { field: 'name', format: (value) => value },
                subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
            },
        },
        { 
            type: 'searchselect', 
            name: 'work_location',
            label: 'job.general.work_location_label',
            placeholder: 'job.general.work_location_placeholder',
            objectName: 'location.general.object_name.singular',
            query: {
                // Get all active locations
                endpoint: 'get_location_search',
            },
            postEndpoint: 'post_location',
            selectionFormat: 'address',
            optionFormat: {
                title: { field: 'address', format: (value) => value },
            },
        },
        {
            type: 'header',
        },
        {
            type: 'text',
            name: 'internal_reference',
            label: 'job.general.internal_reference_label',
            placeholder: 'job.general.internal_reference_placeholder',
            tooltipText: 'job.general.internal_reference_tooltip',
        },
        {
            type: 'textarea',
            name: 'main_description',
            label: 'job.general.main_description_label',
            rows: 3,
            placeholder: 'job.general.main_description_placeholder',
        },
        {
            type: 'header',
        },
        { 
            type: 'dropdown', 
            name: 'scheduling_type', 
            label: 'job.general.schedule_label',
            disabled_selected: 'job.general.schedule_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'job_storage', name: 'job.general.add_to_job_storage_option'},
                { value: 'schedule', name: 'job.general.schedule_immediately_option'},
            ],
        },
        {
            type: 'date',
            name: 'target_date',
            placeholder: 'job.general.target_date_placeholder',
            dependentOn: { fieldName: 'scheduling_type', value: 'job_storage' },
            width: '50%',
            group: 'field-row',
        },
        {
            type: 'number',
            name: 'estimated_duration',
            placeholder: 'job.general.estimated_duration_placeholder',
            dependentOn: { fieldName: 'scheduling_type', value: 'job_storage' },
            width: '50%',
            group: 'field-row',
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}       
        />
    );
};

export default JobForm;