import { useEffect, useState } from 'react';

/*
 * useAdjustRowsSize.ts
 * A custom hook to use in the scheduling component to maximize
 * the screen height or width.
 */

const useAdjustRowsSize = (
    schedulingBoardRef: React.RefObject<HTMLDivElement>, 
    calendarRef: React.RefObject<HTMLDivElement>,
    viewDirection: string
) => {
    const [resourceSize, setResourceSize] = useState<number | undefined>(undefined);

    useEffect(() => {
        const adjustResourceRowsSize = () => {

            // Adjust row height for stacked view
            if (viewDirection === 'stacked') {
            
                // Get the height of the window of the user
                const windowHeight = window.innerHeight;
            
                // Get the navbar height
                const navBar = document.querySelector('.navbar') as HTMLElement;
                const subNavBar = document.querySelector('.subnavbar') as HTMLElement;
                const navBarHeight = navBar ? navBar.offsetHeight : 0;
                const subNavBarHeight = subNavBar ? subNavBar.offsetHeight : 0;
            
                // Get the height of the page header and scheduling header
                const mainElement = document.querySelector('main') as HTMLElement;
                const mainElementMarginTop = document.querySelector('main') ? parseInt(window.getComputedStyle(mainElement).marginTop) : 0;
                const pageHeader = document.querySelector('.scheduling-header') as HTMLElement;
                const pageHeaderMarginBottom = pageHeader ? parseInt(window.getComputedStyle(pageHeader).marginBottom) : 0;
                const boardHeader = document.querySelector('.scheduling-board .header') as HTMLElement;
                const toScheduleRow = document.querySelector('.scheduling-board .unassigned-row') as HTMLElement;
                
                const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight + pageHeaderMarginBottom : 0;
                const boardHeaderHeight = boardHeader ? boardHeader.offsetHeight : 0;
                const toScheduleRowHeight = toScheduleRow ? toScheduleRow.offsetHeight : 0;
                
                // Get the number of resource rows
                const resourceRows = document.querySelectorAll('.scheduling-board .adjust-height');
            
                // Calculate the height of resource rows borders (1px per resource row)
                const totalBorderHeight = resourceRows.length * 1;
            
                // Calculate the height above the resource rows
                const estimatedOtherElementsHeight = navBarHeight + subNavBarHeight + pageHeaderHeight + boardHeaderHeight + toScheduleRowHeight + mainElementMarginTop + totalBorderHeight;
        
                // Calculate the height available for the scheduling rows
                const totalHeightAvailable = windowHeight - estimatedOtherElementsHeight;
                
                // Devide the available height per row
                if (resourceRows.length > 0){
                    const heightPerRow = (totalHeightAvailable / resourceRows.length);

                    // Reset unassigned row width (if set in columns direction adjust width function)
                    const unassignedRow = document.querySelector('.scheduling-board .unassigned-row');
                    if (unassignedRow) {
                        (unassignedRow as HTMLElement).style.width = 'auto'
                    };
            
                    resourceRows.forEach(row => {
                        const element = row as HTMLElement;

                        // Reset resource rows width (if set in columns direction adjust width function)
                        element.style.width = 'auto';

                        // Set the height of the rows
                        element.style.height = `${heightPerRow}px`;
                    });

                    setResourceSize(heightPerRow);
                }

            // Adjust row width for columns view
            } else if (viewDirection === 'columns') {

                // Get the total calendar width
                const calendarWidth = calendarRef.current?.offsetWidth || 0;

                // Set the header width + 1 px border
                const headerWidth = 48 + 1;

                // Get the rows to set the adjustable width for
                const resourceRows = document.querySelectorAll('.scheduling-board .adjust-width');

                // Calculate the width of resource rows borders (1px per resource row)
                const borderWidth = resourceRows.length * 1;

                // Calculate the total width to be used by the rows
                const totalAvailableWidth = calendarWidth - headerWidth - borderWidth;

                // Set the minimal row width to 200px
                const minRowWidth = 200;

                // Determine the number of rows which need to scale
                const numberOfRows = resourceRows.length;

                // Define the width per row
                if (resourceRows.length > 0) {
                    const widthPerRow = Math.max(totalAvailableWidth / numberOfRows, minRowWidth);

                    resourceRows.forEach(row => {
                        const element = row as HTMLElement;

                        // Reset resource rows height (if set in stacked direction adjust height function)
                        element.style.height = 'auto';

                        // Set the width of the rows
                        element.style.width = `${widthPerRow}px`;
                    });

                    setResourceSize(widthPerRow);
                }
            }
        };
    
        // Create the mutation observer instance
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    adjustResourceRowsSize();
                }
            });
        });

        // Start the observer
        if (schedulingBoardRef.current) {
            observer.observe(schedulingBoardRef.current, { childList: true, subtree: true, attributes: true });
        }
        if (calendarRef.current) {
            observer.observe(calendarRef.current, { childList: true, subtree: true, attributes: true });
        }
    
        // Execute the adjustment of the row height
        adjustResourceRowsSize();
    
        // Clear on unmount
        return () => observer.disconnect();
    }, [schedulingBoardRef, calendarRef, viewDirection]);

    return resourceSize;
};

export default useAdjustRowsSize;