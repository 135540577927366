import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { ListProps, ListRowType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import '../../style/scss/settings.scss';

interface SettingsListType<T extends ListRowType> extends ListProps<T> {
    title?: string;
    activeFeature?: string;
    explanationText: string;
    explanationVideoUrl?: string;
}

const SettingsList = <T extends ListRowType>({
    title, activeFeature, explanationText, explanationVideoUrl, query, objectName, filters, columns, 
    showSearch, showDeleteOrDeactivate, showImport, showExport, showColumnSelector, onRowClick, formModal, 
    detailModal, wizardModal, detailPageUrl, modalSize, isPaginated, isDraggable
}: SettingsListType<T>) => {
    const { t } = useTranslation();
    const { activeFeatures } = useAuthContext();

    // Determine if the setting is disabled because it isn't an active feature
    const isSettingDisabled = activeFeature ? !activeFeatures.includes(activeFeature) : false;

    return (
        <div className='container-filled'>
            <div className='settings-list'>
                <div className='title'>
                    <h5>{t(title)}</h5>
                    {isSettingDisabled && (
                        <Link className='upgrade-badge' 
                            to="/settings/billing/plan">
                            {t('billing.upgrade_badge_label')}
                        </Link>
                    )}
                </div>
                <div className='explanation'>
                    {!explanationVideoUrl ? (
                        <p className='explanation-text'>
                            {t(explanationText)}
                        </p>
                    ) : (
                        <>
                            <div className='text'>
                                <p className='explanation-text'>
                                    {t(explanationText)}
                                </p>
                            </div>
                            <div className='video'>
                                {explanationVideoUrl}
                            </div>
                        </>
                    )}
                </div>
                <AbstractList
                    query={query}
                    objectName={objectName}
                    filters={filters}
                    columns={columns}
                    showSearch={showSearch}
                    showDeleteOrDeactivate={showDeleteOrDeactivate}
                    showImport={showImport}
                    showExport={showExport}
                    showColumnSelector={showColumnSelector}
                    isDraggable={isDraggable}
                    formModal={formModal}
                    isPaginated={isPaginated}
                    onRowClick={onRowClick}
                    detailModal={detailModal}
                    wizardModal={wizardModal}
                    detailPageUrl={detailPageUrl}
                    modalSize={modalSize}
                />
            </div>
        </div>
    )
}

export default SettingsList;