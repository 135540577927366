import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface PasswordFieldProps extends PasswordFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const PasswordField: React.FC<PasswordFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, placeholder, onChange, viewInEditMode, shouldAutoFocus,
    disabled, alignment, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState('');

    // Updates the value when the data is later received then the initialization of the useState
    useEffect(() => {
        if (data && data[name] !== undefined && data[name] !== value) {
            setValue(data[name]);
        }
    }, [data, name]);

    // Updates the value after changing the input
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
        setUnsavedChanges(viewKey, e.target.value !== data[name]);
        setUpdatedData({...updatedData, [name]: e.target.value});
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (handleSubmit) handleSubmit();
        }
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <input
                    type="password"
                    id={name}
                    name={name}
                    placeholder={t(placeholder)}
                    value={value}
                    disabled={disabled}
                    onChange={handleInputChange}
                    className={errorMessage ? 'text is-invalid' : 'text'}
                    autoFocus={shouldAutoFocus}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                // View mode
                <FieldViewMode
                    value={value} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default PasswordField;