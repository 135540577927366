export interface AttachmentTypeType {
    id: number;
    name: string;
    ordering: number;
    deleted: boolean;
}

export interface AttachmentType {
    created?: string | null;
    id: number | null;
    uuid?: string;
    file: string | null;
    filename: string | null;
    attachment_type: number | null;
    proposal: number | null;
    job: number | null;
    invoice: number | null;
    contact: number | null;
    location: number | null;
    visibility: string;
    deleted: boolean;
}

export const createNewAttachment = ({ 
    id, 
    uuid, 
    file, 
    fileName, 
    proposalId,
    jobId,
    invoiceId, 
    contactId, 
    locationId 
}: { 
    id?: number | null, 
    uuid?: string, 
    file: string, 
    fileName: string, 
    proposalId?: number,
    jobId?: number,
    invoiceId?: number,
    contactId?: number,
    locationId?: number
}): AttachmentType => ({
    id: id ?? null,
    uuid: uuid,
    file: file ?? null,
    filename: fileName ?? null,
    attachment_type: null,
    proposal: proposalId ?? null,
    job: jobId ?? null,
    invoice: invoiceId ?? null,
    contact: contactId ?? null,
    location: locationId ?? null,
    visibility: 'office_and_field',
    deleted: false,
})

export interface AttachmentDetailListProps {
    detailPageId: string;
    detailPage: string;
}

export type AttachmentUpdateType = {
    filename?: string,
    attachment_type?: number | null,
    visibility?: string,
    deleted?: boolean,
}