import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import '../../style/scss/dashboard.scss';

const Greeting: React.FC = () => {
    const { t } = useTranslation();
    const { firstName } = useAuthContext();

    // Configure the greeting for the right time
    const configureGreeting = () => {
        const currentHour = new Date().getHours();

        if (currentHour < 4) {
            return 'date_time.greetings.night'
        } else if (currentHour < 12) {
            return 'date_time.greetings.morning'
        } else if (currentHour < 18) {
            return 'date_time.greetings.afternoon'
        } else if (currentHour < 24) {
            return 'date_time.greetings.evening'
        }
    };

    return (
        <>{t(configureGreeting(), { name: firstName })} 👋</>
    )
};

export default Greeting;