/*
 * dropdownBehavior.ts
 * General utility function that handles the behavior of the custom
 * dropdown and searchselect dropdowns. It takes care of the closing
 * of the dropdown when clicking next to it. It also ensures that the
 * dropdown a height of 90% of the available space and determines
 * whether the dropdown should be displayed at the top or bottom of
 * the screen. It also gives the dropdown a minimal width of 200px or
 * if the associated field is bigger gives it the width of the field.
 */

import { useEffect, MutableRefObject } from "react";

// Close the dropdown list when clicking outside the dropdown
export const useClickOutside = (
    isOpen: boolean, 
    dropdownRef: MutableRefObject<HTMLElement | null>, 
    setIsOpen: (isOpen: boolean) => void
) => {
    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [isOpen, dropdownRef, setIsOpen]);
};

// Set the dropdown width on open
export const useSetDropdownWidth = (
    isOpen: boolean, 
    dropdownListRef: MutableRefObject<HTMLElement | null>, 
    fieldRef: MutableRefObject<HTMLElement | null>, 
    setDropdownWidth: (width: number) => void
) => {
    useEffect(() => {
        if (isOpen && dropdownListRef.current && fieldRef.current) {
            const optionsWidth = dropdownListRef.current.offsetWidth;
            const fieldWidth = fieldRef.current.offsetWidth;

            if (fieldWidth < 200) {
                setDropdownWidth(optionsWidth);
            } else {
        		setDropdownWidth(fieldWidth);
      		}
    	}
  }, [isOpen, dropdownListRef, fieldRef, setDropdownWidth]);
};

// Set the dropdown position (above/below field) and max height on open
export const useSetDropdownPosition = (
  	isOpen: boolean, 
  	fieldRef: MutableRefObject<HTMLElement | null>, 
  	setDropdownAbove: (dropdownAbove: boolean) => void, 
  	setDropdownMaxHeight: (maxHeight: number) => void,
    dropdownHeight?: number
) => {
  	useEffect(() => {
    	if (isOpen && fieldRef.current) {
      		const fieldRect = fieldRef.current.getBoundingClientRect();
      		const spaceAbove = fieldRect.top;
      		const spaceBelow = window.innerHeight - fieldRect.bottom;

            // If a dropdown height is given
            if (dropdownHeight) {
                // If the dropdown doesn't fit below, set it above
                if (dropdownHeight > spaceBelow && dropdownHeight <= spaceAbove) {
                    setDropdownAbove(true);
                // If it fits below, put it there
                } else {
                    setDropdownAbove(false);
                }
            // If no dropdown height is given
            } else {
                setDropdownAbove(spaceAbove > spaceBelow);

                // Set the max height to be 90% of the available space
      		    const availableSpace = spaceAbove > spaceBelow ? spaceAbove : spaceBelow;
      		    setDropdownMaxHeight(availableSpace * 0.9);
            }
    	}
  	}, [isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight, dropdownHeight]);
};