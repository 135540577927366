import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from 'components/modals/ModalContext';
import { FieldViewModeType, ImageFieldViewMode, LinkFieldViewMode, PriceFieldViewMode } from 'types/FieldTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../../../../style/scss/live-edit.scss';
import '../../../../style/scss/forms.scss';
import '../../../../style/scss/tooltip.scss';

const FieldViewMode: React.FC<FieldViewModeType> = ({
    value, type = 'data', alignment = 'stacked', emptyLabel, ...props
}) => {   
    const { t, i18n } = useTranslation();
    const { userLocale } = useSettings();
    const { closeModal } = useModal();

    // Check if the value is a translation string or just show the data value
    const translateStringOrShowDataValue = (value: string | number | null) => {
        if (typeof value === 'string') {
            return i18n.exists(value) ? t(value) : value;
        }
        return value;
    };

    // Render the field view mode based on its type
    const renderFieldViewMode = () => {
        if (value) {
            switch (type) {
                case 'data':
                    // Show the value or the translated string
                    return (
                        <p>{translateStringOrShowDataValue(value)}</p>
                    );
                case 'textarea':
                    // Show the value as textarea and render the html
                    return (
                        <p className='textarea'
                           dangerouslySetInnerHTML={{ __html: value as string }} 
                        />
                    );
                case 'price': 
                    // Show the value as a price with it's currency
                    const { currencySymbol } = props as PriceFieldViewMode;

                    // Format the rate based on the user locale
                    const formattedPrice = value.toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    
                    return (
                        <p>{currencySymbol ? currencySymbol : null} {formattedPrice}</p>
                    )
                case 'link':
                    // Show a link to the connected object. If the link is null, just render the value
                    const { linkUrl } = props as LinkFieldViewMode;
                    if (linkUrl) {
                        return (
                            <span className="tooltip-icon">
                                <Link className='item-link' 
                                      to={linkUrl}
                                      onClick={e => closeModal()}>
                                    {value}
                                </Link>
                                <span className="tooltip">{t('forms.open_link')}</span>
                            </span>
                        );
                    } else {
                        return (
                            <p>{translateStringOrShowDataValue(value)}</p>
                        );
                    }
                case 'image':
                    // Show image preview
                    const { imageType } = props as ImageFieldViewMode;
                    return (
                        <div className={`image-${imageType}`}
                             style={{ backgroundImage: `url(${value})` }} 
                        />
                    );
                default:
                    return (
                        <p>{translateStringOrShowDataValue(value)}</p>
                    );
            }

        // If no value is given, show minus sign or empty value
        } else {
            return (
                <span className="no-value">
                    {emptyLabel ? 
                        <span className="empty-label">{t(emptyLabel)}</span>
                    : (
                        <>
                            <span className='minus-sign'>-</span>
                            <span className="hover-text">{t('forms.add_value')}</span>
                        </>
                    )}
                </span>
            );
        }
    }

    return (
        <div className="view-mode-wrapper">
            {renderFieldViewMode()}
            {alignment === 'stacked' &&
                <span className='edit-icon'>
                    <FontAwesomeIcon icon={faPen} />
                </span>
            }
        </div>
    );
};

export default FieldViewMode;