import React from 'react';
import LoginForm from './LoginForm';
import SecondaryButton from 'components/buttons/SecondaryButton';
import logoGrey from 'assets/crafter-logo-grey.png';
import '../../style/scss/account.scss';

const LoginPage = () => {
    return (
        <div className='container-center'>
            <div className='account-container'>
                <img src={logoGrey} alt="Logo" className='account-logo'/>
                <div className='account-form'>
                    <LoginForm />
                </div>
                <div className='account-buttons'>
                    <SecondaryButton 
                        label="account.password_forgotten_button_label"
                        size="small"
                        route="password-forgotten" />
                    <SecondaryButton 
                        label="account.no_account_yet_button_label"
                        size="small"
                        route="/create-account" />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;