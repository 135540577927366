import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import Cropper, { Point } from 'react-easy-crop';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import '../../../style/scss/image-editor.scss';

interface ImageCropType {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface ImageCropConfigType {
    aspectRatio: number; // Aspect ratio of the cropped image, for example 4/3, 3/4, 1, etc.
    cropShape: 'rect' | 'round'; // Format of the cropped image
    resize?: { width: number, height: number }; // Resizes the image to this size. Optionally
}

interface ImageEditorType {
    viewKey?: string;
    image: string;
    fileName: string;
    cropConfig: ImageCropConfigType;
    onCroppedImage: (croppedImageUrl: string, fileName: string) => (void);
}

const ImageEditor: React.FC<ImageEditorType> = ({ 
    viewKey, image, fileName, cropConfig, onCroppedImage 
}) => {
    const { t } = useTranslation()
    const { setUnsavedChanges } = useGlobalContext();
    const { revealModal, closeModal } = useModal();
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [finalCrop, setFinalCrop] = useState<ImageCropType>({ x: 0, y: 0, width: 0, height: 0 })
    const [zoom, setZoom] = useState<number>(1);
    const [buttonLoader, setButtonLoader] = useState(false);

    // Reveal modal on component mount
    useEffect(() => revealModal(), []);

    // Handle and update the crop position
    const onCropChange = (newCrop: Point) => {
        setUnsavedChanges(viewKey!, true);
        setCrop(newCrop);
    };

    // Handle and update the zoom position
    const onZoomChange = (newZoom: number) => {
        setUnsavedChanges(viewKey!, true);
        setZoom(newZoom);
    };

    // Crop the image based on the final crop position
    function getCroppedImg(imageInput: string, crop: ImageCropType): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageInput;
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Canvas context is not available'));
                    return;
                }
    
                // Set canvas size to the size of the cropped area
                canvas.width = crop.width;
                canvas.height = crop.height;
    
                // Draw the cropped section
                ctx.drawImage(
                    image,
                    crop.x, crop.y,
                    crop.width, crop.height,
                    0, 0,
                    crop.width, crop.height
                );

                const croppedDataUrl = canvas.toDataURL('image/png');
                resolve(croppedDataUrl);
            };
            image.onerror = () => {
                reject(new Error('Image loading error'));
            };
        });
    }

    // Resize the cropped image and convert to png to reduce filesize
    const resizeImage = (src: string, width: number, height: number): Promise<string> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error("Canvas context is not available"));
                    return;
                }
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = () => reject(new Error("Error loading image for resizing"));
            img.src = src;
        });
    };

    // Crop and optionally resize the image and return it to the parent component
    const returnImage = async () => {
        setButtonLoader(true);

        try {
            // Generate the cropped image
            const croppedImageUrl = await getCroppedImg(image, finalCrop);
            let finalImageUrl = croppedImageUrl;

            // Resize image if resize props are given
            if (cropConfig.resize) {
                finalImageUrl = await resizeImage(croppedImageUrl, cropConfig.resize.width, cropConfig.resize.height);
            }

            // Return the cropped image to the parent component
            onCroppedImage(finalImageUrl, fileName);
        } catch (error) {
            console.error("Error processing image:", error);
        } finally {
            // Stop button loader and close modal
            setButtonLoader(false); 
            closeModal();
        }
    };

    return (
        <div className='image-editor-modal'>
            <h4 className='header text-center'>{t('forms.image_cropper_header')}</h4>
            <div className='content'>
                <div className='edit-controls'>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropConfig.aspectRatio}
                        cropShape={cropConfig.cropShape}
                        showGrid={false}
                        onCropChange={onCropChange}
                        onZoomChange={onZoomChange}
                        onCropComplete={(croppedArea, croppedAreaPixels) => {
                            // Important to keep this console log here, otherwise cropping won't work...
                            console.log(croppedArea); 
                            setFinalCrop(croppedAreaPixels)
                        }} />
                </div>
                <div className="helper-text">
                    {t('forms.image_cropper_helpertext')}
                </div>
            </div>
            <div className='footer'>
                <SecondaryButton
                    onClick={closeModal} 
                    label="general.cancel"
                    size="small"/>
                <PrimaryButton
                    onClick={returnImage}
                    label="general.save"
                    size="small"
                    loading={buttonLoader} />
            </div>
        </div>
    )
}

export default ImageEditor;