import React, { useState, useEffect, useRef, useContext } from 'react';
import { TimeFieldType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import FormFieldContext from '../FormFieldContext';
import { useClickOutside, useSetDropdownPosition } from 'services/utils/dropdownBehavior'
import { convertUTCToLocalTimeString, extractTimeFromLocalDateTime } from 'internationalization/timezoneConversions';
import { formatTime } from 'services/utils/timefieldFormatting';
import { formValidation } from 'services/utils/formValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface TimeInputProps extends TimeFieldType {
    id?: string;
    value?: string | null;
    isInvalid?: boolean;
    onChange?: (time: string | null) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({
    name, id, value, convertTimezone = false, utcDatetime, localDatetime, disabled, onChange, saveOnBlur, isInvalid
}) => {
    const { t } = useTranslation();
    const { errorMessages, setErrorMessages } = useGlobalContext();
    const { handleSubmit } = useContext(FormFieldContext);
    const { userLocale, userTimezone, getTimeFormat } = useSettings();
    const [isOpen, setIsOpen] = useState(false);
    const [isCleared, setIsCleared] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [activeTime, setActiveTime] = useState<string | null>(null);
    const is12HourFormat = (getTimeFormat() === '12h');
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownListRef = useRef<HTMLDivElement>(null);
    const fieldRef = useRef<HTMLInputElement>(null);
    const [dropdownAbove, setDropdownAbove] = useState(false);
    const [,setDropdownMaxHeight] = useState<number | undefined>(undefined);

    // Set the current value if it exists
    useEffect(() => {
        // If convert timezone is true, convert the given utc datetime into the local timezone datetime and extract the time from it
        if (convertTimezone && utcDatetime) {
            const localTime = convertUTCToLocalTimeString(utcDatetime, userLocale, userTimezone, is12HourFormat);
            setSelectedTime(localTime);

        // If the datetime is already converted into local datetime, only extract the time from it
        } else if (localDatetime) {
            const timeString = extractTimeFromLocalDateTime(localDatetime, userLocale, is12HourFormat);
            setSelectedTime(timeString);

        // Otherwise just put in the given value
        } else if (!utcDatetime && !localDatetime && value) {
            setSelectedTime(value);
        }
    }, [convertTimezone, utcDatetime, localDatetime, value]);

    // Define the times to select per quarter, in 24H or AM/PM time format
    const times = Array.from({ length: is12HourFormat ? 96 : 96 }, (_, i) => {
        const hour24 = Math.floor(i / 4);
        const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12;
        const minute = (i % 4) * 15;
        const getZeroPadded = (num: number) => String(num).padStart(2, '0');
    
        if (is12HourFormat) {
            const period = hour24 < 12 ? 'date_time.time.AM' : 'date_time.time.PM';
            return `${hour12}:${getZeroPadded(minute)} ${t(period)}`;
        } else {
            return `${getZeroPadded(hour24)}:${getZeroPadded(minute)}`;
        }
    });

    // Center the scroll of the options in the dropdown to the current time
    useEffect(() => {
        if (isOpen && dropdownListRef.current) {
            const now = new Date();
            let currentHour = now.getHours();
            let currentMinute = now.getMinutes();

            let currentPeriod = '';
            if(is12HourFormat) {
                currentPeriod = currentHour < 12 ? 'date_time.time.AM' : 'date_time.time.PM';
                currentHour = currentHour % 12;
            }
            currentMinute = Math.floor(currentMinute / 15) * 15;
            
            const closestTimeIndex = times.findIndex(time => {
                const [hoursStr, minutesStr, period] = time.split(/[:\s]/);
                const hours = Number(hoursStr);
                const minutes = Number(minutesStr);
                if(is12HourFormat) {
                    if (period !== currentPeriod) {
                        return false;
                    } else {
                        const compareHour = hours === 12 ? 0 : hours;
                        const currentCompareHour = currentHour === 12 ? 0 : currentHour;
                        return compareHour > currentCompareHour || (compareHour === currentCompareHour && minutes >= currentMinute);
                    }
                }
                else {
                    return hours > currentHour || (hours === currentHour && minutes >= currentMinute);
                }
            });
    
            const closestTimeElement = dropdownListRef.current.children[closestTimeIndex] as HTMLElement;
    
            if (closestTimeElement) {
                // Set to the closest time when clicking in the field
                if (!selectedTime && !isCleared) {
                    const newTime = times[closestTimeIndex];
                    handleSelect(newTime)
                }

                // Center the scroll of the dropdown to the current time
                setActiveTime(times[closestTimeIndex]);
                const timerId = setTimeout(() => {
                    if(dropdownListRef.current) {
                        const dropdownListElement = dropdownListRef.current as HTMLElement;
                        dropdownListElement.scrollTop = closestTimeElement.offsetTop - dropdownListElement.offsetHeight / 2 + closestTimeElement.offsetHeight / 2;
                    }
                }, 0);
                return () => clearTimeout(timerId);
            }
        }
    }, [isOpen]);
    
    // When exiting the field, format the entered value and set the right selected time
    const onSearchBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newTime = e.target.value;
        newTime = formatTime(e.target.value, is12HourFormat ?? false);
        setSelectedTime(newTime);

        // Validate the input of the field
        validateTime(newTime);

        setIsCleared(false);
        setTimeout(() => setIsOpen(false), 0);

        if (onChange) onChange(newTime);

        if (saveOnBlur && handleSubmit) {
            handleSubmit({ [name]: newTime });
        }
    }; 
    
    // Validate the entered time on blur
    const validateTime = (dataToValidate: any) => {
        const fieldItem = { name, type: 'time' };
        const error = formValidation(fieldItem, dataToValidate, is12HourFormat);
        
        if (error && error[name]) {
            const newErrorState = { ...errorMessages, [name]: error[name] };
            setErrorMessages(newErrorState);
            return false;
        } else {
            const newErrorState = { ...errorMessages, [name]: undefined };
            setErrorMessages(newErrorState);
            return true;
        }
    };

    // Handle the selection of a time
    const handleSelect = (newTime: string) => {
        if (onChange) onChange(newTime);
        setSelectedTime(newTime);
        setActiveTime(newTime);
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    // Use dropdownBehavior.ts to close the dropdown when clicking outside
    useClickOutside(isOpen, dropdownRef, setIsOpen);

    // Use dropdownBehavior.ts to set the dropdown position on top or bottom on open
    useSetDropdownPosition(isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight, 295);

    return (
        <div className='custom-dropdown-container timefield-container'>
            <input 
                type="text" 
                name={name}
                id={id}
                ref={fieldRef}
                value={selectedTime}
                disabled={disabled}
                onChange={(e) => {
                    setSelectedTime(e.target.value); 
                    setIsOpen(true);
                    if (onChange) onChange(e.target.value);
                }}
                onBlur={onSearchBlur}
                onFocus={() => setIsOpen(true)}
                className={`${errorMessage || isInvalid ? 'is-invalid' : ''}`}
                placeholder={is12HourFormat ? t('date_time.time.12h_placeholder') : t('date_time.time.24h_placeholder')}
            />
            {selectedTime && !disabled && (
                <div className='clear-button tooltip-icon' 
                     onClick={(e) => {
                     e.preventDefault(); 
                     setSelectedTime(''); 
                     setIsCleared(true); 
                     fieldRef.current?.focus();}}>
                    <FontAwesomeIcon icon={faTimes} />
                    <span className="tooltip">{t('date_time.time.clear_time_label')}</span>
                </div>
            )}
            {isOpen && (
                <div className={`custom-dropdown ${dropdownAbove ? 'dropdown-above' : ''}`} style={{maxHeight: '295px'}} ref={dropdownRef}>
                    <div className='custom-dropdown-list' style={{width: '150px'}} ref={dropdownListRef}>
                        {times.map(time => (
                            <div key={time} 
                                    className={`custom-dropdown-item ${selectedTime === time ? 'selected' : ''} ${activeTime === time ? 'active' : ''}`}
                                    onMouseDown={() => { handleSelect(time); setIsOpen(false); }}>
                                {time}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimeInput;