/*
 * releasedFeatures.ts
 * This list the released features. These features are currently available 
 * to users. This is necessary because the features sent from the backend 
 * with the environment also include future functionalities that may still 
 * be under development.
 */

// Add available features here:
const CURRENTLY_RELEASED_FEATURES = [
    'contacts',
    'products',
    // 'inventory',
    // 'proposals',
    'scheduling',
    'jobs',
    // 'manage_timesheets',
    // 'contract_management',
    'invoices',
    // 'inbox',
    // 'tickets',
    // 'workflows',
    // 'hrm',
    // 'reporting',
    'mobile_jobs',
    // 'mobile_timesheet',
    // 'mobile_tasks',
    // 'mobile_leave_requests',
    // 'mobile_expenses',
    // 'mobile_create_jobs',
    // 'mobile_calendar',
    // 'mobile_payments',
    'product_categories',
    // 'default_duration',
    'purchase_orders',
    // 'related_organizations',
    // 'location_objects',
    // 'single_pipeline',
    'job_multiple_appointments',
    'scheduling_timeline_views',
    // 'gps_job_filters',
    // 'job_storage_filters',
    // 'equipment_planning',
    // 'employee_scheduling',
    // 'required_mobile_fields',
    // 'scheduling_time_blocks',
    // 'mobile_job_storage',
    // 'mobile_forms',
    'groups',
    // 'subcontractors',
    // 'custom_email_messages',
    // 'custom_sms_messages',
    // 'custom_job_pdf',
    // 'product_rules',
    // 'suggested_duration',
    // 'stockrooms',
    // 'stock_minimum',
    'custom_roles',
    // 'custom_exports',
    // 'custom_views',
    // 'custom_filters',
    // 'custom_column_selection',
    // 'custom_widgets',
    // 'custom_to_dos',
    // 'custom_statusses',
    // 'multiple_pipelines',
    // 'proposal_view_notification',
    // 'route_planner',
    // 'route_optimalization',
    // 'appointment_booking_engine',
    // 'client_portal',
    // 'field_worker_profile',
    // 'divisions',
    // 'enhanced_security',
    // 'local_backup',
    // 'api_keys'
]

// Helper function to filter received features against the currently released features
export const filterReleasedFeatures = (features: string[]) => {
    return features.filter(feature => CURRENTLY_RELEASED_FEATURES.includes(feature));
};

// Helper function to get the list of released features
export const getReleasedFeatures = (): string[] => {
    return CURRENTLY_RELEASED_FEATURES;
}