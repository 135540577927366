import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../style/scss/navigation.scss';

/* 
 * SideBarNavItem button component
 *
 * This component ensures that in the sidebar, the
 * surrounding <li> also counts in the <a> of the nav item. 
 * Otherwise, only the text of the nav item would be clickable.
 */

interface SideBarNavItemProps {
    to: string;
    children: React.ReactNode;
}

const SideBarNavItem: React.FC<SideBarNavItemProps> = ({ to, children }) => {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const location = useLocation();

    const handleClick = () => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    };

    // Function to set the active nav item
    const isActive = location.pathname === to;
    const activeClassName = isActive ? 'active' : '';

    return (
        <li onClick={handleClick} className={`sidebar-nav-item ${activeClassName}`}>
            <Link ref={linkRef} to={to} className='sidebar-nav-link'>
                {children}
            </Link>
        </li>
    );
};

export default SideBarNavItem;