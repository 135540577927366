import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { FeatureStatusesType } from './WorkflowBar';
import { statusesTodosConfig } from './dashboardConfig';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/scss/dashboard.scss';

export interface StatusesTodosConfig {
    [featureKey: string]: {
        allowedFeature: string;
        todos: { 
            status_condition: string[], 
            label: string 
        }[];
    };
};

const TodoList: React.FC<{ featureStatuses: FeatureStatusesType }> = ({ featureStatuses }) => {
    const { t } = useTranslation();
    const { allowedFeatures } = useAuthContext();

    // If the user has more than one responsibility feature, show a feature header between each responsibility group
    const countResponsibilityGroups = () => {
        return Object.values(statusesTodosConfig).filter(mapping => 
            allowedFeatures.includes(mapping.allowedFeature)
        ).length;
    };

    // Render the todos for each responsibility groups
    const renderTodos = () => {

        // Count the rendered responsibility groups
        const responsibilityFeaturesCount = countResponsibilityGroups();

        // Loop through the statuses todos config
        return Object.entries(statusesTodosConfig).map(([featureKey, value]) => {
            
            // Only render the todo if the feature exists in the allowed feature of the user, and if there is more than one todo to be done
            if ((allowedFeatures.includes(value.allowedFeature)) && featureStatuses[featureKey] && featureStatuses[featureKey].length > 0) {

                // Render the todo rows
                const todoRows = value.todos.map(todo => {
                    
                    // Count the todos to do or set to 0 if the feature statuses array is empty
                    const count = featureStatuses[featureKey]?.filter(item => todo.status_condition.includes(item.status_condition)).length || 0;

                    // If there are todos to be done, return the rendered todo row
                    if (count > 0) {
                        return (
                            <div className='todo-row' key={todo.label}>
                                <span className='label'>{count} {t(todo.label)}</span>
                                <span className='icon'><FontAwesomeIcon icon={faAngleRight} /></span>
                            </div>
                        );
                    }

                    // If there are no todos to be done, render no todo row
                    return null;

                // Filter null elements out of the array
                }).filter(item => item !== null);

                // Render the responsibility groups
                return (
                    <div className='responsibility-group' key={featureKey}>
                        {responsibilityFeaturesCount > 1 &&
                            // If there are more than 1 responsibility features for the user, group them with a feature label
                            <h6>{t(`dashboard.general.todos_${featureKey}_title`)}</h6>
                        }
                        {todoRows.length > 0 ? (
                            // Show the rendered todo rows
                            todoRows
                        ) : (
                            // If there are no todos to be done, show the finished todos row
                            <div className='finished-todo-row'>
                                <span className='label'>{t('dashboard.general.todos_all_is_done_label')}</span>
                            </div>
                        )}
                    </div>
                );
            }

            return null;

        // Filter null elements out of the array
        }).filter(group => group !== null);
    };

    return (
        <div className='todos'>
            <h5>{t('dashboard.general.todos_today_title')}</h5>
            <div className='section'>  
                {renderTodos()} 
            </div>
        </div>
    );
};

export default TodoList;