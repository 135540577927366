import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const UserForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'user'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'first_name',
            label: 'user.general.first_name_label',
            type: 'text',
            placeholder: 'user.general.first_name_placeholder',
            width: '50%',
            group: 'field-row',
        },
        {
            name: 'last_name',
            label: 'user.general.last_name_label',
            type: 'text',
            placeholder: 'user.general.last_name_placeholder',
            width: '50%',
            group: 'field-row',
        },
        {
            name: 'email',
            label: 'user.general.email_label',
            type: 'email',
            placeholder: 'user.general.email_placeholder',
            helperText: 'user.general.email_helperText',
        },
        {
            name: 'password',
            label: 'user.general.password_label',
            type: 'password',
            placeholder: 'user.general.password_placeholder',
        },
        {
            name: 'role',
            label: 'user.general.role_label',
            type: 'dropdown',
            disabled_selected: 'user.general.role_disabled_selected',
            showSearch: false,
            apiObject: 'role',
            params: { 
                is_active: true, 
            },
            allowNoneOption: false,
            helperText: 'user.general.role_helperText'
        },
        {
            name: 'profile_picture',
            label: 'user.general.profile_picture_label',
            type: 'image',
            imageType: 'smallCircle',
            dropzoneText: 'user.general.profile_picture_placeholder',
            apiField: { 
                fileUpload: 'profile_picture_file', 
                fileName: 'profile_picture_filename'
            },
            cropConfig: {
                aspectRatio: 1,
                cropShape: 'round',
                resize: { width: 200, height: 200 }
            }
        },
        {
            name: 'is_active',
            label: 'user.general.is_active_label',
            type: 'checkbox',
            showOnEdit: true,
            tooltipText: 'user.general.is_active_tooltipText',
        },
    ];
    
    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default UserForm;