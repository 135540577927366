import React, { useState, useEffect } from 'react';
import { Button } from 'types/ButtonTypes';
import { useTranslation } from 'react-i18next';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { Link } from 'react-router-dom';
import '../../style/scss/button.scss';

const SecondaryButton: React.FC<Button> = ({ 
    onClick, 
    route, 
    label, 
    size = 'medium', 
    loading = false,
    type = 'button',
    onlyViewRestriction,
    customClass
}) => {
    const { t } = useTranslation();

    // Make the button invisible if it is restricted for only_view users
    const userHasOnlyViewRight = !useAllowedRight('only_view')
    const isVisible = onlyViewRestriction ? userHasOnlyViewRight : true;

    // Set the size, button and text loading classes
    const buttonSizeClass = `button-${size}`;
    const buttonLoadingClass = loading ? 'button-loading' : '';
    const textLoadingClass = loading ? 'text-hidden' : '';

    // Give the loader icon a delay, so it looks smoother
    const [showLoaderWithDelay, setShowLoaderWithDelay] = useState(false);
    useEffect(() => {
        let timer: number;
        if (loading) {
            timer = window.setTimeout(() => setShowLoaderWithDelay(true), 150)
        } else {
            setShowLoaderWithDelay(false);
        }
        return () => clearTimeout(timer);
    }, [loading]);

    // Configure the button
    const ConfiguredButton = (
        <button 
            type={type}
            onClick={onClick}
            className={`button ${buttonSizeClass} button-secondary ${customClass} ${buttonLoadingClass}`}
            disabled={loading}>
                <span className={`button-text ${textLoadingClass}`}>
                    {t(label)}
                </span>
                {showLoaderWithDelay && 
                    <div className='loader-container'>
                        <div className='button-loader'></div>
                    </div>
                }
        </button>
    );

    return (
        isVisible ? (
            route ? (
                <Link to={route}>
                    {ConfiguredButton}
                </Link>
            ) : (
                ConfiguredButton
            )
        ) : null
    );
};

export default SecondaryButton;