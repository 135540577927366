import React from 'react';
import { useTranslation } from 'react-i18next';
import Greeting from './Greeting';
import ReleaseCard from 'components/detailcards/ReleaseCard';
import '../../style/scss/dashboard.scss';

const BetaHomePage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='container-filled'>
            <div className='dashboard'>
                <div className='greeting'>
                    <h2>
                        Welkom in jouw Beta omgeving
                    </h2>
                </div>
                <div className='greeting'>
                    <h5>
                        <Greeting />
                    </h5>
                    <h5>
                        Welkom in jouw Beta omgeving.
                        Feedback
                        Groet
                    </h5>

                </div>
                <div>
                    <h5>
                        Nieuwe releases
                    </h5>
                    {/* <ReleaseCard /> */}
                </div>
                <div>
                    <h5>
                        Onze roadmap
                    </h5>
                    {/* <h5>{t('dashboard.general.roadmap_label')}</h5> */}
                </div>
            </div>
        </div>
    );
};

export default BetaHomePage;