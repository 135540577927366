import axios from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from './csrfFunctions';
import { AlertType } from 'types/AlertTypes';

interface LogoutCallbacks {
    setIsLoggedIn: (loggedIn: boolean | null) => void;
    setRedirectTo: (redirect: 'home' | 'loginpage' | null) => void;
    setFormAlert: (alert: AlertType | null) => void;
}

interface LogoutParams {
    logoutFromServer?: boolean;
    showSessionAlert?: boolean;
    callbacks: LogoutCallbacks;
}

// Log out and delete environment data and cookies from the server
export const logout = async ({ logoutFromServer = false, showSessionAlert = false, callbacks }: LogoutParams) => {
    const csrfToken = getCsrfToken();

    // Optionally logout from the server
    if (logoutFromServer) {
        try {
            await axios.post(`${apiBaseUrl}/logout/`, {}, { 
                withCredentials: true,
                headers: { 
                    'X-CSRFToken': csrfToken 
                } 
            });
        } catch (error: any) {
            console.error('Error while logging out:', error);
        }
    }

    // Show session alert when logging out in case of unauthorized fetches
    if (showSessionAlert) {
        callbacks.setFormAlert({ type: 'warning', message: "alert.session_expired_message" })
    }

    // Use the callback to change the login status
    callbacks.setIsLoggedIn(false);

    // Delete the data from the local storage
    localStorage.clear();

    // Use the callback to change the redirect to the loginpage
    callbacks.setRedirectTo('loginpage');
}