import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const EnvironmentForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'environment_as_superuser'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'environment.general.environment_name_label',
            type: 'text',
            placeholder: 'environment.general.environment_name_placeholder',
        },
        {
            name: 'status',
            label: 'environment.general.status_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            hardcodedOptions: [
                { value: 'client', name: 'environment.type.client'},
                { value: 'suspended', name: 'environment.type.suspended'},
                { value: 'cancelled', name: 'environment.type.cancelled'},
            ],
        },
        {
            name: 'is_active',
            label: 'forms.is_active_label',
            type: 'checkbox',
            showOnEdit: true,
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default EnvironmentForm;