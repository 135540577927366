import React, { useState, useMemo, useRef, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { fetchData } from 'services/api/fetchData';
import { fetchDropdownDataFromFields } from 'services/api/fetchFieldData';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { startDateEndTime } from 'services/utils/dateTimeUtils';
import { sanitizeTextareaFromDataObject } from 'services/utils/convertTextareaData';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import FormFieldContext from './FormFieldContext';
import { FieldType, FieldOptionFetchResponse, FieldOption } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { generateFieldComponents } from './GenerateFieldComponents';
import { generateButtonComponents } from './GenerateButtonComponents';
import '../../style/scss/forms.scss';

interface InlineFormProps {
    viewKey: string;
    customApiFunction?: (data: Record<string, any>) => Promise<void>;
    apiObject?: string;
    itemId?: number;
    itemStr?: string;
    fields: FieldType[];
    formHeader?: string;
    formDescription?: string;
    formInformation?: React.ReactNode;
    metaData?: Record<string, number | string | undefined>;
    buttons: FormButtonType[]; 
    onClose?: () => void;
    onTypeChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    refresh?: string;
};

const InlineForm: React.FC<InlineFormProps> = ({ 
    viewKey, customApiFunction, apiObject, itemId, itemStr, fields, formHeader, formDescription, formInformation, metaData, buttons, onClose, onTypeChange, refresh 
}) => {
    const { t } = useTranslation();
    const { formAlert, setFormAlert, setFloatingAlert, errorMessages, setErrorMessages, setUnsavedChanges } = useGlobalContext();
    const { handleLogout } = useAuthContext();
    const [editing, setEditing] = useState(true);
    const [data, setData] = useState<FieldType[]>([])
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([])
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [showLoader, setShowLoader] = useState(false);
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    // Fetch data
    useEffect(() => {
        // Set load status to loading
        setLoadStatus("loading");

        // Show loader icon after 250ms
        let timer: NodeJS.Timeout | null = setTimeout(() => {
            setShowLoader(true);
        }, 250);

        // Fetch data of the current item in case of editing
        const loadItemData = async () => {
            if (itemId || itemStr) {
                try {
                    const data = await fetchData({ apiObject, itemId: itemId || itemStr, handleLogout });
                    setData(data);
                } catch (error) {
                    console.log(`Failed to load ${apiObject} data`, error);
                }
            }
        };

        // Fetch options of dropdown and select fields
        const fetchDropdownData = async () => {
            const dropdownData: Record<string, FieldOptionFetchResponse> = {};
            for (let field of fields) {
                if ((field.type === 'dropdown' || field.type === 'multiselect' || field.type === 'tabs') && field.apiObject) {
                    const results = await fetchDropdownDataFromFields(field.apiObject, field.params, handleLogout);
                    dropdownData[field.apiObject] = results;
                }
                // If multiselect fields are grouped, fetch the group data
                if (field.type === 'multiselect' && field.groupByField) {
                    const results = await fetchData({ apiObject: field.groupByField?.apiObject, params: { is_active: 'true' }, handleLogout });
                    const fieldName = field.groupByField.apiField;

                    // Only extract the id and the given field name from the data
                    const extractedFields = results.results.map((item: any) => ({
                        id: item.id,
                        [fieldName]: item[fieldName],
                    }));
                    setGroupOptions(extractedFields);
                }
            }
            setDropdownData(dropdownData);
        }

        // Start both async operations
        const loadPromises = [loadItemData(), fetchDropdownData()];

        // Set load status to loaded on completion
        Promise.all(loadPromises).then(() => {
            setLoadStatus("loaded");
            if (timer) {clearTimeout(timer)};
            setShowLoader(false);
        });
    }, []);
    
    // Handles the submit of the form
    const handleSubmit = async () => {
        setButtonLoader(true);

        // Create the cancel token source
        const source = axios.CancelToken.source();
        setCancelSource(source);

        // Validate start and end dates. If no end date is given, start date is the end date
        const { adjustedUpdatedData, errors } = startDateEndTime(updatedData, data);
        if (errors.endDate || errors.endTime) {
            const updatedErrorState: Record<string, string | string[] | undefined> = {
                ...errorMessages,
                end_date: errors.endDate,
                end_time: errors.endTime
            };
            setErrorMessages(updatedErrorState);
            setButtonLoader(false);
            return;
        }

        // If there are textarea fields, convert and sanitize the text
        const transformedData = sanitizeTextareaFromDataObject(adjustedUpdatedData, fields);
        
        // Combine the transformed data and eventually the meta data
        const submitData = { ...transformedData, ...metaData };

        try {
            // If a custom api function is given, use this
            if (customApiFunction) {
                await customApiFunction(submitData)
            }

            // If no custom api function is given, use the default save data function
            else {
                await saveData({ apiObject, itemId: itemId || itemStr, method: itemId || itemStr ? 'patch' : 'post', data: submitData, source });

                // Show floating alert on successful save
                setFloatingAlert({ 'type': 'success' })
            }
        } catch (error) {
            // Extract the names of the fields from the fields to do validation on
            const fieldsWithName = fields.filter(field => 'name' in field);
            const errorFields = (fieldsWithName as Array<{ name: string }>).map(field => field.name);

            // Generate given errors using the handleSaveErrors function to show below or on top of the fields
            const errorData = handleSaveErrors(error, errorFields); 
            setErrorMessages(errorData);

            // If there is a general error, show this on top of the form 
            if (errorData.general) {
                setFormAlert({ message: errorData.general, type: 'danger' })
            }
        } finally {
            setButtonLoader(false);
            setUnsavedChanges(viewKey, false);
            if (onClose) onClose();
        }
    }

    // Handles closing of the form
    const handleClose = (event: React.MouseEvent) => {
        event.preventDefault();
        if (cancelSource) cancelSource.cancel();
        setUnsavedChanges(viewKey, false)
        setFormAlert(null);
        setErrorMessages({});
        setButtonLoader(false);
        if (onClose) onClose();
    }

    // Use the generateFieldComponents function to render each field component for the form
    const fieldComponents = useMemo(() => {
        return generateFieldComponents(viewKey, fields, dropdownData, groupOptions, data, updatedData, itemId, onTypeChange ?? (() => {}));
    }, [data, dropdownData, refresh, updatedData]);

    // Use the generateButtonComponents function to render the form buttons
    const buttonComponents = useMemo(() => {
        return generateButtonComponents(buttons, handleClose, handleSubmit, buttonLoader);
    }, [buttons, handleClose, handleSubmit, buttonLoader]);

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData, buttonLoader, 
            setButtonLoader, showErrorAlert, setShowErrorAlert, handleSubmit }}>
            {showLoader ? (
                <div className='inline-form-loader'>
                    <div className="loader"></div>
                </div>
            ) : loadStatus === 'loaded' ? (
                <form className='formset inline-form' ref={formRef}>
                    {formAlert &&
                        <div className={`alert form-alert alert-${formAlert.type}`} role="alert">
                            {t(formAlert.message)}
                        </div>
                    }
                    {formHeader &&
                        <h4>{t(formHeader)}</h4>
                    }
                    {formDescription &&
                        <p>{t(formDescription)}</p>
                    }
                    <div>
                        {fieldComponents}
                    </div>
                    <div className='form-information'>
                        {formInformation}
                    </div>
                    <div className='button-row'>
                        {buttonComponents}
                    </div>                
                </form>
            ) : null}
        </FormFieldContext.Provider>
    );
};

export default InlineForm;