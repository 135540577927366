import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';

/*
 * fetchData.tsx
 * General utility function that handles get request. The function generates the api 
 * url based on the given api object. It generates a cancel token that cancels requests 
 * from aborted page openings, which prevents unwanted server requests.
 */

export interface FetchDataProps {
    apiObject?: string;
    apiUrl?: string;
    itemId?: string | number;
    params?: any;
    viewKey?: string;
    responseAsBlob?: boolean;
    source?: CancelTokenSource;
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>;
}

export async function fetchData({ 
    apiObject, apiUrl, itemId, params, responseAsBlob, source, handleLogout 
} : FetchDataProps) {

    // If no canceltoken source is given as prop, create one
    let internalSource: CancelTokenSource | null = null;
    if (!source) {
        internalSource = axios.CancelToken.source();
        source = internalSource;
    }

    // Get the csrf token from the cookie
    const csrfToken = getCsrfToken();

    // Configure the url of the api
    let url = apiUrl 
    ? `${apiBaseUrl}/${apiUrl}/` // If apiUrl is given, use that
    : itemId
        // If an itemId is given, get the data of that item. E.g.: 'api/get_product/400008/'
        ? `${apiBaseUrl}/get_${apiObject}/${itemId}/`
        // If no itemId is given, get the list of the api object. E.g.: 'api/get_product_list/'
        : `${apiBaseUrl}/get_${apiObject}_list/`;

    // Ensure the url ends with a slash (to prevent redirects) if it does not have parameters
    if (!url.endsWith('/') && !url.includes('?')) {
        url += '/';
    }

    try {
        // Fetch the data from the server
        const response = await axios({
            url,
            method: 'get',
            params,
            cancelToken: source.token,
            withCredentials: true,
            headers: { 'X-CSRFToken': csrfToken },
            responseType: responseAsBlob ? 'blob' : 'json'
        });

        // If response status is unauthorized, log out user
        if (response.status === 403) {
            handleLogout({ showSessionAlert: true });
        }

        // Return the response data
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);

        // If the error status is unauthorized, log user out
        } else if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
            handleLogout({ showSessionAlert: true });

        } else {
            console.log('Fetching data not possible. Error: ', (error as Error).message);
            throw error;
        }
    } finally {
        if (internalSource) {
            source.cancel();
        }
    }
}