import React, { useState, useEffect, useContext } from 'react';
import { FieldData, TargetDateMultiFieldType } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { formatDecimalToHoursAndMinutes } from 'services/utils/convertDecimalToHoursAndMinutes';
import { convertHoursAndMinutesToDecimal } from 'services/utils/convertHoursToDecimal';
import DateInput from '../basefields/DateInput';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from '../fields/elements/FieldViewMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface TargetDateMultiFieldProps extends TargetDateMultiFieldType {
    errorMessage?: string | string[] | undefined;
}

const TargetDateMultiField: React.FC<TargetDateMultiFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, viewInEditMode, alignment, isEditable, disabled
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [estimatedDuration, setEstimatedDuration] = useState('');
    
    // Load the current values inside the fields
    useEffect(() => {
        // Set the current estimated duration from the data
        if (data && data['estimated_duration']) {

            // Extract the value from the data
            const backendValue = data['estimated_duration'];

            // Format the decimal field into a time field
            const formattedTime = formatDecimalToHoursAndMinutes(backendValue);

            // Set the estimated duration
            setEstimatedDuration(formattedTime);
        }

        // Set the current target date from the data
        if (data && data[name]) {
            const dateValue = new Date(data[name]);

            // Check if the date value is a legit date object
            if (!isNaN(dateValue.getTime())) {
                setSelectedDate(dateValue);
            } else {
                setSelectedDate(null);
            }
        };
    }, [data, name]);

    // Updates the value after changing the budget
    const handleEstimatedDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        // Get the new value
        const newValue = e.target.value

        // Set the estimated duration
        setEstimatedDuration(newValue);

        // Convert the estimated duration in the right format
        const convertedDecimalNumber = convertHoursAndMinutesToDecimal(newValue);

        // Update the updated data
        setUpdatedData((prevData: Record<string, any>) => ({
            ...updatedData,
            ...prevData,
            ['estimated_duration']: convertedDecimalNumber
        }));

        // Set unsaved changes
        const hasChanged = convertedDecimalNumber !== data[name]
        if (hasChanged) {
            setUnsavedChanges(viewKey, hasChanged);
        }
    };

    // Handles the date change
    const handleDateChange = (dateString: string | null) => {
        if (dateString === null) {
            // If the date is deleted, set it to null
            setSelectedDate(null);
            setUpdatedData({ ...updatedData, [name]: null })
        } else {
            const parsedDate = new Date(dateString);
            if (!isNaN(parsedDate.getTime())) {
                setSelectedDate(parsedDate);
            } else {
                setSelectedDate(null);
            }
        }
    
        // If there are changes, set unsaved changes and updated data
        const hasChanged = dateString !== (data[name] ? new Date(data[name]).toISOString().split('T')[0] : null);
        setUnsavedChanges(viewKey, hasChanged);

        // Update the updated data
        if (hasChanged) {
            setUpdatedData((prevData: Record<string, any>) => ({
                ...updatedData,
                ...prevData,
                [name]: dateString,
            }));
        }
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name && !errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = name && errorMessages[name];

    // Format the field view value
    const configureFieldViewValue = () => {

        // Initialize the fieldViewValue to an empty string
        let fieldViewValue = '';

        // Determine if a date exists and convert it to the right user locale
        const dateValue = selectedDate && selectedDate.toLocaleDateString(userLocale, {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit'
        });

        // Configurate the field view value
        if (dateValue && estimatedDuration) {
            fieldViewValue = `${dateValue} • ${estimatedDuration} ${t('date_time.general.hour.singular')}`;
        } else if (dateValue) {
            fieldViewValue = dateValue;
        } else if (estimatedDuration) {
            fieldViewValue = `${estimatedDuration} ${t('date_time.general.hour.singular')}`;
        }
    
        return fieldViewValue;
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='two-fields'>
                    <div className='field-column'>
                        <label htmlFor={name}>
                            {t('job.general.target_date_label')}
                        </label>
                        <DateInput
                            id={name}
                            name={name}
                            value={selectedDate}
                            onChange={(date) => handleDateChange(date)}
                            placeholder={'job.general.target_date_placeholder'}
                        />
                    </div>
                    <div className='field-column'>
                        <label htmlFor={'estimated_duration'}>
                            {t('job.general.estimated_duration_label')}
                            <span className="tooltip-icon">
                                <FontAwesomeIcon icon={faCircleQuestion} />
                                <span className="tooltip tooltip-right">{t('job.general.estimated_duration_tooltipText')}</span>
                            </span>
                        </label>
                        <input 
                            type="text"
                            id={'estimated_duration'}
                            name={'estimated_duration'}
                            value={estimatedDuration}
                            onChange={handleEstimatedDurationChange}
                            placeholder={t('job.general.estimated_duration_placeholder')}
                            disabled={disabled}
                            autoFocus={false}
                            className={errorMessage ? 'is-invalid' : ''}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    value={configureFieldViewValue()} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default TargetDateMultiField;