import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import EnvironmentForm from './EnvironmentForm';

// Rename the name of the function:
const EnvironmentList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_environment_as_superuser_list',
        defaultParams: { 'status': 'client' },
        object: 'environment_as_superuser'
    }
    const objectName = 'environment.general.object_name'
    const showSearch = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'id', label: 'environment.general.environment_id_label' },
        { width: '9fr', field: 'name', label: 'environment.general.environment_name_label' },
    ];

    // Specify form, detail modal or detailpage for this list
    const formModal = <EnvironmentForm />;
    const onRowClick = 'editModal'

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'client',
            label: 'environment.general.active_environments_label',
            params: { 'status': 'client' }
        },
        {
            id: 'suspended',
            label: 'environment.general.suspended_environments_label',
            params: { 'status': 'suspended' }
        },
        {
            id: 'cancelled',
            label: 'environment.general.cancelled_environments_label',
            params: { 'status': 'cancelled' }
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default EnvironmentList;