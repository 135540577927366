import React, { createContext, useState, ReactNode, useContext } from 'react';

interface FetchContextType {
    activeFetches: Set<string>;
    registerFetch: (url: string) => void;
    unregisterFetch: (url: string) => void;
    fetchedData: Map<string, any>;
    setData: (url: string, data: any) => void;
    getData: (url: string) => any;
}

const FetchContext = createContext<FetchContextType | undefined>(undefined);

interface FetchProviderProps {
    children: ReactNode;
}

interface cachedDataType {
    url: string;
    status: 'valid' | 'invalid';
    data: Map<string, any>;
}

export const FetchContextProvider: React.FC<FetchProviderProps> = ({ children }) => {
    const [activeFetches, setActiveFetches] = useState<Set<string>>(new Set());
    const [fetchedData, setFetchedData] = useState<Map<string, any>>(new Map());

    // Register a fetch url to the active fetches
    const registerFetch = (url: string) => {
        setActiveFetches(prev => new Set(prev.add(url)));
    };

    // Unregister a complete fetch from the active fetches
    const unregisterFetch = (url: string) => {
        setActiveFetches(prev => {
            const newSet = new Set(prev);
            newSet.delete(url);
            return newSet;
        });
    };

    // Cache the fetched data in the context with the url as key
    const setData = (url: string, data: any) => {
        setFetchedData(prev => new Map(prev.set(url, data)));
    };

    // Get cached data of a url
    const getData = (url: string) => {
        return fetchedData.get(url);
    };

    return (
        <FetchContext.Provider value={{ activeFetches, registerFetch, unregisterFetch, fetchedData, setData, getData }}>
            {children}
        </FetchContext.Provider>
    );
};

// Create a custom hook for the fetch context
export const useFetchContext = () => {
    const context = useContext(FetchContext);
    if (!context) {
        throw new Error('useFetchContext must be used within a FetchProvider');
    }
    return context;
};