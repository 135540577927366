import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageAlertType } from 'types/AlertTypes';

const PageAlert: React.FC<PageAlertType> = ({ title, type, message }) => {
    const { t } = useTranslation();

    return (
        <div className='container-filled page-alert'>
            <div className={`alert alert-${type}`}>
                <div className='title'>{t(title)}</div>
                <div>{t(message)}</div>
            </div>
        </div>
    );
};

export default PageAlert;