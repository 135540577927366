import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const ProductForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'product'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'product.general.name_label',
            type: 'text',
            placeholder: 'product.general.name_placeholder',
        },
        {
            name: 'number',
            label: 'product.general.number_label',
            type: 'text',
            placeholder: 'product.general.number_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'category', 
            label: 'product.general.category_label', 
            apiObject: 'productcategory',
            disabled_selected: 'product.general.category_disabled_selected',
            params: { 
                is_active: true, 
                is_product: true
            }
        },
        { 
            type: 'text', 
            name: 'unit', 
            label: 'product.general.unit_label', 
            placeholder: 'product.general.unit_placeholder', 
        },
        { 
            type: 'price-currency', 
            name: 'sale_price', 
            label: 'product.general.sale_price_label', 
            placeholder: 'product.general.sale_price_placeholder', 
            apiObject: 'currency',
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default ProductForm;