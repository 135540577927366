import CryptoJS from 'crypto-js';

/*
 * This is a fake encryption. The idea behind this is that malicious users cannot directly 
 * see which value is stored via the localStorage. It is not for security purposes, because 
 * as soon as a change is made to the localStorage, the signing is broken and the data is 
 * retrieved again.
 */

// localStorageKey
const localStorageKey = 'df170add18f80935cfe79f172dcfc63e81cf229bc2855d4e8bf970c0ef60fd49'

// Function to encrypt a string
export const encryptData = (text: string): string => {
    const ciphertext = CryptoJS.AES.encrypt(text, localStorageKey).toString();
    return ciphertext;
};
  
// Function to decrypt a string
export const decryptData = (ciphertext: string): string => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, localStorageKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};

// Function to get decrypted data from a local storage item
export const getDecryptedLocalStorageItem = (key: string): string | null => {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
        const decryptedValue = decryptData(encryptedValue);
        try {
            return JSON.parse(decryptedValue);
        } catch (e) {
            return decryptedValue;
        }
    }
    return null;
}