import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { fetchData } from 'services/api/fetchData';
import { FieldOption, FieldOptionFetchResponse, FieldType } from 'types/FieldTypes';
import { AppointmentType, createNewAppointment } from '../jobs/JobTypes';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import { fetchDropdownDataFromFields } from 'services/api/fetchFieldData';
import { CancelTokenSource } from 'axios';
import CheckboxCheck from 'assets/CheckboxCheck.svg';
import '../../style/scss/forms.scss';
import '../../style/scss/modals.scss';
import '../../style/scss/settings.scss';

const CustomizeSchedulingModal: React.FC<{ viewKey?: string, jobId: number, setLoading: (loading: boolean) => void }> = ({ viewKey, jobId, setLoading }) => {
    const { t } = useTranslation()
    const { revealModal, closeModal } = useModal();
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);    
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([])
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});;
    const [buttonLoader, setButtonLoader] = useState(false);
    const [emailCheckboxValue, setEmailCheckboxValue] = useState<boolean>(false);

    useEffect(() => {
        revealModal();
    }, [])

    // Field configuration for every appointment
    const fields: FieldType[] = [
        {
            type: 'tabs',
            name: 'start_date',
            label: 'Getoonde dagen',
            options: [
                { value: "weekdays", name:  "Werkdagen" },
                { value: "weekdaysAndSaturday", name:  "Werkdagen & Zaterdag" },
                { value: "alldays", name:  "Alle dagen" },
            ],
            viewInEditMode: true,
            defaultValue: "weekdays"
        },
        {
            type: 'time',
            name: 'start_date',
            label: 'Getoonde tijden (van - tot)',
            width: '34%',
            viewInEditMode: true,
            tooltipText: 'test',
        },
        {
            type: 'time',
            name: 'start_date',
            label: '',
            width: '34%',
            viewInEditMode: true,
        },
        {
            type: 'tabs',
            name: 'start_date',
            label: 'Afspraak kleuren in agenda op basis van',
            options: [
                { value: "status", name: "Statuskleuren" },
                { value: "labels", name: "Labelkleuren" },
            ],
            viewInEditMode: true,
        },
        // { 
        //     type: 'multiselect', 
        //     name: 'assignees', 
        //     label: 'Afspraak tekst', 
        //     apiObject: 'user',
        //     apiRequest: [{
        //         endpoint: 'get_user_list',
        //         object: 'user',
        //         params: { is_active: true, field_worker: true }
        //     }],
        //     allowNoneOption: false,
        //     showSearch: true,
        //     disabled_selected: 'job.schedule_job.assignees_disabled_selected',
        //     selectionFormat: 'full_name',
        //     optionFormat: (option: FieldOption) => option.full_name,
        //     viewInEditMode: true,
        //     tooltipText: 'test',
        //     helperText: 'test'
        // },
    ];

    const handleSubmit = async () => {

    };

    return (
        <div className='schedule-job-modal'>
            <h3 className='header text-center'>Customize Scheduling</h3>
            <div className='settings-form'>
            
                    
            </div>
            <div className='footer'>
                <div className='buttons-left'>
                </div>
                <div className='buttons-right'>
                    <SecondaryButton
                        onClick={closeModal} 
                        label="general.cancel"
                        size="small"/>
                    <PrimaryButton
                        onClick={handleSubmit}
                        label="general.save"
                        size="small"
                        loading={buttonLoader}/>
                </div>
            </div>
        </div>
    );
};

export default CustomizeSchedulingModal;