import React from 'react';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtons } from 'types/ButtonTypes';
import { FieldOption } from 'types/FieldTypes';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (client: any) => {
    if (client && client['entity_type'] && client['id']) {
        const baseUrl = client['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${client['id']}`;
    }
    return null;
}

// Rename the name of the function:
const InvoiceModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {

    // Specify the parameters of this detail modal:
    const apiObject = 'invoice';
    const object = 'invoice';
    const showStatusBar = true;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtons = [
        {   
            type: 'secondary',
            label: 'button.download_pdf_button_label',
            action: 'download_pdf',
            allowedRight: ['can_download'],
            query: {
                endpoint: 'get_pdf_layout_list/invoice',
                object: 'pdf_layout',
                defaultParams: { 'deleted': false },
            }
        },
        {   
            type: 'dropdown',
            label: 'button.more_button_label',
            buttons: [
                // {
                //     label: 'button.convert_to_proposal_button_label',
                //     action: 'convert_proposal',
                //     restrictedRight: ['only_view'],
                //     allowedFeature: ['proposals'],
                // },
                {
                    label: 'button.duplicate_invoice_button_label',
                    action: 'duplicate',
                    restrictedRight: ['only_view'],
                },
                {
                    label: 'button.delete_invoice_button_label',
                    action: 'delete',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                }

            ]
        },
        {   
            type: 'primary',
            label: 'button.send_invoice_label',
            action: 'send_invoice',
            restrictedRight: ['only_view'],
            customClass: 'sent_invoice',
            condition: [{ field: 'status_condition', values: ['invoice_created'] }]
        },
    ];

    // Define the summary of the modal
    const summary = false;

    // Define the sections of the modal
    // const sections: DetailCardType[] = [
    //     {
    //         component: 'FieldsCard',
    //         title: 'job.general.detail_card_header',
    //         alignment: 'horizontal',
    //         fieldColumns: [
    //             [
    //                 { 
    //                     type: 'searchselect', 
    //                     name: 'client',
    //                     label: 'job.general.client_label',
    //                     placeholder: 'job.general.client_placeholder',
    //                     objectName: 'client.general.object_name.singular',
    //                     query: {
    //                         // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
    //                         endpoint: 'get_contact_search',
    //                         params: {
    //                             contact_type: ['contact', 'prospect', 'client'] 
    //                         }
    //                     },
    //                     postEndpoint: 'post_contact',
    //                     viewModeLinkUrl: { function: determineLinkUrl },
    //                     selectionFormat: 'name',
    //                     optionFormat: {
    //                         title: { field: 'name', format: (value) => value },
    //                         subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
    //                     },
    //                 },
    //                 { 
    //                     type: 'searchselect', 
    //                     name: 'reporting_person',
    //                     label: 'job.general.reporting_person_label',
    //                     placeholder: 'job.general.reporting_person_placeholder',
    //                     objectName: 'person.general.object_name.singular',
    //                     query: {
    //                         // Only get persons and when a client is selected, get the persons connected to that contact
    //                         endpoint: 'get_person_search',
    //                         params: {
                                
    //                         }
    //                     },
    //                     postEndpoint: 'post_contact',
    //                     dataCondition: [{'client.entity_type': 'organization'}],
    //                     viewModeLinkUrl: { baseUrl: '/contacts/person' },
    //                     selectionFormat: 'name',
    //                     optionFormat: {
    //                         title: { field: 'name', format: (value) => value },
    //                     },
    //                     tooltipText: 'job.general.reporting_person_tooltipText'
    //                 },
    //                 { 
    //                     type: 'searchselect', 
    //                     name: 'invoice_address',
    //                     label: 'job.general.invoice_address_label',
    //                     placeholder: 'job.general.invoice_address_placeholder',
    //                     objectName: 'location.general.object_name.singular',
    //                     query: {
    //                         // Get all locations and when a client is selected, get the primary locations connected to that contact
    //                         endpoint: 'get_location_search',
    //                         params: {

    //                         }
    //                     },
    //                     postEndpoint: 'post_location',
    //                     dataCondition: [{'client.entity_type': 'organization'}],
    //                     viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
    //                     selectionFormat: 'address',
    //                     optionFormat: {
    //                         title: { field: 'address', format: (value) => value },
    //                     },
    //                 },
    //                 { 
    //                     type: 'searchselect', 
    //                     name: 'work_location',
    //                     label: 'job.general.work_location_label',
    //                     placeholder: 'job.general.work_location_placeholder',
    //                     objectName: 'location.general.object_name.singular',
    //                     query: {
    //                         // Get all locations
    //                         endpoint: 'get_location_search',
    //                         params: {
    //                         }
    //                     },
    //                     postEndpoint: 'post_location',
    //                     viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
    //                     selectionFormat: 'address',
    //                     optionFormat: {
    //                         title: { field: 'address', format: (value) => value },
    //                     },
    //                 },
    //                 { 
    //                     type: 'searchselect', 
    //                     name: 'location_person',
    //                     label: 'job.general.contact_at_location_label',
    //                     placeholder: 'job.general.contact_at_location_placeholder',
    //                     objectName: 'person.general.object_name.singular',
    //                     query: {
    //                         // Get all persons
    //                         endpoint: 'get_person_search',
    //                     },
    //                     postEndpoint: 'post_contact',
    //                     dataCondition: [
    //                         {'client.entity_type': 'organization'}
    //                     ],
    //                     viewModeLinkUrl: { baseUrl: '/contacts/person' },
    //                     selectionFormat: 'name',
    //                     optionFormat: {
    //                         title: { field: 'name', format: (value) => value },
    //                     },
    //                     tooltipText: 'job.general.contact_at_location_tooltipText'
    //                 },
    //             ],
    //             [
    //                 { 
    //                     type: 'dropdown', 
    //                     name: 'workflow', 
    //                     label: 'job.general.workflow_label', 
    //                     apiObject: 'workflow',
    //                     params: { is_active: true, connected_feature: 'job' },
    //                     selectionFormat: 'name',
    //                     optionFormat: (option: FieldOption) => option.name,
    //                     backendField: 'id',
    //                     activeFeature: 'workflows',
    //                     disabled_selected: 'job.general.workflow_disabled_selected',
    //                     allowNoneOption: false,
    //                     showSearch: false,
    //                 },
    //                 { 
    //                     type: 'dropdown', 
    //                     name: 'division', 
    //                     label: 'job.general.division_label', 
    //                     activeFeature: 'divisions',
    //                     disabled_selected: 'job.general.division_disabled_selected',
    //                     allowNoneOption: false,
    //                     showSearch: false,
    //                 },
    //                 { 
    //                     type: 'reference', 
    //                     name: 'reference',
    //                     label: 'job.general.reference_label',
    //                     tooltipText: 'job.general.reference_tooltip',
    //                 },
    //                 { 
    //                     type: 'multiselect', 
    //                     name: 'labels', 
    //                     label: 'job.general.labels_label', 
    //                     apiObject: 'label',
    //                     apiRequest: [{
    //                         endpoint: 'get_label_list',
    //                         object: 'label',
    //                         params: { is_active: true, connected_feature: 'jobs' }
    //                     }],
    //                     allowNoneOption: false,
    //                     showSearch: false,
    //                     disabled_selected: 'job.general.labels_disabled_selected',
    //                 },
    //                 { 
    //                     type: 'dropdown', 
    //                     name: 'billing_type', 
    //                     label: 'job.general.billing_type_label',
    //                     disabled_selected: 'forms.general_disabled_selected',
    //                     allowNoneOption: false,
    //                     showSearch: false,
    //                     hardcodedOptions: [
    //                         { value: 'calculation', name: 'job.billing_type.calculation'}, 
    //                         { value: 'fixed', name: 'job.billing_type.fixed'}, 
    //                         { value: 'non_billing', name: 'job.billing_type.non_billing'}, 
    //                     ],
    //                 },
    //                 {
    //                     type: 'target-date', 
    //                     name: 'target_date', 
    //                     label: 'job.general.target_date_label', 
    //                     // placeholder: 'job.general.target_date_placeholder', 
    //                 },
    //             ]
    //         ]
    //     },
    //     {
    //         component: 'FieldsCard',
    //         title: 'job.general.activities_header',
    //         alignment: 'stacked',
    //         fields: [
    //             { 
    //                 type: 'textarea', 
    //                 name: 'main_description', 
    //                 emptyLabel: 'job.general.main_description_emptyLabel',
    //                 placeholder: 'job.general.main_description_placeholder', 
    //                 enableStyling: 'extended',
    //                 showToolbarInitially: true,
    //                 rows: 2,
    //             },
    //             { 
    //                 type: 'textarea', 
    //                 name: 'internal_description', 
    //                 emptyLabel: 'job.general.internal_description_emptyLabel',
    //                 placeholder: 'job.general.internal_description_placeholder', 
    //                 rows: 2,
    //                 isNote: true,
    //                 enableStyling: 'extended',
    //                 showToolbarInitially: true,
    //             },
    //         ]
    //     },
    //     {
    //         component: 'FieldsCard',
    //         title: 'job.general.attachments_header',
    //         alignment: 'stacked',
    //         fields: [
    //             {
    //                 type: 'attachments',
    //                 name: 'attachments',
    //                 dropzoneText: 'job.general.attachments_placeholder',
    //                 apiRequest: [{ 
    //                     endpoint: 'get_attachmenttype_list', 
    //                     object: 'attachmenttype', 
    //                     params: { 'deleted': false }
    //                 }]
    //             }
    //         ]
    //     },
    //     {
    //         component: 'AppointmentsCard'
    //     },
    //     // {
    //     //     component: 'FieldsCard',
    //     //     title: 'job.items.items_label',
    //     //     fields: [
    //     //         { 
    //     //             name: 'items',
    //     //             type: 'fieldset',
    //     //             draggable: true,
    //     //             fields: [
    //     //                 {
    //     //                     name: 'quantity_registrated',
    //     //                     type: 'text',
    //     //                     label: 'job.items.quantity_label',
    //     //                     placeholder: 'job.items.quantity_placeholder'
    //     //                     width: '2fr',
    //     //                 }
    //     //                 // Other fields within the fieldset
    //     //             ]
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         component: 'FieldsCard',
    //         title: 'job.items.items_label',
    //         fields: [
    //             { 
    //                 name: 'items',
    //                 type: 'job-items',
    //                 jobId: itemId,
    //                 apiRequest: [{
    //                     endpoint: 'get_vatrate_list',
    //                     object: 'vatrate',
    //                     params: { is_active: true }
    //                 }]
    //             }
    //         ]
    //     },
    //     {
    //         component: 'FieldsCard',
    //         title: 'job.timerows.timerows_label',
    //         fields: [
    //             { 
    //                 name: 'timerows',
    //                 type: 'timerows',
    //                 jobId: itemId,
    //                 apiRequest: [
    //                     {
    //                         endpoint: 'get_timetype_list',
    //                         object: 'timetype',
    //                         params: { is_active: true, type: 'productive' }
    //                     },
    //                     {
    //                         endpoint: 'get_hourlyrate_list',
    //                         object: 'hourlyrate',
    //                         params: { is_active: true }
    //                     },
    //                     {
    //                         endpoint: 'get_user_list',
    //                         object: 'user',
    //                         params: { is_active: true, field_access: true }
    //                     }   
    //                 ]                 
    //             }
    //         ]
    //     },
    //     {
    //         component: 'PhotosCard'
    //     },
    //     // {
    //     //     component: 'MessagesCard'
    //     // },
    // ]
    
    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            // sections={sections}
            summary={summary}
        />
    );
};

export default InvoiceModal;