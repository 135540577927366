import React from 'react';
import TaskTimelineForm from '../../views/tasks/TaskTimelineForm';
import { TimelineUserType } from 'types/TimelineTypes';
import '../../style/scss/timeline.scss';

interface TimelineTaskFormProps {
    viewKey: string;
    timeline_page_id: string;
    timeline_page?: string;
    taskId?: number;
    createdBy?: TimelineUserType | null;
    createdDate?: string;
    modifiedBy?: TimelineUserType | null;
    modifiedDate?: string;
    isTaskFormOpen: boolean;
    setIsTaskFormOpen: (isFormOpen: boolean) => void;
    resetActiveButtonItem: () => void;
}

const TimelineTaskForm: React.FC<TimelineTaskFormProps> = ({ 
    viewKey, timeline_page_id, timeline_page, taskId, setIsTaskFormOpen, resetActiveButtonItem, createdBy, createdDate, modifiedBy, modifiedDate 
}) => {
    
    return (
        <TaskTimelineForm 
            viewKey={viewKey}
            taskId={taskId}
            timeline_page_id={timeline_page_id}
            timeline_page={timeline_page}
            createdBy={createdBy}
            createdDate={createdDate}
            modifiedBy={modifiedBy}
            modifiedDate={modifiedDate}
            onClose={() => {
                setIsTaskFormOpen(false);
                resetActiveButtonItem();
            }}
        />
    );
};

export default TimelineTaskForm;