import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const ReleaseForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'release'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'version',
            label: 'release.general.version_label',
            placeholder: 'release.general.version_placeholder',
        },
        {
            type: 'text',
            name: 'release_name',
            label: 'release.general.release_name_label',
            placeholder: 'release.general.release_name_placeholder'
        },
        { 
            type: 'dropdown', 
            name: 'type', 
            label: 'release.general.release_type_label',
            disabled_selected: 'forms.select_type_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'feature_release', name: 'release.release_type.feature_release'},
                { value: 'fix_release', name: 'release.release_type.fix_release'},
            ],
        },
        {
            type: 'date',
            name: 'release_date',
            label: 'release.general.release_date_label',
        },
        {
            type: 'checkbox',
            name: 'internal_release',
            label: 'release.general.internal_release_label',
            tooltipText: 'release.general.internal_release_tooltip'
        },
        { 
            type: 'dropdown', 
            name: 'status', 
            label: 'release.general.status_label',
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'published' },
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'draft', name: 'release.general.draft_release_label'},
                { value: 'published', name: 'release.general.published_release_label'},
            ],
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}       
        />
    );
};

export default ReleaseForm;