/*
 * convertHoursToDecimal.ts
 * Converts a frontend hours field to a backend decimal. To be used for time
 * duration fields to convert them back to a amount field to store in the
 * backend. For example: 7:45 hours is converted into 7.75. 50:61 hours is
 * converted into 51.0167.
 */

export const convertHoursAndMinutesToDecimal = (timeString: string): number => {
    // Convert filled in dots to colon (probably the user meant a colon)
    timeString = timeString.replace('.', ':'); 

    // Split the hours and minutes from the time string
    const [hoursString, minutesString] = timeString.split(':');

    // Convert the hours into an integer
    let hours = parseInt(hoursString, 10);

    // Convert the minutes into an integer, if there are no minutes, fallback to 0
    let minutes = minutesString ? parseInt(minutesString, 10) : 0;

    // If there are more than 59 minutes, count an extra hour
    if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
    }

    // Convert the hours and minutes to a decimal number
    const decimalNumber = hours + minutes / 60

    // Round the decimal number to 4 decimals after the comma
    const roundedDecimal = parseFloat(decimalNumber.toFixed(4));

    return roundedDecimal
};