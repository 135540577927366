import React, { useContext } from 'react';
import { ReactNode, createContext, useState } from 'react';
import { AlertType, FloatingAlertType } from 'types/AlertTypes';

/*
 * GlobalContext.tsx
 * This context is created as the top level global context for our React
 * app. The states and functions can be shared across components in the
 * whole application.
 */

interface GlobalContextType {
    // Keep track of the general alert on top of the form
    formAlert: AlertType | null;
    setFormAlert: (alert: AlertType | null) => void;

    // Keep track if a floating alert on the right of the screen has to be shown
    floatingAlert: FloatingAlertType | null;
    setFloatingAlert: (alert: FloatingAlertType | null) => void;

    // Keep track if a form has unsaved changes, to show the shakeElement function
    unsavedChangesMap: { [viewKey: string]: boolean };
    setUnsavedChanges: (viewKey: string, hasUnsavedChanges: boolean) => void;
    removeUnsavedChange: (viewKey: string) => void;

    // Keep track of error messages to be shown on top of a from or below fields
    errorMessages: any;
    setErrorMessages: (value: any) => void;
}

const initialState = {
    formAlert: null,
    setFormAlert: () => {},
    floatingAlert: null,
    setFloatingAlert: () => {},
    unsavedChangesMap: {},
    setUnsavedChanges: () => {},
    removeUnsavedChange: () => {},
    errorMessages: {},
    setErrorMessages: () => {},
}

const GlobalContext = createContext<GlobalContextType>(initialState);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [formAlert, setFormAlert] = useState<AlertType | null>(initialState.formAlert)
    const [floatingAlert, setFloatingAlert] = useState<FloatingAlertType | null>(initialState.floatingAlert)
    const [unsavedChangesMap, setUnsavedChangesMap] = useState<{ [viewKey: string]: boolean }>(initialState.unsavedChangesMap);
    const [errorMessages, setErrorMessages] = useState<Record<string, string | string[] | Record<string, string> | undefined>>(initialState.errorMessages);

    // Register the unsaved changes boolean in the unsaved changes map
    const setUnsavedChanges = (viewKey: string, hasUnsavedChanges: boolean) => {
        setUnsavedChangesMap(current => ({ ...current, [viewKey]: hasUnsavedChanges}));
    }

    // Remove a unsaved changes property for a certain view key from the unsaved changes map
    const removeUnsavedChange = (viewKey: string) => {
        setUnsavedChangesMap(current => {
            const newMap = { ...current };
            delete newMap[viewKey];
            return newMap;
        });
    }

    return (
        <GlobalContext.Provider value={{ formAlert, setFormAlert, floatingAlert, setFloatingAlert,
            unsavedChangesMap, setUnsavedChanges, removeUnsavedChange, errorMessages, setErrorMessages }}>
                {children}
        </GlobalContext.Provider>
    )
}

// Create a custom hook for the global context
export const useGlobalContext = () => {
    return useContext(GlobalContext)
}