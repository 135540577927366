import React from 'react';
import { createRoot } from 'react-dom/client';
import './internationalization/i18n';
import App from './App';
import 'style/scss/global-variables.scss';
import 'style/scss/default-elements.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);