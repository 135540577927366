import React from 'react';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';

// Rename the name of the function:
const ReleaseEditModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {

    // Specify the parameters of this detail modal:
    const apiObject = 'release';

    // Define the sections of the modal
    const sections: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'job.general.detail_card_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    {
                        type: 'text',
                        name: 'release_name',
                        label: 'release.general.release_name_label',
                        placeholder: 'release.general.release_name_placeholder'
                    },
                    {
                        type: 'date',
                        name: 'release_date',
                        label: 'release.general.release_date_label',
                    },
                ],
                [
                    { 
                        type: 'dropdown', 
                        name: 'type', 
                        label: 'release.general.release_type_label',
                        disabled_selected: 'forms.select_type_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                        hardcodedOptions: [
                            { value: 'feature_release', name: 'release.release_type.feature_release'},
                            { value: 'fix_release', name: 'release.release_type.fix_release'},
                        ],
                    },
                    { 
                        type: 'checkbox', 
                        name: 'internal_release', 
                        label: 'release.general.internal_release_label',
                        singleCheckbox: true
                    },
                    { 
                        type: 'dropdown', 
                        name: 'status', 
                        label: 'release.general.status_label',
                        disabled_selected: 'forms.select_type_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                        hardcodedOptions: [
                            { value: 'draft', name: 'release.general.draft_release_label'},
                            { value: 'published', name: 'release.general.published_release_label'},
                        ],
                    },
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'release.general.description_label',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'description', 
                    emptyLabel: 'release.general.description_emptyLabel',
                    placeholder: 'release.general.description_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        },
        {
            component: 'FieldsCard',
            title: 'release.general.change_log_label',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'change_log', 
                    emptyLabel: 'release.general.change_log_emptyLabel',
                    placeholder: 'release.general.change_log_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        }
    ]
    
    return (
        <DetailModal 
            viewKey={viewKey}
            apiObject={apiObject}
            itemId={itemId}
            sections={sections}
        />
    );
};

export default ReleaseEditModal;