import React, { useMemo } from 'react';
import InlineForm from 'components/forms/InlineForm';
import { FieldType } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { v4 as uuidv4 } from 'uuid';

const ForgotPasswordForm: React.FC = () => {
    const viewKey = useMemo(() => uuidv4(), []);

    const formHeader = 'account.forgot_password_header_label'
    const formDescription = 'account.forgot_password_header_description'

    const fields: FieldType[] = [
        {
            type: 'email',
            name: 'email',
            placeholder: 'account.your_email_placeholder',
        },
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'account.forgot_password_button_label',
            position: 'fullwidth',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'large',
            showLoader: true,
        },
    ];

    return (
        <InlineForm  
            viewKey={viewKey}
            apiObject='forgotpassword'
            formHeader={formHeader}
            formDescription={formDescription}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default ForgotPasswordForm;