import React, { useState, useEffect, useRef } from 'react';
import { DropdownButtonType } from 'types/ButtonTypes';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import '../../style/scss/button.scss';

const DropdownButton: React.FC<DropdownButtonType> = ({
    items, 
    label, 
    size = 'medium', 
    loading = false, 
    type = 'button', 
    activeItem,
    onlyViewRestriction,
    customClass
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Make the button invisible if it is restricted for only_view users
    const userHasOnlyViewRight = !useAllowedRight('only_view')
    const isVisible = onlyViewRestriction ? userHasOnlyViewRight : true;

    // Set the size, button and text loading classes
    const buttonSizeClass = `button-${size}`;
    const buttonLoadingClass = loading ? 'button-loading' : '';
    const textLoadingClass = loading ? 'text-hidden' : '';

    // Give the loader icon a delay, so it looks smoother
    const [showLoaderWithDelay, setShowLoaderWithDelay] = useState(false);
    useEffect(() => {
        let timer: number;
        if (loading) {
            timer = window.setTimeout(() => setShowLoaderWithDelay(true), 150)
        } else {
            setShowLoaderWithDelay(false);
        }
        return () => clearTimeout(timer);
    }, [loading]);

    // Close the dropdown when clicking outside it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        isVisible ? (
            <div className='dropdown-button' ref={dropdownRef}>
                <button type={type}
                        onClick={toggleDropdown}
                        className={`button ${buttonSizeClass} button-secondary ${customClass} ${isOpen ? 'button-active' : ''} ${buttonLoadingClass}`}
                        style={{ position: 'relative' }}
                        disabled={loading}>
                    <span className={`button-text ${textLoadingClass}`}>
                        {t(label)}
                    </span>
                    {showLoaderWithDelay && 
                        <div className='loader-container'>
                            <div className='button-loader' />
                        </div>
                    }
                    <FontAwesomeIcon
                        icon={!isOpen ? faCaretDown : faCaretUp}
                        style={{ marginLeft: '0.3rem', paddingBottom: '0.025rem' }}
                    />
                </button>
                {isOpen && (
                    <div className='dropdown-button-menu'>
                        {items.map((item, index) => (
                            <div key={index} className='dropdown-button-item' onClick={() => { item.onClick(); toggleDropdown() } } >
                                {t(item.label)}
                                {item.label === activeItem && (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className='filter-dropdown-icon' />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ) : null
    );
};

export default DropdownButton;