import { faHome } from '@fortawesome/free-solid-svg-icons';
import { NavigationMenuItem } from 'types/NavigationTypes';

// Define the menu items here
export const navigationConfig: { [key: string]: NavigationMenuItem } = {
    dashboard: { 
        name: 'nav.dashboard_label', 
        icon: faHome,
        route: '/',
        subMenu: [
            { name: 'nav.trial_page_label', route: '/trial-homepage' },
            { name: 'nav.beta_page_label', route: '/beta-homepage' },
            { name: 'nav.dashboard_label', route: '/dashboard' },
            { name: 'nav.admin_dashboard_label', route: '/admin-dashboard' },
        ]
    },
    proposals: {
        name: 'nav.sales_label',
        route: '/proposals',
        activeFeature: 'proposals',
    },
    scheduling: {
        name: 'nav.scheduling_label', 
        route: '/scheduling', 
        activeFeature: 'scheduling'
    },
    jobs: {
        name: 'nav.jobs_label',
        route: '/jobs',
        subMenu: [
            { name: 'nav.jobs_label', route: '/jobs', activeFeature: 'jobs' },
            { name: 'nav.contracts_label', route: '/contracts', activeFeature: 'contract_management' },
            // { name: 'nav.tasks_label', route: '/tasks' }
        ]
    },
    invoices: {
        name: 'nav.invoices_label',
        route: '/invoices',
        activeFeature: 'invoices',
    },
    team: { 
        name: 'nav.team_label', 
        route: '/timesheet',
        subMenu: [
            { name: 'nav.timesheet_label', route: '/timesheet', activeFeature: 'manage_timesheets' },
            { name: 'nav.employees_label', route: '/employees', activeFeature: 'hrm' },
            { name: 'nav.subcontractors_label', route: '/subcontractors', activeFeature: 'subcontractors' },
            { name: 'nav.leave_requests_label', route: '/leave-requests', activeFeature: 'hrm' },
            { name: 'nav.expenses_label', route: '/expenses', activeFeature: 'hrm' },
        ]
    },
    products: { 
        name: 'nav.products_label', 
        route: '/products', 
        subMenu: [
            { name: 'nav.products_label', route: '/products', activeFeature: 'products' },
            { name: 'nav.hourly_rates_label', route: '/hourly-rates', activeFeature: 'products' },
            // { name: 'nav.services_label', route: '/services', activeFeature: 'products' },
            { name: 'nav.inventory_label', route: '/inventory', activeFeature: 'inventory' },
            { name: 'nav.stockrooms_label', route: '/stockrooms', activeFeature: 'stockrooms', allowedFeature: 'inventory' },
            { name: 'nav.purchases_label', route: '/purchases', activeFeature: 'purchase_orders', allowedFeature: 'inventory'  },
            { name: 'nav.categories_label', route: '/product_categories', activeFeature: 'product_categories', allowedFeature: 'products' },
        ]
    },
    contacts: { 
        name: 'nav.contacts_label', 
        route: '/contacts', 
        subMenu: [
            { name: 'nav.contacts_label', route: '/contacts', activeFeature: 'contacts' },
            { name: 'nav.work_locations_label', route: '/contacts/work-locations', activeFeature: 'contacts' },
            // { name: 'nav.objects_label', route: '/contacts/objects', activeFeature: 'location_objects' },
        ]
    },
}