import { useEffect, useState } from 'react';
import { JobType } from '../../jobs/JobTypes';

/*
 * useJobInboxSpacerHeight.ts
 * A custom hook to calculate the spacer height of the job inbox.
 * This is nessecary to enable the scroll down function to load more
 * storage rows/weeks in case the already loaded rows does not match
 * the whole screen height and scrolling is impossible.
 */

const useJobInboxSpacerHeight = (
    jobInboxHeight: number, 
    visibleWeeks: string[], 
    inboxJobsByWeek: { [week: string]: JobType[] }
) => {
    const [spacerHeight, setSpacerHeight] = useState(0);

    useEffect(() => {
        const calculateSpacerHeight = () => {
            // Get the current height of the total job inbox
            const inboxElement = document.querySelector('.inbox') as HTMLElement;
            const inboxHeight = inboxElement ? inboxElement.offsetHeight : 0;

            // Get the current height of the inbox rows
            const inboxRowsElement = document.querySelector('.inbox-rows') as HTMLElement;
            const inboxRowsHeight = inboxRowsElement ? inboxRowsElement.offsetHeight : 0;

            // Calculate the spacer height
            const calculatedSpacerHeight = Math.max(inboxHeight - inboxRowsHeight, 0) + 50;

            // Set the spacer height
            setSpacerHeight(calculatedSpacerHeight);
        };

        // Initial calculation
        calculateSpacerHeight();

        // Recalculate when job inbox height, visible weeks, or inbox jobs by week changes
        const resizeObserver = new ResizeObserver(() => calculateSpacerHeight());
        const inboxElement = document.querySelector('.inbox') as HTMLElement;
        const inboxRowsElement = document.querySelector('.inbox-rows') as HTMLElement;

        if (inboxElement) {
            resizeObserver.observe(inboxElement);
        }

        if (inboxRowsElement) {
            resizeObserver.observe(inboxRowsElement);
        }

        return () => {
            if (inboxElement) {
                resizeObserver.unobserve(inboxElement);
            }
            if (inboxRowsElement) {
                resizeObserver.unobserve(inboxRowsElement);
            }
        };
    }, [jobInboxHeight, visibleWeeks, inboxJobsByWeek]);

    return spacerHeight;
};

export default useJobInboxSpacerHeight;