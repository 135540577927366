import React, { useState, useContext, useEffect } from 'react';
import { LocationsFieldsetType, FieldData, FieldOption } from 'types/FieldTypes';
import { AddressType, createEmptyAddress } from 'types/FieldsetTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import CustomSearchSelect from '../basefields/SearchSelect';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

interface LocationsFieldsetProps extends LocationsFieldsetType {
    object?: string;
    objectId: string | number | null | undefined;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
    handleFieldSubmit?: boolean;
    fieldSubmitted?: () => void;
    refetchData?: () => void;
}

const LocationsFieldset: React.FC<LocationsFieldsetProps & { data: FieldData, viewKey: string }> = ({
    viewKey, name, label = 'location.general.address_label', object, objectId, apiField = 'locations', 
    data, helperText, tooltipText, viewInEditMode, alignment, handleFieldSubmit, fieldSubmitted, refetchData,
    isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setEditing, updatedData, setButtonLoader, setUpdatedData, showErrorAlert, setShowErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges, setFloatingAlert } = useGlobalContext();
    const hasRightCheck = useAllowedRight;
    const [ addresses, setAddresses ] = useState<AddressType[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const defaultAddressType = 'billing';

    // If there are addresses in the fetched data, show the current ones
    useEffect(() => {
        if (data && data.primary_locations && data.primary_locations.length > 0) {
            // Set the addresses
            const fetchedAddresses = data.primary_locations.map((address: AddressType) => ({
                id: address.id,
                created: address.created,
                modified: address.modified,
                address: address.address,
                street: address.street,
                house_number: address.house_number,
                addition: address.addition,
                postal_code: address.postal_code,
                city: address.city,
                country: address.country,
                name: address.name,
                notes: address.notes,
                type: address.type,
                deleted: address.deleted,
                environment: address.environment
            }));
            setAddresses(fetchedAddresses)
            
            // Add the fetched addresses to the updated data
            setUpdatedData({ ...updatedData, [apiField]: [...fetchedAddresses] });
        // If there are no addresses, show an empty row
        } else if (editing || viewInEditMode) {
            const emptyAddress = createEmptyAddress(defaultAddressType);
            setAddresses([emptyAddress]);
        }
    }, [data]);

    const addressToFieldOption = (address: any): FieldOption => {
        return {
            id: address.id,
            value: address.address,
            name: 'address',
            address: address.address,
        };
    }
    
    // Helper function to update the addresses inside the updated data
    const updateUpdatedData = (item: any, index: number) => {
        const updatedAddressesArray = Array.isArray(updatedData[apiField]) 
            ? [...updatedData[apiField]] 
            : [];
            updatedAddressesArray[index] = item;
        setUpdatedData({ ...updatedData, [apiField]: updatedAddressesArray });
    };

    // Handle changes in the address field
    const handleAddressChange = (selectedItem: any, index: any) => {
        setUnsavedChanges(viewKey, true);
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = selectedItem;
        setAddresses(updatedAddresses);
        updateUpdatedData(updatedAddresses[index], index);
    };

    // Handle unlinking of an item
    const handleUnlinkItem = (index: number) => {        
        if (index >= 0 && index < addresses.length) {
            // Delete the item from the addresses list
            const newAddressesList = [...addresses];
            newAddressesList.splice(index, 1);
            setAddresses(newAddressesList);
        
            // Delete the item from the updated items
            const updatedItems = Array.isArray(updatedData[apiField]) ? [...updatedData[apiField]] : [];
            updatedItems.splice(index, 1);
        
            setUpdatedData({ ...updatedData, [apiField]: updatedItems });
        }
    };

    // Handle the creation of a new empty row
    const handleAddRow = () => {
        const newAddress = createEmptyAddress(defaultAddressType);
        const updatedAddresses = [...addresses, newAddress];
        setAddresses(updatedAddresses);
    }

    // Handle save of the field, currently only to link existing locations to the page object
    // TO DO: Create a submit function to add a new location when adding 'add new' in the SearchSelect field.
    const handleFieldSubmitFunction = async() => {
        try {
            if (setButtonLoader) setButtonLoader(true);

            // Patch the items and refetch the data after saving
            await saveData({ apiUrl: `patch_${object}/${objectId}`, method: 'patch', data: updatedData });
            if (refetchData) await refetchData();

            // Clear the updatedData to prevent resubmitting and duplicate items
            setUpdatedData({});

            // Clear the errors, show a success alert, set unsaved changes to false and close the edit mode
            setErrorMessages({});
            setFloatingAlert({ type: 'success' })
            setUnsavedChanges(viewKey, false);
            if (setEditing) setEditing(false);
        } catch (error) {
            const fieldNames = ['address'];
            const errorData = handleSaveErrors(error, fieldNames); 
            if (setShowErrorAlert) setShowErrorAlert(true)
            setErrorMessages(errorData);
            setUnsavedChanges(viewKey, true);
        } finally {
            if (setButtonLoader) setButtonLoader(false);
            // If the submitting is finished, reset the submitting status in the parent component
            if (fieldSubmitted) fieldSubmitted();
        }
    }

    // Executes the submit of the field, when the handleFieldSubmit property in the parent component is set to true
    useEffect(() => {
        if (handleFieldSubmit === true) handleFieldSubmitFunction();
    }, [handleFieldSubmit]);

    // Submits the widget field after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!errorMessages['address']) {
                handleFieldSubmitFunction();
            }
        }
    }

    // Mas address type to locale string
    const mapAddressTypeToLocaleString = (type: string) => {
        switch (type) {
            case 'billing':
                return 'location.type.billing_short';
            case 'worklocation':
                return 'location.type.worklocation';
            default:
                return '';
        }
    };

    const content = (
        <>
            <label htmlFor={name}>
                {t(label)}
                {tooltipText && 
                    (alignment === 'stacked' || (alignment === 'horizontal' && !editing)) &&
                    <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <span className="tooltip">{t(tooltipText)}</span>
                    </span>
                }
                {alignment === 'horizontal' && !editing && !viewInEditMode && 
                    <span className='edit-icon'>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                }
            </label>
            <div>
                {showErrorAlert &&
                    <div className="alert form-alert alert-danger" role="alert">
                        {t(errorMessages.general, { defaultValue: errorMessages.general })}
                    </div>
                }
                {(editing || viewInEditMode) ? (
                    // Edit mode
                    <div className='edit-mode'>
                        {addresses.map((address, index) => (
                            <div key={index} className='list-item'>
                                <div className='single-field'>
                                    <CustomSearchSelect 
                                        key={address.id}
                                        type='searchselect' 
                                        name='address'
                                        value={addressToFieldOption(address)}
                                        data={data}
                                        placeholder='location.general.search_location_label'
                                        objectName='location.general.object_name.singular'
                                        query={{ endpoint: 'get_location_list', params: { deleted: false }}}
                                        postEndpoint='post_location'
                                        disableAddNew={true}
                                        optionFormat={{ title: { field: 'address', format: (value) => value } }}
                                        selectionFormat='address'
                                        onSelect={selectedItem => {handleAddressChange(selectedItem, index)}}
                                    />
                                    <div className='delete-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => {handleUnlinkItem(index)}} />
                                    </div>
                                </div>
                                {errorMessages[index]?.address && 
                                    <div className='error-message'>
                                        {t(errorMessages[index]?.address, { defaultValue: errorMessages[index]?.address })}
                                    </div>
                                }
                            </div>
                        ))}
                        <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                            className="add-new-button">
                            {t('forms.add_another')}
                        </div>
                        {helperText && 
                            <div className="helper-text">
                                {t(helperText)}
                            </div>
                        }
                    </div>
                ) : (
                    // View mode
                    <div className="view-mode">
                        <span className='p'>
                            {addresses && addresses.length > 0 ? (
                                <>
                                    <div className='item-list'>
                                        {addresses.map((address, index) => (
                                            <p key={index}>
                                                {address.type === 'worklocation' ? (
                                                    // Only show worklocations as link, because other locations don't have a location page
                                                    <Link className='item-link' 
                                                          to={`/contacts/work-locations/${address.id}`}
                                                          key={address.id}>
                                                        {address.address}
                                                    </Link>
                                                ) : (
                                                    <span key={address.id}>{address.address}</span>
                                                )}
                                                {address.type ? <span className='type'> ({t(mapAddressTypeToLocaleString(address.type))})</span> : ''}
                                            </p>
                                        ))}
                                    </div>
                                    {alignment === 'stacked' &&
                                        <span className='edit-icon'>
                                            <FontAwesomeIcon icon={faPen} />
                                        </span>
                                    }
                                </>
                            ) : (
                                <span className="no-value">
                                    <span className='minus-sign'>-</span>
                                    <span className="hover-text">{t('forms.add_value')}</span>
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </>
    )

    return (
        <div className='widget-field'>
            { alignment === 'horizontal' 
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
}

export default LocationsFieldset;