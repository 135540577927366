import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { HeaderButtons } from 'types/ButtonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Rename the name of the function:
const LocationDetail: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'location'
    const pageObject = 'location'
    const statusBar = false
    const headerTitleDataField = 'address'

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        // {   
        //     type: 'secondary',
        //     label: 'button.merge_button_label',
        //     action: 'merge',
        //     size: 'medium'
        // },
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            tooltipText: 'general.delete',
            action: 'delete',
            onSuccess: 'redirect',
            redirectTo: '/contacts/work-locations',
            size: 'medium'
        },
    ]

    // Define the detail page tabs:
    const tabs: TabType[] = [ 
        { label: 'timeline.general.timeline_label', type: 'timeline', showOnlyTimelineUpdates: false },
        { label: 'job.general.jobs_label', type: 'jobs' },
        { label: 'document.general.documents_label', type: 'attachments' },
    ]

    // Define detail components and add fields to FieldGroup cards:
    const detailCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'location.general.details_card_label',
            alignment: 'horizontal',
            fields: [
                {
                    name: 'street',
                    label: 'location.general.street_label',
                    type: 'text',
                    placeholder: 'location.general.street_placeholder',
                },
                {
                    name: 'house_number',
                    label: 'location.general.house_number_label',
                    type: 'text',
                    placeholder: 'location.general.house_number_placeholder',
                },
                {
                    name: 'postal_code',
                    label: 'location.general.postal_code_label',
                    type: 'text',
                    placeholder: 'location.general.postal_code_placeholder',
                },
                {
                    name: 'city',
                    label: 'location.general.city_label',
                    type: 'text',
                    placeholder: 'location.general.city_placeholder',
                },
                {
                    name: 'country',
                    label: 'location.general.country_label',
                    type: 'text',
                    placeholder: 'location.general.country_placeholder',
                },
                {
                    name: 'name',
                    label: 'location.general.location_name_label',
                    type: 'text',
                    placeholder: 'location.general.location_name_placeholder',
                    tooltipText: 'location.general.location_name_tooltipText',
                },
                {
                    name: 'notes',
                    label: 'location.general.notes_label',
                    type: 'textarea',
                    placeholder: 'location.general.notes_placeholder',
                },
                {
                    type: 'dropdown', 
                    name: 'type', 
                    label: 'location.general.location_type_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    defaultValue: { value: 'worklocation' },
                    allowNoneOption: false,
                    hardcodedOptions: [
                        { value: 'worklocation', name: 'location.type.worklocation'},
                        { value: 'mailing', name: 'location.type.mailing'},
                        { value: 'visiting', name: 'location.type.visiting'},
                        { value: 'billing', name: 'location.type.billing'},
                    ],
                }
            ]
        },
        {
            component: 'LocationMap',
        },
    ]

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitleDataField={headerTitleDataField}
            headerButtons={headerButtons}
            statusBar={statusBar}
            detailCards={detailCards}
            tabs={tabs}
        />
    );
};

export default LocationDetail;