import React from 'react';
import { useFilteredDropdownOptionsByFeature } from 'services/permissions/permissionChecks';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const LabelForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const apiObject = 'label'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'label.general.name_label',
            type: 'text',
            placeholder: 'label.general.name_placeholder',
        },
        {
            name: 'connected_feature',
            label: 'label.general.connected_feature_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            allowNoneOption: false,
            hardcodedOptions: useFilteredDropdownOptionsByFeature([
                { value: 'people', name: 'label.general.connected_features.people' },
                { value: 'organizations', name: 'label.general.connected_features.organizations' },
                { value: 'jobs', name: 'label.general.connected_features.jobs', requiredFeature: 'jobs' },
                { value: 'proposals', name: 'label.general.connected_features.proposals', requiredFeature: 'proposals' },
                { value: 'invoices', name: 'label.general.connected_features.invoices', requiredFeature: 'invoices' },
            ]),
        },
        {
            name: 'color',
            label: 'label.general.color_label',
            type: 'tabs',
            showIconOnly: true,
            options: [
                { name: 'Dark blue', value: 'label background-dark-blue' },
                { name: 'Blue', value: 'label background-blue' },
                { name: 'Dark green', value: 'label background-dark-green' },
                { name: 'Green', value: 'label background-green' },
                { name: 'Yellow', value: 'label background-yellow' },
                { name: 'Orange', value: 'label background-orange' },
                { name: 'Red', value: 'label background-red' },
                { name: 'Gray', value: 'label background-gray' },
            ]
        },
        {
            name: 'is_active',
            label: 'label.general.is_active_label',
            type: 'checkbox',
            showIfFilled: true,
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default LabelForm;