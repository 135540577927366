import { generateWeekNumber } from "services/utils/dateTimeUtils";

/*
 * generateJobInboxWeeks.ts
 * A custom function to generate the job inbox weeks.
 * For the first/last week of the year it determines the year
 * based on the 4th day of the week.
 */

export const generateJobInboxWeeks = (weeksToGenerate: number, startWeek?: string, weekStartsOnSunday?: boolean): string[] => {
    const weeks = new Set<string>();
    let currentWeekStart: Date;

    // Determine the starting week
    if (startWeek) {
        // Parse the start week and set the current week start to the first day of that week
        const [year, week] = startWeek.split('-').map(Number);
        currentWeekStart = new Date(year, 0, 1 + (week - 1) * 7);
        while (currentWeekStart.getDay() !== 1) { // Find the first Monday
            currentWeekStart.setDate(currentWeekStart.getDate() + 1);
        }
    } else {
        // If no start week is provided, set current week start to the start of the current week
        currentWeekStart = new Date();
        while (currentWeekStart.getDay() !== 1) { // Find the first Monday
            currentWeekStart.setDate(currentWeekStart.getDate() - 1);
        }
    }

    // Determines the right year for the first week of the year.
    // The 4th day of the first year determines the first/last week number.
    function getISOWeekYear(date: Date): number {
        let dateObject = new Date(date.getTime());
        dateObject.setHours(0, 0, 0, 0);

        // Adjust to the nearest thursday based on ISO week date rules
        let dayOfWeek = dateObject.getDay();
        if (weekStartsOnSunday) {
            // Shift the date to the nearest thursday if the week starts on sunday
            dateObject.setDate(dateObject.getDate() + 4 - (dayOfWeek || 7));
        } else {
            // Shift the date to the nearest thursday if the week starts on monday
            dateObject.setDate(dateObject.getDate() + 3 - (dayOfWeek + 6) % 7);
        }

        return dateObject.getFullYear();
    }

    // Generate week identifiers for the specified number of weeks
    for (let i = 0; i < weeksToGenerate; i++) {
        const weekNumber = generateWeekNumber(currentWeekStart, weekStartsOnSunday);
        const year = getISOWeekYear(currentWeekStart);
        weeks.add(`${year}-${weekNumber}`);
        // Move to the start of the next week
        currentWeekStart.setDate(currentWeekStart.getDate() + 7);
    }

    return Array.from(weeks);
};