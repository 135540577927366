import { convertUTCToLocalDateTimeString } from "internationalization/timezoneConversions";

// Sort the given rows on the given date sort field
export const sortRowsByDate = <T>(
    rows: T[], 
    sortField: keyof T
): { sortedRows: T[] } => {

    // Sort the rows on the given sort field
    const sortedRows = rows

        // Create a copy of the array to avoid mutating the original array
        .slice()

        // Sort the rows
        .sort((a: T, b: T) => {
            // Convert sortField values to date objects, if present
            const dateA = a[sortField] ? new Date(a[sortField] as any) : null;
            const dateB = b[sortField] ? new Date(b[sortField] as any) : null;

            // Compare the date objects, handling null values to place empty fields at the end
            if (dateA && dateB) return dateA.getTime() - dateB.getTime();
            if (dateA && !dateB) return -1;
            if (!dateA && dateB) return 1;
            return 0;
        });

    return { sortedRows };
}

// Convert the start- and end-datetime fields of the given rows to local datetimes
export const convertRowsToLocalDateTimes = <T>(
    rows: T[],
    startField: keyof T,
    endField: keyof T,
    userLocale: string, 
    userTimezone: string):
{ rowsWithLocalDatetimes: T[] } => {

    // Convert the start- and end datetimes to the local timezone of the user
    const rowsWithLocalDatetimes = rows.map((row: T) => ({
        ...row,
        [startField]: row[startField] 
            ? convertUTCToLocalDateTimeString(row[startField] as string, userLocale, userTimezone)
            : null,
        [endField]: row[endField]
            ? convertUTCToLocalDateTimeString(row[endField] as string, userLocale, userTimezone)
            : null
    }));

    return { rowsWithLocalDatetimes };
}