import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import LocationForm from './LocationForm';

// Rename the name of the function:
const LocationList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_location_list',
        defaultParams: { 'deleted': false },
        object: 'location'
    }
    const objectName = 'location.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true
    
    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const formModal = <LocationForm />;
    const onRowClick = 'detailPage'
    const detailPageUrl = 'contacts/work-locations'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'address', label: 'location.general.address_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            detailPageUrl={detailPageUrl}
        />
    );
};

export default LocationList;