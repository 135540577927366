import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';

/*
 * PublicRoute
 * The purpose of this PublicRoute file is to extend the Routes
 * component so that we can make Public links which are not intent
 * to be seen by logged in users. For example, it's unwanted to
 * show te login form or create account form for users which are
 * logged in already.
 */

const PublicRoute: React.FC<RouteProps> = (props) => {
    const { isLoggedIn, redirectTo } = useAuthContext();

    if (isLoggedIn && redirectTo) {
        // Redirect to the start page if the user is already logged in
        return <Redirect to="/" />;
    } else {
        // Render the route if the user is not logged in
        return <Route {...props} />;
    }
};

export default PublicRoute;