import { useEffect, useState } from 'react';
import { SchedulingViewType } from '../SchedulingTypes';

/*
 * usePixelsPerMinute.ts
 * A custom hook to calculate the number of pixels in a minute,
 * based on the screensize of the user. This is needed to render
 * the appointment on the right time and give them the right
 * duration. The use of this hook is needed to update the amount
 * when the user changes the screensize.
 */

const useCalculatePixelsPerMinute = (
    calendarRef: React.RefObject<HTMLDivElement>, 
    view: SchedulingViewType, 
    times: string[] | undefined
) => {
    const [pixelsPerMinute, setPixelsPerMinute] = useState<number | undefined>(undefined);

    const calculatePixelsPerMinute = () => {
        // If the needed properties are not available, return undefined
        if (!calendarRef.current || view.viewType !== 'employee_timeline' || !times) {
            return undefined;
        }

        // Get the total calendar width and the name column width of a resource
        const calendarWidth = calendarRef.current.offsetWidth;
        const nameColumnWidth = 200;

        // Get the number of visible days and times of the calendar
        const visibleDays = view.showDays === 'all' ? 7 : view.showDays === 'mon-sat' ? 6 : 5;
        const timesPerDay = times.length;

        // Calculate the total width of the schedulable part of the calendar
        const calendarColumnsWidth = calendarWidth - nameColumnWidth;

        // Calculate the width of a day column
        const dayColumnWidth = calendarColumnsWidth / visibleDays;

        // Calculate the width of a timeslot
        const timeSlotWidth = dayColumnWidth / timesPerDay;

        // Calculate the width of a minute, timeslots are 15 minutes
        const pixelsPerMinute = timeSlotWidth / 15;

        setPixelsPerMinute(pixelsPerMinute);
    };

    useEffect(() => {
        // Create a new resize observer which is called on resizes
        const resizeObserver = new ResizeObserver(() => {
            calculatePixelsPerMinute();
        });

        if (calendarRef.current) {
            resizeObserver.observe(calendarRef.current);
        }

        // Calculate the initial value
        calculatePixelsPerMinute();

        // Clear on unmount
        return () => {
            if (calendarRef.current) resizeObserver.unobserve(calendarRef.current);
        };
    }, [calendarRef, view, times]);

    return pixelsPerMinute;
}

export default useCalculatePixelsPerMinute;