import React, { useEffect, useRef, useState } from 'react';
import { useFetchData } from 'services/api/useFetchData';

interface PdfLayoutExampleProps {
    itemId: string;
    reloadPdf: number | null;
}

const PdfLayoutExample: React.FC<PdfLayoutExampleProps> = ({ itemId, reloadPdf }) => {
    const shadowRootRef = useRef<HTMLDivElement>(null);
    const [htmlContent, setHtmlContent] = useState<string | null>(null);
    const { response, loading, refetch } = useFetchData({ apiUrl: `generate-pdf-html/${itemId}` })
    const prevReloadCounter = useRef(reloadPdf);

    // When the pdf-html example is fetched, put it inside the html content
    useEffect(() => {
        if (response) {
            setHtmlContent(response.html);
        }
    }, [response]);

    // Create the shadow root
    useEffect(() => {
        if (shadowRootRef.current && htmlContent) {
            // Only create a new shadow root if it not yet exist
            if (!shadowRootRef.current.shadowRoot) {
                const shadowRoot = shadowRootRef.current.attachShadow({ mode: 'open' });
                shadowRoot.innerHTML = htmlContent;
            } else {
                // If a shadow root already exists, just update it
                shadowRootRef.current.shadowRoot.innerHTML = htmlContent;
            }
        }
    }, [htmlContent]);

    // Refetch the pdf-html example when the reload counter is updated
    useEffect(() => {
        if (prevReloadCounter.current !== reloadPdf) {
            refetch();
            prevReloadCounter.current = reloadPdf;
        }
    }, [reloadPdf]);

    // Set the style of the shadow root container
    const containerStyle: React.CSSProperties = {
        backgroundColor: 'white',
        width: '794px',
        height: '1123px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        // transform: 'scale(0.75)', // Scale to 75% for smaller screens
        transformOrigin: 'top left',
    };

    return (
        <div style={containerStyle} ref={shadowRootRef}></div>
    );
};

export default PdfLayoutExample;