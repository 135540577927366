import React, { useContext, useState, useEffect } from 'react';
import { NumberFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { useTranslation } from 'react-i18next';
import FieldViewMode from './elements/FieldViewMode';
import FieldWrapper from '../FieldWrapper';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface NumberFieldProps extends NumberFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const NumberField: React.FC<NumberFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, placeholder, onChange, viewInEditMode, shouldAutoFocus,
    min, max, disabled, alignment, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState('');
    const [displayValue, setDisplayValue] = useState('');

    // Updates the value when the data is later received then the initialization of the useState
    useEffect(() => {
        if (data && data[name] !== undefined) {
            const formattedValue = formatForInput(String(data[name]));
            if (formattedValue !== value) {
                setValue(formattedValue);
                setDisplayValue(formatForDisplay(formattedValue));
            }
        }
    }, [data, name]);

    // Formats the displayed input by inserting dots every 3 digits, and separating the decimal part by a comma
    function formatForDisplay(input: string) {
        // Removes all dots from the input
        const inputWithoutDots = input.replace(/\./g, '');
        
        // Splits the input into a whole part, comma (if any), and a decimal part (if any)
        let whole, comma, decimal;
        const commaIndex = inputWithoutDots.indexOf(',');
        if (commaIndex !== -1) {
            whole = inputWithoutDots.substring(0, commaIndex);
            comma = ',';
            decimal = inputWithoutDots.substring(commaIndex + 1);
        } else {
            whole = inputWithoutDots;
        }
    
        // Reverse the whole part for easier formatting
        const reverseWhole = whole.split('').reverse().join('');
        // Inserts a dot every three digits, and reverses back the string
        const formattedWhole = reverseWhole.replace(/(\d{3}(?=\d))/g, '$1.').split('').reverse().join('');

        // Returns the formatted number with the comma if only a comma is present
        if (!decimal && comma) {
            return `${formattedWhole}${comma}`;
        }
    
        // Returns the formatted number with both the comma and decimal part if the decimal part is present
        return decimal ? `${formattedWhole}${comma}${decimal}` : `${formattedWhole}`;
    }

    // Prepares the value for input by replacing dots with commas, and inserting dots every three digits
    const formatForInput = (value: string) => {
        return value.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    // Prepares the value for sending to the server by removing all dots, and replacing the comma with a dot
    const formatForServer = (value: string) => {
        return value.replace(/\./g, '').replace(',', '.');
    }

    // Handles the change of the input by updating the value, checking the validity of the input, and updating the state accordingly
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Unsaved changes check
        setUnsavedChanges(viewKey, value !== data[name]);

        if (value === '') {
            setValue('');
            setDisplayValue('');
            setErrorMessages((prevMessages: any) => ({ ...prevMessages, [name]: undefined }));
            setUpdatedData({...updatedData, [name]: ''});
        } else {
            setValue(value);
            setDisplayValue(formatForDisplay(value));
            if (onChange) {
                onChange(e);
            }
            setErrorMessages((prevMessages: any) => ({ ...prevMessages, [name]: undefined }));
            setUpdatedData({...updatedData, [name]: formatForServer(value)});
        }
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <input
                    type="text"
                    id={name}
                    name={name}
                    min={min}
                    max={max}
                    placeholder={t(placeholder)}
                    value={displayValue}
                    onChange={handleInputChange}
                    className={errorMessage ? 'is-invalid' : ''}
                    autoFocus={shouldAutoFocus}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={displayValue} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default NumberField;