import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const ContactSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Voorkeurs contact entiteit',
            explanationText: 'Bepaal de voorkeurs contact entiteit voor de contacten die je aanmaakt. Op deze manier staat de keuze in het entiteit veld bij het aanmaken van een contact standaard op `organisatie` of `persoon`.',
            fields: [
                {
                    type: 'dropdown',
                    name: 'contact_default_entity_type',
                    allowNoneOption: false,
                    showSearch: false,
                    defaultValue: { value: 'organization' },
                    hardcodedOptions: [
                        { value: 'organization', name: 'Organisaties' },
                        { value: 'person', name: 'Personen' },
                    ]
                }
            ]
        }
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default ContactSettings;