/*
 * convertDecimalToHoursAndMinutes.ts
 * Formats a backend decimal field to hours and minutes in the frontend. To be
 * used for time amount fields. For example the estimated duration field of the
 * job. This shows a total amount of hours, which can be more than 24 hours so
 * need decimal field in the backend.
 */

export const formatDecimalToHoursAndMinutes = (decimalHours: number | string): string => {
    // Convert the hours to a number if it's a string
    const hoursAsNumber = typeof decimalHours === 'string' ? parseFloat(decimalHours) : decimalHours;

    // Check if the conversion to number is valid
    if (isNaN(hoursAsNumber)) {
        return 'Invalid input';
    }

    // Convert the decimal hours to minutes
    const totalMinutes = Math.floor(hoursAsNumber * 60);

    // Calculate the hours
    const hours = Math.floor(totalMinutes / 60);

    // Calculate the minutes
    const minutes = totalMinutes % 60;

    // Format the time with a colon
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return formattedTime;
}