import { WidgetConfig } from "types/WidgetTypes";
import { StatusesTodosConfig } from "./ToDoList";

/*
 * dashboardConfig.ts
 * Config of the items included in the dashboard.
 */

// Config of the default widgets shown on the dashboard
export const widgetConfig: WidgetConfig[] = [
    {
        widgetKey: 'openJobs',
        variant: 'numberWidget',
        title: 'dashboard.widget.open_jobs.title',
        tooltipText: 'dashboard.widget.open_jobs.tooltip_text',
        query: {
            default: { endpoint: 'get_open_job_ids', dataType: 'ids' }
        },
        calculationMethod: 'count',
        measuringUnit: 'dashboard.widget.open_jobs.measuring_unit',
        moreIsNegative: true,
    },
    {
        widgetKey: 'productiveHours',
        variant: 'numberWidget',
        title: 'dashboard.widget.productive_hours.title',
        tooltipText: 'dashboard.widget.productive_hours.tooltip_text',
        query: {
            default: { endpoint: 'get_productive_hours', dataType: 'idValuePairs', valueField: 'duration' },
            dimension: { endpoint: 'get_active_field_worker_ids', dataType: 'ids' }
        },
        calculationMethod: 'divide',
        measuringUnit: 'dashboard.widget.productive_hours.measuring_unit',
        decimals: 1,
    },
    // {
    //     widgetKey: 'revenuePerJob',
    //     variant: 'numberWidget',
    //     title: 'dashboard.widget.revenue_per_job.title',
    //     query: {
    //         default: { endpoint: 'get_job_revenue', dataType: 'idValuePairs', valueField: 'budget' }
    //     },
    //     calculationMethod: 'average',
    //     prefix: '€',
    //     decimals: 2,
    // },
    // {
    //     widgetKey: 'totalRevenue',
    //     variant: 'numberWidget',
    //     title: 'dashboard.widget.total_revenue.title',
    //     query: {
    //         default: { endpoint: 'get_job_revenue', dataType: 'idValuePairs', valueField: 'budget' }
    //     },
    //     calculationMethod: 'count',
    //     prefix: '€',
    // },
];

// Config of the features to include in the workflow-bar and todo-list components
export const statusFeatureConfig: { [featureKey: string]: string } = {
    // proposal: 'proposals',
    job: 'jobs',
    // invoice: 'invoices'
};

// Config of the status conditions of each feature mapped to the todos
export const statusesTodosConfig: StatusesTodosConfig = {
    // proposal: {  // To be implemented
    //     allowedFeature: 'proposals',
    //     todos: [
    //         {
    //             status_condition: ['proposal_created'],
    //             label: 'dashboard.todos.proposal.proposals_to_follow_up'
    //         },
    //         {
    //             status_condition: ['proposal_accepted'],
    //             label: 'dashboard.todos.proposal.won_orders_to_process'
    //         }
    //     ]
    // },
    // purchase: {  // To be implemented
    //     allowedFeature: 'purchases',
    //     todos: [
    //         {
    //             status_condition: ['purchase_created'],
    //             label: 'dashboard.todos.purchase.purchases_to_send'
    //         },
    //         {
    //             status_condition: ['purchase_pending', 'purchase_expired'],
    //             label: 'dashboard.todos.purchase.purchases_to_be_booked'
    //         }
    //     ]
    // },
    job: {
        allowedFeature: 'jobs',
        todos: [
            {
                status_condition: ['job_created', 'all_purchases_delivered'],
                label: 'dashboard.todos.job.jobs_to_be_scheduled'
            },
            {
                status_condition: ['incomplete'],
                label: 'dashboard.todos.job.appointments_to_reschedule'
            },
            {
                status_condition: ['completed'],
                label: 'dashboard.todos.job.workorders_to_verify'
            }
        ]
    },
    // invoice: {  // To be implemented
    //     allowedFeature: 'invoices',
    //     todos: [
    //         {
    //             status_condition: ['invoice_created'],
    //             label: 'dashboard.todos.invoice.invoice_to_be_sent'
    //         },
    //         {
    //             status_condition: ['invoice_expired'],
    //             label: 'dashboard.todos.invoice.invoice_reminders_to_be_sent'
    //         },
    //         {
    //             status_condition: ['invoice_paid'],
    //             label: 'dashboard.todos.invoice.invoice_payments_to_check'
    //         }
    //     ]
    // }
}