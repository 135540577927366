import { getDecryptedLocalStorageItem } from "./encryptData";

export const refreshStatesFromLocalStorage = (setStateFunctions: Record<string, Function>) => {
    // Map the keys from the local storage to the setState functions
    const keysToSetters = {
        'user_hash': setStateFunctions.setUserHash,
        'first_name': setStateFunctions.setFirstName,
        'last_name': setStateFunctions.setLastName,
        'environment_hash': setStateFunctions.setEnvironmentHash,
        'package': setStateFunctions.setEnvironmentPackage,
        'type': setStateFunctions.setEnvironmentType,
        'status': setStateFunctions.setEnvironmentStatus,
        'active_features': setStateFunctions.setActiveFeatures,
        'access_to': setStateFunctions.setAccessTo,
        'allowed_features': setStateFunctions.setAllowedFeatures,
        'allowed_rights': setStateFunctions.setAllowedRights,
        'redirect_to': setStateFunctions.setRedirectTo,
    };

    // Loop through each storage item to decrypt them and pass them back with the set state function
    Object.entries(keysToSetters).forEach(([key, setStateFunction]) => {
        if (setStateFunction) {
            const decryptedLocalStorageValue = getDecryptedLocalStorageItem(key);
            if (decryptedLocalStorageValue) {
                try {
                    // First try to parse in case it's a JSON field
                    const parsedValue = JSON.parse(decryptedLocalStorageValue);
                    setStateFunction(parsedValue);
                } catch (error) {
                    // In case it's not a JSON field, just take the value
                    setStateFunction(decryptedLocalStorageValue);
                }
            }
        }
    });
};