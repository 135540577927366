import React from "react";
import { FormAlertType } from "types/AlertTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import '../../style/scss/alert.scss';

const FormAlert: React.FC<FormAlertType> = ({ type, message, onClose }) => {
    return (
        <div className={`alert form-alert alert-${type}`} 
             role="alert">
            {message}
            <FontAwesomeIcon
                icon={faTimes}
                className='close-alert'
                style={{ cursor: 'pointer' }}
                onClick={onClose}
            />
        </div>
    );
};

export default FormAlert;