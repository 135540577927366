import React, { useContext, useState, useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import FormFieldContext from '../FormFieldContext';
import { DateFieldType, FieldData } from 'types/FieldTypes';
import FieldWrapper from '../FieldWrapper';
import DateInput from '../basefields/DateInput';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';
import "react-datepicker/dist/react-datepicker.css";

interface DateFieldProps extends DateFieldType {
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const DateField: React.FC<DateFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, convertTimezone, utcDatetime, localDatetime, helperText, 
    tooltipText, placeholder, viewInEditMode, min, max, showIfFilled, isEditable, disabled, 
    alignment
}) => {
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { setUnsavedChanges } = useGlobalContext();
    const { userLocale } = useSettings();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const minDate = min ? new Date(min) : undefined;
    const maxDate = max ? new Date(max) : undefined;

    // Set the current value from the received data
    useEffect(() => {
        if (data && data[name]) {
            const dateValue = new Date(data[name]);

            // Check if the date value is a legit date object
            if (!isNaN(dateValue.getTime())) {
                setSelectedDate(dateValue);
            } else {
                setSelectedDate(null);
            }
        };
    }, [data, name]);

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!selectedDate)) {
        return null;
    }

    // Handles the date change
    const handleDateChange = (dateString: string | null) => {
        if (dateString === null) {
            // If the date is deleted, set it to null
            setSelectedDate(null);
            setUpdatedData({ ...updatedData, [name]: null })
        } else {
            const parsedDate = new Date(dateString);
            if (!isNaN(parsedDate.getTime())) {
                setSelectedDate(parsedDate);
            } else {
                setSelectedDate(null);
            }
        }
    
        // If there are changes, set unsaved changes and updated data
        const hasChanged = dateString !== (data[name] ? new Date(data[name]).toISOString().split('T')[0] : null);
        setUnsavedChanges(viewKey, hasChanged);
        if (hasChanged) {
            setUpdatedData({ ...updatedData, [name]: dateString });
        }
    };

    // Format the selectedDate
    const fieldValue = selectedDate && selectedDate.toLocaleDateString(userLocale, {year: 'numeric', month: '2-digit', day: '2-digit'});

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <DateInput
                    id={name}
                    name={name}
                    value={selectedDate}
                    convertTimezone={convertTimezone}
                    utcDatetime={utcDatetime}
                    localDatetime={localDatetime}
                    onChange={(date) => handleDateChange(date)}
                    placeholder={placeholder}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={fieldValue} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default DateField;