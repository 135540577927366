import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useHistory } from 'react-router-dom';
import { ReleaseType } from '../../views/dashboard/releases/ReleaseTypes';
import ReleaseViewModal from '../../views/dashboard/releases/ReleaseViewModal';
import ReleaseTypeBadge from '../../views/dashboard/releases/functions/ReleaseTypeBadge';
import { formatDateString } from 'services/utils/dateTimeUtils';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/scss/dashboard.scss';

interface ReleasesProps {
    releases?: ReleaseType[];
    loading: boolean;
}

const ReleaseCard: React.FC<ReleasesProps> = ({ releases = [], loading }) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { userLocale } = useSettings();
    const history = useHistory();

    // Open the release modal
    const openReleaseModal = (itemId: number) => {
        initializeModal(
            React.createElement(ReleaseViewModal, { itemId }), {
                itemId, modalSize: 'medium-large'
            }
        )
    };

    return (
        <div className='releases'>
            <h5>{t('dashboard.general.whats_new_title')}</h5>
            <div className='section'>
                {loading ? (
                    // Show loader when the data is being fetched
                    <div className='loader-container'>
                        <div className="loader" />
                    </div>
                ) : (
                    releases.length > 0 ? (
                        <>
                            {releases.map((release: ReleaseType) => (
                                <div className='release'
                                     onClick={() => openReleaseModal(release.id)}>
                                    <div className='header'>
                                        <div className='title'>
                                            <span>{release.version}</span>
                                            <span style={{ margin: '0 5px' }}>•</span>
                                            <span>{formatDateString(release.release_date, userLocale)}</span>
                                        </div>
                                        <div>
                                            <ReleaseTypeBadge releaseType={release.type} />
                                        </div>
                                    </div>
                                    {release.description && (
                                        <p className='description'>
                                            {release.release_name && (
                                                <>
                                                    <span>{release.release_name}</span>
                                                    <span style={{ margin: '0 5px' }}>•</span>
                                                </>
                                            )}                                        
                                            {release.description_plain}
                                        </p>
                                    )}  
                                </div>                              
                            ))}
                            <div className='release-list-link'
                                 onClick={() => history.push(`/releases`)}>
                                <div className="release-link">
                                    {t('release.general.show_all_releases_label')}
                                    <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className='link-arrow'
                                    />
                                </div>
                            </div>    
                        </>
                    ) : (
                        <p>{t('dashboard.general.no_releases_label')}</p>
                    )
                )} 
            </div>
        </div>
    );
};

export default ReleaseCard;