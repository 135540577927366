/*
 * parsePriceString.ts
 * This function converts a price string into a float, making sure it's
 * stored correctly in the database with a period (".") as the decimal
 * separator. It adapts to the user's locale, where some countries use
 * a comma (",") and others use a period (".") for decimals. The function 
 * also handles common mistakes. For example, if a user in a country that 
 * uses periods for decimals enters "65,5", it assumes they meant "65.5". 
 * If they enter "65,500", it assumes they meant "65500". The same logic 
 * applies in reverse for locales that use commas for decimals. This 
 * ensures the price is always stored correctly in the backend, no matter 
 * how the user inputs it.
 */

export function parsePriceStringOnUserLocale(priceString: string, userLocale: string): number | null {

    // Use the number format to detect if the decimal separator is a comma or period
    const formatter = new Intl.NumberFormat(userLocale);
    const parts = formatter.formatToParts(12345.6);
    
    let groupSeparator = '';
    let decimalSeparator = '';
    
    for (const part of parts) {
        if (part.type === 'group') {
            groupSeparator = part.value;
        } else if (part.type === 'decimal') {
            decimalSeparator = part.value;
        }
    }

    // Determine the amount of digits after the separator
    const countAfterSeparator = priceString.split(/[,\.]/).pop()?.length || 0;

    let sanitizedValue = priceString;

    // Handle the case where the input contains a period where the comma is the decimal separator based on the local
    if (decimalSeparator === ',' && priceString.includes('.')) {
        if (countAfterSeparator > 2) {
            sanitizedValue = sanitizedValue.replace(/\./g, '');
        } else {
            sanitizedValue = sanitizedValue.replace('.', ',');
        }
    }

    // Handle the case where the input contains a comma where a period is the decimal separator based on the local
    if (decimalSeparator === '.' && priceString.includes(',')) {
        if (countAfterSeparator > 2) {
            sanitizedValue = sanitizedValue.replace(/,/g, '');
        } else {
            sanitizedValue = sanitizedValue.replace(',', '.');
        }
    }

    // Remove the group seperator for thousands
    sanitizedValue = sanitizedValue.replace(new RegExp(`\\${groupSeparator}`, 'g'), '');

    // Replace the decimal separator by a period
    sanitizedValue = sanitizedValue.replace(new RegExp(`\\${decimalSeparator}`), '.');

    // Convert the sanitized value to a float
    const parsedValue = parseFloat(sanitizedValue);

    return parsedValue;
}