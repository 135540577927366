import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import AttachmentTypeForm from './AttachmentTypeForm';

// Rename the name of the function:
const AttachmentTypeList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_attachmenttype_list',
        defaultParams: { 'deleted': false },
        object: 'attachmenttype'
    }
    const objectName = 'attachment_type.general.object_name'
    const showSearch = false
    const isDraggable = true
    const showDeleteOrDeactivate = 'flag_deleted'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <AttachmentTypeForm />;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'attachment_type.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            isDraggable={isDraggable}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default AttachmentTypeList;