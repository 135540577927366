import React from 'react';
import { FieldsCardProps } from 'types/DetailPageTypes'; 
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { baseUrl } from 'App';
import AttachmentViewer from 'components/forms/fieldsets/AttachmentViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/card.scss';

interface PhotoType {
    id: number;
    created: Date;
    modified: Date;
    file: string;
    filename: string;
    description: string;
    job: number | null;
    deleted: boolean;
}

const PhotosCard: React.FC<FieldsCardProps> = ({ 
    viewKey, itemId, isEditable, data, loadStatus 
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();

    // Function to format the date based on the local timezone and format
    const formatDate = (dateString: Date) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    // Handle viewing of a photo
    const handleViewPhoto = (event: React.MouseEvent, photo: PhotoType) => {
        // Prevent edit-view mode toggling
        event.preventDefault();
        event.stopPropagation();

        // Open the attachment view modal
        initializeModal(<AttachmentViewer file={photo.file} filename={photo.filename} type='photo' />, { modalSize: 'medium-large' } )
    }

    return (
        data?.photos?.length > 0 ? (
            <div className='card photoscard'>
                <div className='photos-header'>
                    <h6>{t('job.photos.photos_label')}</h6>
                    <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <span className="tooltip tooltip-right">{t('job.photos.photos_explanation_tooltip')}</span>
                    </span>
                </div>
                {loadStatus === "loading" ? (
                    <div className="loader"></div>
                ) : loadStatus === "loaded" && data && data.photos && (
                    <div className='photos-list'>
                        {data.photos.filter((photo: PhotoType) => !photo.deleted).map((photo: PhotoType) => (
                            <div key={photo.id}
                                onClick={e => handleViewPhoto(e, photo)}
                                className='photo-box tooltip-icon'>
                                <img src={`${baseUrl}${photo.file}`} 
                                     alt={photo.filename}
                                     className='photo' />
                                <span className="tooltip tooltip-bottom">
                                    <div>{photo.filename}</div>
                                    {photo.description && <div>{photo.description}</div>}
                                    <div>{t('job.photos.photo_added_label')} {formatDate(photo.created)}</div>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ) : (null)
    );
}

export default PhotosCard;