import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'GlobalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useModal } from 'components/modals/ModalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { v4 as uuidv4 } from 'uuid';
import { fetchDataAndOptionsOfDetailCards } from 'services/api/fetchFieldData';
import { FieldType, FieldOptionFetchResponse, FieldOption } from 'types/FieldTypes';
import { DetailPageProps, DetailPageItemId } from 'types/DetailPageTypes';
import generateHeaderButtons from 'components/modals/functions/generateHeaderButtons';
import { TabBar } from './TabBar';
import LiveEditField from 'components/forms/LiveEditField';
import FieldsCard from 'components/detailcards/FieldsCard';
import PeopleCard from 'components/detailcards/PeopleCard';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/scss/detailpage.scss';

const DetailPage: React.FC<DetailPageProps> = ({ 
    apiObject, pageObject, headerTitle, headerTitleDataField, headerTitleFields, headerButtons, statusBar, detailCards, tabs 
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const viewKey = useMemo(() => uuidv4(), []);
    const { id } = useParams<DetailPageItemId>();
    const { setFloatingAlert } = useGlobalContext();
    const { activeFeatures, allowedFeatures, allowedRights, handleLogout } = useAuthContext();
    const { initializeModal } = useModal();
    const hasRightCheck = useAllowedRight;
    const itemId = id;
    const [data, setData] = useState<FieldType[]>([])
    const [header, setHeader] = useState<string | null>(null);
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [reloadTabs, setReloadTabs] = useState(0);
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([])
    const [buttonLoader, setButtonLoader] = useState<Record<string, boolean>>({});
    const [editable] = useState<boolean>(!hasRightCheck('only_view'));

    // Fetch the data of the fields and dropdown options of the page 
    useEffect(() => {
        setLoadStatus("idle");
        fetchData();
        // eslint-disable-next-line
    }, []);

    // Function to fetch the data to be used on initial render and for refetches
    const fetchData = async () => {
        return fetchDataAndOptionsOfDetailCards(
            apiObject, 
            itemId, 
            dropdownData, 
            groupOptions, 
            headerButtons,
            detailCards, 
            setData, 
            setDropdownData, 
            setGroupOptions, 
            setLoadStatus,
            handleLogout,
            setHeader,
            headerTitleDataField
        ); 
    }

    // Handle the refetch if needed
    const handleRefetch = async () => {
        await fetchData();
        setReloadTabs(prev => prev + 1);
    };

    // Initialize the tab bar if there are tabs given
    let tabLabel, tabComponent;
    if (tabs) {
        ({ tabLabel, tabComponent } = TabBar({tabs, viewKey, itemId: id, pageContext: pageObject, reloadTabs}));
    }

    // Gives the loader a delay to smoothen fast transactions
    useEffect(() => {
        if (loadStatus === "idle") {
            const timer = setTimeout(() => {
                setLoadStatus("loading")
            }, 250);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [loadStatus]);

    // Generates the card components
    const generateCardComponent = (componentType: string, fields: FieldType[], title: string | undefined, itemId: string, pageObject: string, viewKey: string, data: any, apiField: string | undefined, alignment: 'horizontal' | 'stacked' | undefined, dataCondition?: { [key: string]: any }[]) => {
        // If a data condition is set for the card, only render it if the corresponding data object has this value
        if (dataCondition && data) {
            const shouldDisplayCard = dataCondition.every((condition: { [key: string]: any }) =>
                Object.entries(condition).every(([key, value]) => data[key] === value)
            );
            
            if (!shouldDisplayCard) {
                return null;
            }
        }

        switch (componentType) {
            case 'FieldsCard':
                return <FieldsCard apiObject={pageObject} itemId={itemId} viewKey={viewKey} title={title} fields={fields} data={data} dropdownData={dropdownData} groupOptions={groupOptions} alignment={alignment} loadStatus={loadStatus} refetchData={handleRefetch} />
            case 'PeopleCard':
                return <PeopleCard apiObject={pageObject} itemId={itemId} viewKey={viewKey} personIds={apiField ? data[apiField] : undefined} loadStatus={loadStatus} isEditable={editable} />
            // Plug in other card components
            default:
                return null;
        }
    }

    // Generate the header buttons
    const generatedHeaderButtons = data && headerButtons && itemId ? generateHeaderButtons(
        headerButtons, 
        pageObject, 
        data, 
        dropdownData, 
        parseInt(itemId), 
        initializeModal, 
        buttonLoader, 
        setButtonLoader, 
        setFloatingAlert,
        handleLogout,
        { activeFeatures, allowedFeatures, allowedRights },
        undefined,
        undefined,
        history
    ) : null;

    return (
        <div className='container-filled'>
            <div className='detailpage-header row'>
                <div className='header-title'>
                    <span className='back-button'
                          onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </span>
                    {headerTitle && (
                        <h2>{t(headerTitle)}</h2>
                    )}
                    {headerTitleDataField && (
                        <h2>{header}</h2>
                    )}
                    {headerTitleFields?.map((field, index) =>
                        <LiveEditField
                            key={index}
                            viewKey={viewKey}
                            apiObject={pageObject}
                            fieldItem={field}
                            itemId={itemId}
                            data={data} 
                            refetchData={handleRefetch}
                        />
                    )}
                </div>
                {headerButtons && 
                    // Render the header buttons of the detail page
                    <div className='button-container right-align'>
                        {generatedHeaderButtons}
                    </div>
                }
            </div>
            {statusBar &&
                <div className='detailpage-statusbar'>
                    {statusBar}
                </div>
            }
            <div className='detailpage-content'>
                <div>
                    {detailCards.map((card) =>
                        <div key={`${card.title}-${card.component}`}>
                            {card.component &&
                                generateCardComponent(card.component, card.fields || [], card.title, itemId, pageObject, viewKey, data, card.apiField, card.alignment, card.dataCondition)
                            }
                        </div>
                    )}
                </div>
                <div>
                    {tabLabel && tabLabel.length > 1 &&  (
                        <ul className='card tabbar'>
                            {tabLabel}
                        </ul>
                    )}
                    {tabComponent}
                </div>
            </div>
        </div>
    );
};

export default DetailPage;