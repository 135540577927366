import { fetchData } from "services/api/fetchData";
import { CombinedWidgetDataType, IdType, IdValuePairsType } from "types/WidgetTypes";
import { widgetConfig } from "../../views/dashboard/dashboardConfig";

/*
 * fetchWidgetData.ts
 * This generic function loops through the widget config and executes all 
 * fetches of all widget and returns it to the parent component. This
 * function needs to be implemented in the parent component where the
 * base widget component is implemented.
 */

// Generic function to fetch and processs the data for a widget endpoint
const fetchAndProcessData = async (
    endpoint: string, 
    periodDates: { 
        currentPeriod: { from: string; to: string; }, 
        previousPeriod: { from: string; to: string; } }, 
    dataType: 'ids' | 'idValuePairs', 
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>,
    valueField?: string,
) => {

    // Fetch the data for the current or previous period of the widget
    const fetchPeriodData = async (from: string, to: string): Promise<IdType | IdValuePairsType> => {

        // Fetch the data
        const response = await fetchData({ apiUrl: `${endpoint}/?from=${from}&to=${to}`, handleLogout });

        // Transform the response to a list of ids or id-value pairs
        if (dataType === 'ids') {
            return response as IdType;
        } else {
            return (response as IdValuePairsType).map(item => ({
                id: item.id,
                value: (item as any)[valueField!]
            }));
        }
    };

    // Get the current and previous periods from the period dates function
    const { currentPeriod, previousPeriod } = periodDates;

    // Execute the fetches
    const current = await fetchPeriodData(currentPeriod.from, currentPeriod.to);
    const previous = await fetchPeriodData(previousPeriod.from, previousPeriod.to);

    // Return the fetched data as object with current and previous values
    return { current, previous };
};

// Generic function to fetch the widget data, to be used in the parent component of the widget
export const fetchWidgetData = async (
    periodDates: { 
        currentPeriod: { from: string; to: string; }, 
        previousPeriod: { from: string; to: string; },
    },
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>
): Promise<CombinedWidgetDataType> => {

    // New data object to store the fetched data inside
    const newWidgetData: CombinedWidgetDataType = {};

    // Loop through the widget config and execute all fetches for every widget
    await Promise.all(widgetConfig.map(async (widget) => {

        // Fetch default widget data
        const { endpoint, dataType, valueField } = widget.query.default;
        const defaultData = await fetchAndProcessData(endpoint, periodDates, dataType, handleLogout, valueField);

        // Fetch dimension widget data if it exists
        let dimensionData;
        if (widget.query.dimension) {
            const { endpoint, dataType, valueField } = widget.query.dimension;
            dimensionData = await fetchAndProcessData(endpoint, periodDates, dataType, handleLogout, valueField);
        }

        // Save the fetched widget data inside the new widget data object
        newWidgetData[widget.widgetKey] = { defaultData, dimensionData };
    }));

    // Return the data of all fetches for all widgets to the parent component
    return newWidgetData;
};