/*
 * deletionUtils.tsx
 * General utility of delete and deactivate functions.
 */

// Determines the deletion types
export type DeletionType = 'fully_delete' | 'flag_deleted' | 'flag_deactivated' | undefined;

// Give the deletion types labels for the user interface
export const getDeletionLabel = (deletionType: DeletionType): string => {
    switch (deletionType) {
        case 'fully_delete': return 'general.delete';
        case 'flag_deleted': return 'general.delete';
        case 'flag_deactivated': return 'general.deactivate';
        default: return 'general.delete';
    }
};