export const currencySymbols: Record<string, string> = {
    "aed": "د.إ",
    "ang": "ƒ",
    "aud": "A$",
    "bgn": "лв",
    "cad": "C$",
    "chf": "CHF",
    "cny": "¥",
    "czk": "Kč",
    "dkk": "kr",
    "eur": "€",
    "gbp": "£",
    "gip": "£",
    "huf": "Ft",
    "isk": "kr",
    "jpy": "¥",
    "nok": "kr",
    "nzd": "NZ$",
    "pln": "zł",
    "sek": "kr",
    "sgd": "S$",
    "usd": "$",
    "xcd": "$"
};