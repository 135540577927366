import DOMPurify from 'dompurify';
import { FieldType } from 'types/FieldTypes';

/*
 * convertTextareaData.tsx
 * 
 * General utility that cleans textarea data in a proper way before submitting to the database.
 * It converts backend tabs (\t's) into frontend spaces (so called &nbsp;'s) which makes it possible
 * to show tabs in the frontend.
 * 
 * Then on submit, it converts the frontend spaces back to backend tabs, otherwise the tabs got lost
 * when saving the text again in the database. Thereafter the text is sanitized with DOMPurify. This 
 * is done to clean the text from unwanted html elements to prevent XSS Cross Site Scripting attacks.
 */


/*** Convert functions to use in components ***/

// Convert data from a string
export const sanitizeTextareaFromString = (text: string): string => {
    let textToClean = text;

    // Convert tabs to spaces
    textToClean = convertSpacesToTabs(textToClean);

    // Sanitize the text
    textToClean = sanitizeText(textToClean);

    return textToClean;
}

// Convert data from a data object and given fields property
export const sanitizeTextareaFromDataObject = (input: Record<string, any>, fields?: any[], field?: FieldType): Record<string, any> => {
    const dataToClean: Record<string, any> = { ...input };

    // For forms with multiple fields, loop over the fields to look for textareas to sanitize
    if (fields) {
        fields.forEach(field => {
            if (field.type === 'textarea' && dataToClean[field.name]) {
                // Convert tabs to spaces
                dataToClean[field.name] = convertSpacesToTabs(dataToClean[field.name]);

                // Sanitize the text
                dataToClean[field.name] = sanitizeText(dataToClean[field.name]);
            }
        })
    }

    // For single fields, just sanitize if the field is a textarea
    if (field) {
        if (field.type === 'textarea' && dataToClean[field.name]) {
            // Convert tabs to spaces
            dataToClean[field.name] = convertSpacesToTabs(dataToClean[field.name]);

            // Sanitize the text
            dataToClean[field.name] = sanitizeText(dataToClean[field.name]);
        }
    }

    return dataToClean;
}

// Convert backend tabs to non-breakable spaces, to show the tabs properly in the frontend
export const convertTabsToSpaces = (text: string | null | undefined) => {
    if (typeof text === 'string') {
        return text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    } else {
        if (text !== null) {
            console.error("Invalid text provided:", text);
        }
        return "";
    }
}


/*** Helper functions ***/

// Sanitize the text from the textarea and clean all unwanted html elements to prevent XSS Cross Site Scripting attacks
const sanitizeText = (text: string): string => {
    // Configure DOMPurify
    const sanitizeConfig = {
        ALLOWED_TAGS: ['strong', 'p', 'em', 'u', 'ol', 'ul', 'li', 'a', 'h1', 'h2', 'br'],
        ALLOWED_ATTR: ['href']
    };

    // Sanitize the data
    const sanitizedText = DOMPurify.sanitize(text, sanitizeConfig)

    return sanitizedText;
};

// Converts non-breakable spaces to tabs from the textarea, to store the data properly in the database
const convertSpacesToTabs = (text: string): string => {
    let textToConvert = text;

    // Convert 4 concatenated non breakable spaces to 1 tab
    textToConvert = textToConvert.replace(/&nbsp;&nbsp;&nbsp;&nbsp;/g, '\t');

    // Convert the remaining non breakable spaces to normal spaces
    textToConvert = textToConvert.replace(/&nbsp;/g, ' ');

    return textToConvert;
}