import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside, useSetDropdownWidth, useSetDropdownPosition } from 'services/utils/dropdownBehavior'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/forms.scss';

interface MultiSelectProps<T> {
    name?: string;
    id?: string;
    options: T[];
    selectedOption?: T[] | null;
    onChange: (value: string) => void;
    disabled_selected?: string;
    showSearch?: boolean;
    sortOptions?: boolean;
    groupOptions?: T[] | undefined;
    groupByField?: string;
    groupTitleField?: string;
    errorMessage?: string | string[] | undefined;
    isInvalid?: boolean;
    selectionFormat?: (option: T) => string;
    optionFormat?: (option: T) => string;
    allowNoneOption?: boolean;
    disabled?: boolean;
}

const MultiSelect = <T extends { id?: number; [key: string]: any }>({ 
    name, id, options, onChange, disabled_selected, showSearch = true, selectionFormat = (option) =>  `${option.name}`, 
    optionFormat = (option) => `${option.name}`, selectedOption, allowNoneOption, errorMessage, groupByField, 
    groupOptions, groupTitleField, disabled, sortOptions = true, isInvalid
}: MultiSelectProps<T>) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownListRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const fieldRef = useRef<HTMLDivElement>(null);
    const selectedItemsRef = useRef<HTMLDivElement>(null);
    const originalFieldHeightRef = useRef<string | null>(null);
    const [dropdownWidth, setDropdownWidth] = useState<number>(200);
    const [dropdownAbove, setDropdownAbove] = useState(false);
    const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | undefined>(undefined);
    
    // Autofocus the search field when opening the dropdown list
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                searchRef.current?.focus();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    // Saves the original height of the field when initial load of the component
    useEffect(() => {
        if (fieldRef.current) {
            // Set different height for add-edit-forms (36px) and live-edit or inline forms (32px)
            let parentForm = fieldRef.current.closest('.add-edit-form') || fieldRef.current.closest('.modal.medium');
            let fieldHeight = parentForm ? "36px" : "32px";
    
            fieldRef.current.style.height = fieldHeight;
            originalFieldHeightRef.current = fieldHeight;
        }
    }, [])

    // Adjust the height of the field when the selected items change
    useEffect(() => {
        setTimeout(() => {
            if (selectedItemsRef.current && fieldRef.current) {
                if (selectedOption && selectedOption.length > 1) {
                    const newHeight = selectedItemsRef.current.scrollHeight;
                    fieldRef.current.style.height = `${newHeight}px`;
                } else {
                    // Reset to the original height if there are no selected options
                    if (originalFieldHeightRef.current !== null && fieldRef.current !== null) {
                        fieldRef.current.style.height = `${originalFieldHeightRef.current}`;
                    }
                }
            }
        }, 0);
    }, [selectedItems]);

    // First, filter the options in the dropdown
    const filteredOptions = options
        .filter(option => {
            // If the value of the given or fetched dropdown options are empty, filter them out so they are not mapped in the dropdown
            if (!optionFormat || (optionFormat && optionFormat(option) && optionFormat(option).trim() !== "")) {
                // Checks if one of the values of the option contains the search value
                return Object.values(option).some(value => String(value).toLowerCase().includes(search.toLowerCase()));
            }
            return false;
        })
        .filter(option => { 
            // Filter out already selected options from the dropdown
            if (!selectedOption) return true;
            if (option.id) {
                return !selectedOption.some(selected => selected.id === option.id);
            }
            if (option.value) {
                return !selectedOption.some(selected => selected.value === option.value);
            }
            return true;
        })

    // Then, sort the filtered options alphabetical
    const sortedOptions = sortOptions ? filteredOptions.sort((a, b) => optionFormat(a).localeCompare(optionFormat(b))) : filteredOptions;

    // Then, group the options
    const groupOptionsMap: { [key: string]: any } = {};
    if (groupOptions && groupTitleField) {
        groupOptions.forEach(group => {
            if (group.id !== undefined && group[groupTitleField] !== undefined) {
                groupOptionsMap[group.id] = group[groupTitleField];
            }
        });
    }

    let groupedOptions: { [key: string]: T[] } = {};
    if (groupByField) {
        groupedOptions = sortedOptions.reduce((acc, option) => {
            const key = option[groupByField as keyof typeof option];
            const title = groupOptionsMap[key];
            if (title) {
                if (!acc[title]) acc[title] = [];
                acc[title].push(option);
            }
            return acc;
        }, {} as { [key: string]: T[] });
    }

    // And final, sort the groups alphabetical
    const sortedGroups = Object.keys(groupedOptions).sort((a, b) => a.localeCompare(b));

    // Render the coloured options
    const renderColouredOptions = (options: T[]) => (
        <div className="coloured-dropdown-items">
            {options.map((option: T) => (
                <span key={String(option.id || option.value)}
                      className={`coloured-dropdown-item ${option.color}`}
                      onClick={() => { 
                        setSelectedItems(prev => [...prev, option]);
                        onChange(String(option.id || option.value)); 
                        setIsOpen(false); }}>
                    {t(optionFormat(option))}
                </span>
            ))}
        </div>
    );
    
    // Render the non coloured options
    const renderNonColouredOptions = (options: T[]) => (
        options.map((option: T) => (
            <div key={String(option.id || option.value)}
                 className='custom-dropdown-item'
                 onClick={() => { 
                    setSelectedItems(prev => [...prev, option]);
                    onChange(String(option.id || option.value)); 
                    setIsOpen(false); }}>
                {t(optionFormat(option))}
            </div>
        ))
    );

    // Handle remove of a selected item
    const handleRemoveSelectedItem = (option: T) => {
        setSelectedItems(prev => prev.filter(selected => selected.id !== option.id && selected.value !== option.value));
        onChange(String(option.id || option.value))
    }

    // Use dropdownBehavior.ts to close the dropdown when clicking outside
    useClickOutside(isOpen, dropdownRef, setIsOpen);

    // Use dropdownBehavior.ts to set the dropdown width on open
    useSetDropdownWidth(isOpen, dropdownListRef, fieldRef, setDropdownWidth);

    // Use dropdownBehavior.ts to set the dropdown position and max height on open
    useSetDropdownPosition(isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight);

    return (
        <div className='custom-dropdown-container multiselect-container'>
            <div className={`custom-dropdown-field ${errorMessage || isInvalid ? 'is-invalid' : ''} ${disabled ? 'disabled' : ''}`}
                 onMouseDown={(e) => { if (!disabled) e.stopPropagation(); setIsOpen(!isOpen) }}
                 ref={fieldRef}>
                    <div className='multiselect-selected-items'
                         ref={selectedItemsRef}>
                        {selectedOption && selectedOption.length > 0 ? (
                            selectedOption.map((option, index) => (
                                <div key={index}
                                     className={`multiselect-selected-item ${option.color}`}
                                     onMouseDown={(event) => event.stopPropagation()}>
                                    {selectionFormat(option)}
                                    {!disabled && (
                                        <span className='multiselect-item-remove' 
                                              onMouseDown={(event) => event.stopPropagation()}
                                              onClick={() => handleRemoveSelectedItem(option)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className='custom-dropdown-placeholder'>
                                {t(disabled_selected) || t('forms.general_disabled_selected')}
                            </div>
                        )}
                    </div>
                    {!disabled && (
                        <FontAwesomeIcon 
                            icon={isOpen ? faCaretUp : faCaretDown}
                            className='custom-dropdown-caret' />
                    )}
            </div>
            {isOpen && !disabled && (
                <div ref={dropdownRef}
                     className={`custom-dropdown ${dropdownAbove ? 'dropdown-above' : ''}`}  
                     style={{width: dropdownWidth, maxHeight: dropdownMaxHeight}}>
                    <div ref={dropdownListRef}
                         className='custom-dropdown-list' 
                         style={{width: dropdownWidth}}>
                        {showSearch && (
                            <input 
                                type="text" 
                                id={id}
                                name={name}
                                value={search} 
                                className='custom-dropdown-search'
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={t('forms.search_placeholder')} 
                                ref={searchRef}
                            />
                        )}
                        {allowNoneOption !== false &&
                            <div className="custom-dropdown-item" onClick={() => { onChange('none'); setIsOpen(false); }}>
                                ({t('forms.none_option')})
                            </div>
                        }
                        {groupByField ? (
                            sortedGroups.length > 0 ? (
                                sortedGroups.map((group, index) => (
                                    <div key={group}>
                                        <div className={index === 0 ? "custom-dropdown-group-title first" : "custom-dropdown-group-title"}>
                                            {group}
                                        </div>
                                        {renderColouredOptions(groupedOptions[group].filter(option => option.color))}
                                        {renderNonColouredOptions(groupedOptions[group].filter(option => !option.color))}
                                    </div>
                                ))
                            ) : (
                            <div className="custom-dropdown-item-no-options">
                                {t('forms.no_options_to_select_label')}
                            </div>
                            )
                        ) : (
                            filteredOptions.length > 0 ? (
                                <>
                                    {renderColouredOptions(filteredOptions.filter(option => option.color))}
                                    {renderNonColouredOptions(filteredOptions.filter(option => !option.color))}
                                </>
                            ) : (
                            <div className="custom-dropdown-item-no-options">
                                {t('forms.no_options_to_select_label')}
                            </div>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;