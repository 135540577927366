import React from 'react';
import { FormButtonType } from 'types/ButtonTypes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';

/*
 * GenerateButtonComponents.tsx
 * General function which generates the button components of a form. It is used by the InlineForm 
 * component which get the buttons from certain form configuration files. This function maps these
 * buttons and its properties to the right buttons an return these components to the Inline Form to
 * use in the return jsx.
 */ 

type ButtonHandlerFunction = (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);

type GroupedButtonsType = {
    fullwidth: JSX.Element[];
    left: JSX.Element[];
    right: JSX.Element[];
}

export const generateButtonComponents = (
    buttons: FormButtonType[], 
    handleClose: (event: React.MouseEvent) => void,
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    buttonLoader: boolean
): JSX.Element[] => {

    // Map the onClick string to the onClick functions
    const onClickFunctionMapping: { [key: string]: ButtonHandlerFunction } = {
        handleClose, 
        handleSubmit,
    };

    // Create an array to group the button elements based on position
    const groupedButtons: GroupedButtonsType = {
        fullwidth: [],
        left: [],
        right: [],
    };

    // Render the button elements
    buttons.forEach((button, index) => {

        // Determine which button component is needed
        const ButtonComponent = button.hierarchy === 'primary' ? PrimaryButton : SecondaryButton;

        // Determine the onClick function based on the prop
        const onClickFunction = onClickFunctionMapping[button.onClick]
        
        const buttonElement = (
            <ButtonComponent 
                key={index}
                onClick={onClickFunction}
                label={button.label}
                size={button.size}
                icon={button.icon}
                loading={button.showLoader ? buttonLoader : false}
                position={button.position}
            />
        )

        groupedButtons[button.position || 'fullwidth'].push(buttonElement);
    });

    // Create an array to store the generated button elements in
    const generatedButtonElements = [];

    // If there is a fullwidth button, render it first
    if (groupedButtons.fullwidth.length > 0) {
        generatedButtonElements.push(...groupedButtons.fullwidth);
    }

    // If there is no fullwidth button, add the buttons-left div with eventual the left buttons
    else {
        generatedButtonElements.push(
            <div className='buttons-left' key="left">
                {groupedButtons.left}
            </div>
        );
    }

    // Add the right buttons if they are set 
    if (groupedButtons.right.length > 0) {
        generatedButtonElements.push(
            <div className='buttons-right' key="right">
                {groupedButtons.right}
            </div>
        );
    }

    // Return the generated buttons
    return generatedButtonElements;
};