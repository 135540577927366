import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import ContactForm from './ContactForm';

// Rename the name of the function:
const ContactList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_contact_list',
        defaultParams: { 'deleted': false },
        object: 'contact'
    }
    const objectName = 'contact.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const formModal = <ContactForm />;
    const onRowClick = 'detailPage'
    const detailPageUrl = {
        baseUrl: 'contacts',
        urlSuffixApiField: 'entity_type',
        urlSuffixFallback: 'contact'
    };

    // Custom jsx to render the labels as badges
    const renderLabelBadges = (labels: { id: number, name: string, color: string }[] = []) => {
        return (
            <div className='badge-container'>
                {labels.map(label => (
                    <div key={label.id} className={`label-badge ${label.color}`}>
                        {label.name}
                    </div>
                ))}
            </div>
        );
    }

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { field: 'name', label: 'contact.general.name_label', width: '1fr' },
        { field: 'labels', label: 'contact.general.labels_label', width: '1fr', render: renderLabelBadges }
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'contact.general.all_contacts_filter_label',
            params: { 'deleted': false },
            default: true,
        },
        {
            group: 'contact.general.contact_type_filter_group_label',
            filters: [
                {
                    id: 'prospects',
                    label: 'contact.contact_type.prospects',
                    params: { 'contact_type': 'prospect' },
                },
                {
                    id: 'clients',
                    label: 'contact.contact_type.clients',
                    params: { 'contact_type': 'client' },
                },
                {
                    id: 'suppliers',
                    label: 'contact.contact_type.suppliers',
                    params: { 'contact_type': 'supplier' },
                },
            ]
        },
        {
            group: 'contact.general.entity_type_filter_group_label',
            filters: [
                {
                    id: 'organizations',
                    label: 'contact.entity_type.organizations',
                    params: { 'entity_type': 'organization' },
                },
                {
                    id: 'persons',
                    label: 'contact.entity_type.persons',
                    params: { 'entity_type': 'person' },
                },
            ]
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deleted',
                    label: 'contact.general.deleted_contacts_filter_label',
                    shortName: 'general.deleted',
                    params: { 'deleted': true },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            filters={filters}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            detailPageUrl={detailPageUrl}
        />
    );
};

export default ContactList;