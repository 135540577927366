/*
 * shakeElement.tsx
 * General utility function that shakes an element.
 * The function can shake a html element of your choice, for example in case of an error.
 */

export const shakeElement = (element: HTMLElement) => {
    element.classList.add('shake');
    setTimeout(() => {
        element.classList.remove('shake');
    }, 1000);
};