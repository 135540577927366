import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import LayoutForm from './LayoutForm';

// Rename the name of the function:
const LayoutList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_layout_list',
        defaultParams: { 'deleted': false },
        object: 'layout'
    }
    const objectName = 'layout.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deleted'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <LayoutForm />;
    const onRowClick = 'detailPage'
    const detailPageUrl = 'settings/templates/layout/pdf-layout'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'layout.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            detailPageUrl={detailPageUrl}
        />
    );
};

export default LayoutList;