import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const DivisionForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'division'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'division.general.division_name_label',
            type: 'text',
            placeholder: 'division.general.division_name_placeholder',
        },
        {
            name: 'is_active',
            label: 'forms.is_active_label',
            type: 'checkbox',
            showOnEdit: true,
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default DivisionForm;