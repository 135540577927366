import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import RoleForm from './RoleForm';

// Rename the name of the function:
const RoleList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_role_list',
        defaultParams: { 'is_active': true },
        object: 'role'
    }
    const objectName = 'role.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'
    
    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <RoleForm />
    const modalSize = 'medium'
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'role.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            modalSize={modalSize}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};


export default RoleList;