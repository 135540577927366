/*
 * timezoneConversions.ts
 * General utility functions to convert UTC datetime strings into local strings, and
 * convert local strings back into UTC datetime strings.
 */

//// UTC<>Local Timezone Conversion functions ////

// Converts a utc datetime string into a datetime string in the local timezone of the user
export const convertUTCToLocalDateTimeString = (utcDatetime: string, userLocale: string, userTimezone: string): string => {

    // Convert the utc Datetime to a javascript date object. This converts the utc datetime into the local timezone
    // based on its date itself. This is wrong when the given date itself is during winter time and the current date
    // is during daylight saving time. So it's important to convert the missing timezone offset in that case.
    let dateToConvert;
    dateToConvert = new Date(utcDatetime);
    const givenDateTimezoneOffset = dateToConvert.getTimezoneOffset();

    // Get the current date and time to determine the timezone offset of now
    const now = new Date();
    const currentTimezoneOffset = now.getTimezoneOffset();

    // If the current timezone offset differs from the given date, add the daylight saving time offset
    if (currentTimezoneOffset !== givenDateTimezoneOffset) {
        const dayLightSavingTimeOffset = currentTimezoneOffset - givenDateTimezoneOffset;
        dateToConvert = new Date(dateToConvert.getTime() - (dayLightSavingTimeOffset * 60 * 1000));
    }

    // Format the local date based on the user timezone and locale
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    // Get the formatted date from the formatter and create a new date string
    const parts = dateTimeFormatter.formatToParts(dateToConvert).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Format to ISO string
    const localDateTimeString = `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}`;
    return localDateTimeString;
};

// Converts a utc datetime string into a date string in the local timezone of the user
export const convertUTCToLocalDateString = (utcDatetime: string, userLocale: string, userTimezone: string): string => {
    const dateFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    return dateFormatter.format(new Date(utcDatetime));
};

// Converts a utc datetime string into a date object in the local timezone of the user
export const convertUTCToLocalDateObject = (utcDateTime: string, userLocale: string, userTimezone: string): Date => {
    // Convert the utcDateTime string into a date object
    const date = new Date(utcDateTime);

    // Format the date based on the user timezone and locale
    const formatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    // Get the formatted date from the formatter and create a new date
    const parts = formatter.formatToParts(date).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Create a datestring which is always accepted by the Date constructor
    const formattedDateString = `${parts.year}-${parts.month}-${parts.day}`;

    // Return the formatted date string as date object
    return new Date(formattedDateString);
}

// Convert the server UTC datetime into a timestring of the local timezone
export const convertUTCToLocalTimeString = (utcDateTime: string, userLocale: string, userTimezone: string, is12HourFormat: boolean): string => {
    const date = new Date(utcDateTime);
    return date.toLocaleTimeString(userLocale, {
        timeZone: userTimezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: is12HourFormat
    }).substring(0, 5);
};

// Convert a local datetime string in the timezone of the user to an utc datetime string to save in the backend
export const convertLocalDateTimeToUTC = (localDateTime: string, userLocale: string, userTimezone: string): string => {
    // Format the local date based on the user timezone and locale
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    // Get the formatted date from the formatter and create a new date string
    const parts = dateTimeFormatter.formatToParts(new Date(localDateTime)).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Create a new date object from the parts
    const localDate = new Date(`${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}`);

    // Get the timezone offset of the current day
    const currentOffset = new Date().getTimezoneOffset();

    // Convert the local date to UTC by subtracting the offset
    const utcDate = new Date(localDate.getTime() + (currentOffset * 60 * 1000));

    // Get the formatted date from the formatter and create a new date string
    const utcDateParts = dateTimeFormatter.formatToParts(utcDate).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Format to iso string
    const utcDateString = `${utcDateParts.year}-${utcDateParts.month}-${utcDateParts.day}T${utcDateParts.hour}:${utcDateParts.minute}:${utcDateParts.second}`;    
        
    return utcDateString;
}


//// Formatting Conversion Functions ////

// Extract a time string from the local iso datetime string. For example to be used in time fields to only show the time value
export const extractTimeFromLocalDateTime = (localDatetime: string, userLocale: string, is12HourFormat: boolean ): string => {
    const date = new Date(localDatetime);
    const timeFormatter = new Intl.DateTimeFormat(userLocale, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: is12HourFormat
    });

    return timeFormatter.format(date);
};

// Extract the date from the the datetime string
export const extractDateFromLocalDateTime = (
    localDatetime: string, 
    userLocale?: string,
): string => {
    const date = new Date(localDatetime);

    // If no userLocale is given, just extract the date from the datetime string
    if (!userLocale) {
        return localDatetime.split('T')[0];
    }

    // If a userLocale is given, extract the date and format it based on the user locale
    const dateFormatter = new Intl.DateTimeFormat(userLocale, {
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    });

    return dateFormatter.format(date)
};

// Format the date based on the user locale
export const formatDisplayDate = (date: Date, userLocale: string): string => {
    return date.toLocaleDateString(userLocale, { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    });
}