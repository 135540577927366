import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FormFieldContext from './FormFieldContext';
import { FieldWrapperType } from 'types/FieldTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faPen } from '@fortawesome/free-solid-svg-icons';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import '../../style/scss/forms.scss';

const FieldWrapper: React.FC<FieldWrapperType> = ({
    name, label, type, tooltipText, helperText, isEditable, 
    disabled, viewInEditMode, alignment = 'stacked', children
}) => {
    const { t } = useTranslation();
    const { errorMessages } = useGlobalContext();
    const { editing, showErrorAlert } = useContext(FormFieldContext);
    const hasRightCheck = useAllowedRight;
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set the right alignment classname
    const alignmentClassName = () => {
        if (alignment === 'horizontal') {
            return 'horizontal-alignment'
        } else {
            return 'stacked-alignment'
        }
    };

    // Get the error message from the errorState
    const errorMessage = name && errorMessages[name];

    return (
        <div className={`${disabled ? 'disabled' : ''}`}>
            <div className={`${alignmentClassName()} ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing || viewInEditMode ? 'editing' : ''} ${type ? `${type}field` : ''}`}>
                {(editing || viewInEditMode) ? (
                    // Edit mode
                    <>
                        {label && name &&
                            <label htmlFor={name}>
                                {t(label)}
                                {tooltipText &&
                                    <span className="tooltip-icon">
                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                        <span className="tooltip tooltip-right">{t(tooltipText)}</span>
                                    </span>
                                }
                            </label>
                        }
                        {showErrorAlert &&
                            <div className="alert form-alert alert-danger" role="alert">
                                {t(errorMessages.general, { defaultValue: errorMessages.general })}
                            </div>
                        }
                        <div className='edit-mode'>
                            {children}
                            {errorMessage && 
                                <div className='error-message'>
                                    {t(errorMessage, { defaultValue: errorMessage })}
                                </div>
                            }
                            {helperText &&
                                <div className="helper-text">
                                    {t(helperText)}
                                </div>
                            }
                        </div>
                    </>
                ) : (
                    // View mode
                    <>
                        {label && 
                            <label htmlFor={name}>
                                {t(label)}
                                {tooltipText && 
                                    <span className="tooltip-icon">
                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                        <span className="tooltip tooltip-right">{t(tooltipText)}</span>
                                    </span>
                                }
                                {alignment === 'horizontal' && 
                                    <span className='edit-icon'>
                                        <FontAwesomeIcon icon={faPen} />
                                    </span>
                                }
                            </label>
                        }
                        {showErrorAlert &&
                            <div className="alert form-alert alert-danger" role="alert">
                                {t(errorMessages.general, { defaultValue: errorMessages.general })}
                            </div>
                        }
                        <div className="view-mode" onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                            {children}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
};

export default FieldWrapper;