import React, { useState, useContext, useEffect } from 'react';
import { FieldData, SubJobsFieldsetType } from 'types/FieldTypes';
import { JobType, createNewJob } from '../../../views/jobs/JobTypes';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import DateInput from '../basefields/DateInput';
import { formatDisplayDate } from 'internationalization/timezoneConversions';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const SubJobsFieldset: React.FC<SubJobsFieldsetType & { data: FieldData, viewKey: string }> = ({
    viewKey, name, jobId, data, helperText, isEditable
}) => {
    const { t } = useTranslation();
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const { environmentSettings, userLocale } = useSettings();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<JobType[]>([]);
    const [billingType] = useState<string>(data['billing_type'] || environmentSettings.job_default_billing_type);
    const [changesTracker, setChangesTracker] = useState<{ [key: string]: { [field: string]: boolean } }>({});
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set existing sub jobs on first render
    useEffect(() => {
        if (data) {
            if (data[name] && data[name].length > 0) {
                const sortedRows = (data[name] || []).sort((a: JobType, b: JobType) => {
                    if (a.ordering && b.ordering) {
                        return a.ordering - b.ordering;
                    }
                    return 0;
                });
                setRows(sortedRows)
            } else {
                const firstPosition = 1;
                const newSubJob = createNewJob(uuidv4(), jobId!, firstPosition, billingType);
                setRows([newSubJob]);
            }
        }        
    }, [data, jobId])

    // Check if any field of has recorded a change
    useEffect(() => {
        const hasAnyUnsavedChanges = Object.values(changesTracker).some(fields => Object.values(fields).some(value => value));
        setUnsavedChanges(viewKey, hasAnyUnsavedChanges);
    }, [changesTracker, viewKey]);

    // Helper function to check for unsaved changes
    const checkUnsavedChanges = (field: string, newValue: string | number | boolean | null, identifier: string) => {
        // Check if data[name] exists and is an array, otherwise return: the check is not needed
        if (!Array.isArray(data[name])) {
            return;
        }

        const originalItem = data[name].find((item: JobType) => item.id?.toString() === identifier || item.uuid === identifier);

        let isChanged = false;
        if (!originalItem) {
            isChanged = true;
        } else {
            const originalValue = originalItem[field];
            // If original value is number and new value is string, convert and compare
            if (typeof originalValue === 'number' && typeof newValue === 'string') {
                isChanged = originalValue !== Number(newValue);
            // If original value is string and new value is number, convert and compare
            } else if (typeof originalValue === 'string' && typeof newValue === 'number') {
                isChanged = Number(originalValue) !== newValue;
            // For all other cases, just compare the values directly
            } else {
                isChanged = originalValue !== newValue;
            }
        }

        // Save changes in the changes tracker for the field
        setChangesTracker(prev => ({
            ...prev,
            [identifier]: {
                ...(prev[identifier] || {}),
                [field]: isChanged
            }
        }));
    }

    // Handle the input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: 'subject' | 'estimated_duration', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the value from the event
            const value = event.target.value

            // Check and mark unsaved changes
            checkUnsavedChanges(fieldName, value, identifier);

            // Set the value in the updated rows
            updatedRows[rowIndex][fieldName] = value;
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({...prev, [name]: updatedRows}));
        }        
    };

    // Handle the date change
    const handleDateChange = (selectedDate: string | null, fieldName: 'target_date', identifier: string) => {
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Check and mark unsaved changes
            checkUnsavedChanges(fieldName, selectedDate, identifier);

            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedDate;

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({...prev, [name]: updatedRows}));
        }
    }
    
    // Handle the creation of a new row
    const handleAddRow = () => {
        setUnsavedChanges(viewKey, true);

        // Split rows in non deleted and deleted items
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const deletedRows = rows.filter(row => row.deleted);

        // Determine the position of the new row and create it
        const newPosition = nonDeletedRows.length + 1;
        const newJobItem = createNewJob(uuidv4(), jobId!, newPosition, billingType);
    
        // Add the new row at the end of the list with non deleted rows and add the deleted rows
        const updatedNonDeletedRows = [...nonDeletedRows, newJobItem];
        const updatedRows = [...updatedNonDeletedRows, ...deletedRows];
    
        setRows(updatedRows);
        setUpdatedData((prev: any) => ({...prev, [name]: updatedRows}));
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        setUnsavedChanges(viewKey, true);

        const updatedRows = [...rows];
        const itemIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (itemIndex !== -1) {
            // Mark the row as deleted without actually deleting it
            updatedRows[itemIndex].deleted = true;
    
            // Update the ordering of the rows which are not marked as deleted
            const nonDeletedRows = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedRows = nonDeletedRows.map((row, index) => ({
                ...row,
                ordering: index + 1,
            }));

            // Add the deleted rows to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedRows, ...updatedRows.filter(row => row.deleted)];
    
            setRows(finalItems);
            setUpdatedData((prev: any) => ({...prev, [name]: finalItems}));
        } 
    }

    // Handle ordering change
    const handleOrderingChange = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;
        
        // Only use the non deleted items to determine the new ordering
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const [reorderedRow] = nonDeletedRows.splice(source.index, 1);
        nonDeletedRows.splice(destination.index, 0, reorderedRow);
    
        // Determine the ordering for the non deleted items
        const updatedNonDeletedItems = nonDeletedRows.map((row, index) => ({
            ...row,
            ordering: index + 1,
        }));
    
        // Add the deleted items to the list without changing their ordering
        const finalItems = [...updatedNonDeletedItems, ...rows.filter(row => row.deleted)];
    
        setRows(finalItems);
        setUpdatedData((prev: any) => ({...prev, [name]: finalItems}));
    };

    return (
        <div className='widget-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {rows.length > 0 && (
                        <div className='rows-header subjobs-row'>
                            <div className='header-item'>{t('job.general.subject_label')}</div>
                            <div className='header-item'>{t('job.general.estimated_duration_label')}</div>
                            <div className='header-item'>{t('job.general.target_date_label')}</div>
                            <div className='delete-placeholder'></div>
                            <div className='drag-placeholder'></div>
                        </div>
                    )}
                    <DragDropContext onDragEnd={handleOrderingChange}>
                        <StrictModeDroppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                                        const identifier = row.id?.toString() || row.uuid;
                                        return (
                                            <Draggable key={identifier} draggableId={`item-${identifier}`} index={index} isDragDisabled={rows.length <= 1}>
                                                {(provided) => {

                                                    // Set the row to disabled if the job status is verified
                                                    const jobIsVerified = row.status_condition === 'verified'

                                                    return (
                                                        <div className='list-item draggable' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='job-items-row'>
                                                                <input 
                                                                    type="text"
                                                                    id={`subject_${identifier}`}
                                                                    name={`subject_${identifier}`}
                                                                    value={row.subject ?? ''}
                                                                    onChange={event => handleInputChange(event, 'subject', identifier || '')}
                                                                    placeholder={t('job.general.subject_placeholder')}
                                                                    disabled={jobIsVerified}
                                                                    autoFocus={false}
                                                                    className={errorMessages[index]?.row ? 'is-invalid' : ''}
                                                                />
                                                                <input 
                                                                    type="text"
                                                                    id={`estimated_duration_${identifier}`}
                                                                    name={`estimated_duration_${identifier}`}
                                                                    value={row.estimated_duration ?? ''}
                                                                    onChange={event => handleInputChange(event, 'estimated_duration', identifier || '')}
                                                                    placeholder={t('job.general.estimated_duration_placeholder')}
                                                                    disabled={jobIsVerified}
                                                                    autoFocus={false}
                                                                    className={errorMessages[index]?.jobItem ? 'is-invalid' : ''} 
                                                                />
                                                                <DateInput
                                                                    id={`target_date_${identifier}`}
                                                                    name={`target_date_${identifier}`}
                                                                    localDatetime={row.target_date ?? undefined}
                                                                    disabled={jobIsVerified}
                                                                    onChange={(selectedValue) => handleDateChange(selectedValue, 'target_date', identifier || '')} />
                                                                <div className='delete-icon tooltip-icon'>
                                                                    <FontAwesomeIcon 
                                                                        icon={faTrash} 
                                                                        onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')} />
                                                                    <span className="tooltip">{t('general.delete')}</span>
                                                                </div>
                                                                <div className={`order-icon tooltip-icon ${rows.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                    <FontAwesomeIcon icon={faGripLines} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </div>
                                                            </div>
                                                            {errorMessages[index]?.subJob && 
                                                                <div className='error-message'>
                                                                    {errorMessages[index]?.subJob}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                            {t('forms.add_new_item')}
                    </div>    
                    {helperText && 
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                </div>
            ) : (
                // View mode
                <div className={`full-width-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>
                    <div className="view-mode">
                        <span className='p'>
                            <div className='item-list'>
                                {rows.filter(row => row.id).map((row) => {                                    
                                    return (
                                        <div key={row.id!.toString()} className='item'>
                                            <span>{row.subject}</span>
                                            <span>{row.estimated_duration && ` ${row.estimated_duration} ${t('date_time.general.hour.singular')}`}</span>
                                            <span>{row.target_date && ` ${t('job.general.target_date_label')}: ${formatDisplayDate(new Date(row.target_date), userLocale)}`} </span>
                                        </div>
                                    )
                                })}
                            </div>
                            {isEditable && !userHasOnlyViewRights && (
                                // Only show add item label when the object is editable
                                <div className='add-item'>
                                    {t('forms.add_item')}
                                </div>
                            )}
                        </span>
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                </div>
            )}
        </div>
    );
}

export default SubJobsFieldset;